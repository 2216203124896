import React, {useContext, useState} from 'react';
import Context from '../../store/store';
import {Row, Col, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import {signUpUser, loginUser, submitConfirmationCode, resendConfirmationCode} from '../../libs/awsLib';
import {create, addUserToGroup} from '../../libs/request';
import {TextInput, CustomButton} from '../form';
import {parseQS} from '../../libs/utils';
import {emailRegex} from '../../helpers/validationHelper';
import {SignUp, SignIn} from '..';
import {fetchBrandFromURL} from '../../helpers/global';
import {toast} from 'react-toastify';

const UserInfo = ({
  handleFieldUpdate, firstName, lastName, email,
  paypalEmail, phone, onComplete, authenticateUser,
}) => {
  const context = useContext(Context);
  const {activeBrand} = context;
  const queryParams = parseQS(window.location.search);
  const [errors, setErrors] = useState([]);
  const [activeTab, setActiveTab] = useState('0');
  const [awsError, setAwsError] = useState('');
  const [modalError, setModalError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [tabSelected, setTabSelected] = useState(false);
  const {stateUser} = context;

  const handleNext = () => {
    let error = false;
    const newErrors = [];
    if (!firstName.length) {
      error = true;
      newErrors.push('You need to fill in a first name.');
    }
    if (!lastName.length) {
      error = true;
      newErrors.push('You need to fill in a last name.');
    }
    if (!emailRegex.test(email)) {
      error = true;
      newErrors.push('Please enter a valid email address.');
    }
    setErrors(newErrors);
    if (!error) {
      onComplete();
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleLogIn = (event, email, password) => {
    event.preventDefault();
    loginUser(email.toLowerCase(), password)
        .then((result) => {
          const jwtToken = result.accessToken.jwtToken;
          authenticateUser(jwtToken);
        })
        .catch((error) => {
          console.log('error', error);
          if (error.name === 'UserNotConfirmedException') {
            toggleModal();
          } else {
            setAwsError(error.message);
          }
        });
  };

  const handleSignUp = ({
    email, password, phone, firstName, lastName, termsAgreement,
  }) => {
    signUpUser(email, password)
        .then((result) => {
          if (result.user) {
            const newUser = {
              email,
              phone,
              firstName,
              lastName,
              termsAgreement,
              awsId: result.userSub,
            };
            create('user', newUser).then(() => {
              toggleModal();
            }).catch((err) => {
              console.log('error', err);
              toast.err('Something went wrong . . .');
            });
          }
        })
        .catch((error) => {
          console.log('error', error);
          setAwsError(error.message);
        });
  };

  const handleConfirmationCode = async (
      event, {email, password}, confirmationCode,
  ) => {
    event.preventDefault();
    submitConfirmationCode(email, password, confirmationCode)
        .then((result) => {
          const newAuthUser = authenticateUser(result.accessToken.jwtToken);
          toggleModal();
          addUserToGroup(newAuthUser.sub, 'users')
              .then(() => {
                authenticateUser(result.accessToken.jwtToken);
              })
              .catch((error) => {
                setAwsError(error.message);
              });
        })
        .catch((error) => {
          if (error.name === 'ExpiredCodeException') {
            resendConfirmationCode(email);
            setModalError('Your code has expired. A new code has been sent, please use the new code within 24 hours.');
          } else {
            setModalError(error.message);
          }
        });
  };

  const toggle = (tab) => {
    setTabSelected(true);
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="userinfo">

      <div className="userinfo-head">
        <h2>Submit Content</h2>
        <p>Access your account, create an account, or submit content as a guest!</p>
      </div>

      <Row className="nav-container">
        <Nav className="sign-in-nav">
          {(!stateUser || !stateUser.awsId) && (
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '0'})}
                onClick={() => {
                  toggle('0');
                  setAwsError('');
                }}
              >
                Sign Up
              </NavLink>
            </NavItem>
          )}
          {(!stateUser || !stateUser.awsId) && (
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '1'})}
                onClick={() => {
                  toggle('1');
                  setAwsError('');
                }}
              >
                Log In
              </NavLink>
            </NavItem>
          )}
          {(!stateUser || !stateUser.awsId) && (
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '2'})}
                onClick={() => {
                  toggle('2');
                  setAwsError('');
                }}
              >
                Guest
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Row>

      <Row className='forms-container'>
        <Col xs={12} sm={12} md={12} lg={12}>

          {/* Signup */}
          {activeTab === '0' && (
            <>
              {activeBrand.slugname === 'animalsdoingthings' && (
                <div className="disclaimer">For all pages being submitted that include an animal, please always submit under your name and sign your name, and not the animal's name.</div>
              )}
              <SignUp
                awsError={awsError}
                handleConfirmationCode={handleConfirmationCode}
                handleSignUp={handleSignUp}
                showModal={showModal}
                toggleModal={toggleModal}
                toggle={toggle}
              />
            </>
          )}

          {/* Login */}
          {activeTab === '1' && (
            <SignIn
              awsError={awsError}
              handleLogIn={handleLogIn}
              showModal={showModal}
              toggleModal={toggleModal}
              handleConfirmationCode={handleConfirmationCode}
              modalError={modalError}
            />
          )}

          {/* Submit as Guest */}
          {activeTab === '2' && (
            <>

              {activeBrand.slugname === 'animalsdoingthings' && (
                <div className="disclaimer">For all pages being submitted that include an animal, please always submit under your name and sign your name, and not the animal's name.</div>
              )}

              <Row className='form-row split'>
                <Col>
                  <TextInput
                    htmlFor="first-name"
                    id="first-name"
                    name="firstName"
                    labelTitle="First Name"
                    labelClass="required"
                    value={firstName.replace(/ /g, '')}
                    handleChange={handleFieldUpdate}
                    type="text"
                    feedbackText="first name"
                  />
                </Col>
                <Col>
                  <TextInput
                    htmlFor="last-name"
                    id="last-name"
                    name="lastName"
                    labelTitle="Last Name"
                    labelClass="required"
                    value={lastName.replace(/ /g, '')}
                    handleChange={handleFieldUpdate}
                    type="text"
                    feedbackText="last name"
                  />
                </Col>
              </Row>

              <Row className='form-row split'>
                <Col>
                  <TextInput
                    id="email"
                    name="email"
                    labelTitle="Email Address"
                    labelClass="required"
                    value={email.replace(/ /g, '')}
                    handleChange={handleFieldUpdate}
                    type="email"
                  />
                </Col>
                <Col>
                  <TextInput
                    id="phone"
                    name="phone"
                    labelTitle="Phone Number"
                    labelClass="required"
                    value={phone}
                    handleChange={handleFieldUpdate}
                    type="tel"
                  />
                </Col>
              </Row>

              {queryParams.rsID && (
                <Row className='form-row'>
                  <Col>
                    <TextInput
                      id="paypal"
                      name="paypalEmail"
                      labelTitle="Paypal Email"
                      value={paypalEmail}
                      handleChange={handleFieldUpdate}
                      type="text"
                    />
                  </Col>
                </Row>
              )}

              <Row className="error-container">
                <Col>
                  {errors.map((err) => (<p className="error">{err}</p>))}
                </Col>
              </Row>

              <Row className="btn-container centered">
                <Col>
                  <CustomButton
                    type="submit"
                    styling="primary"
                    handleClick={handleNext}
                    title="Next"
                  />
                </Col>
              </Row>

            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserInfo;
