import { Button, Input } from "reactstrap";
import { changePage } from "../libs/utils";
import { DEFAULT_ADMIN_SEARCH_OBJECT, ROOT_URL } from "../helpers/constants";
import { getTagCount, searchTags } from "../libs/request";
import { Loader } from "../components";
import { PaginationBar } from "../components";
import { useSortBy, useTable } from "react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useGetData } from "../hooks/useGetData";

export default function TagMetrics() {
  const [tagMetrics, setTagMetrics] = useState([]);
  const defaultSearchObject = DEFAULT_ADMIN_SEARCH_OBJECT;
  const [searchObject, setSearchObject] = useState(defaultSearchObject);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [noSearchResults, setNoSearchResults] = useState("");
  const [offset, setOffset] = useState(0);
  const offsetNumber = !Number.isNaN(parseInt(searchObject.offset, 10))
    ? searchObject.offset
    : "";

  const loadTagMetrics = async () => {
    return await new Promise(async (resolve, reject) => {
      await getTagCount(offsetNumber).then((res) => {
        res ? resolve(res) : reject("Error");
      });
    });
  };

  useEffect(() => {
    if (searchValue === "") {
      setLoading(true);
      loadTagMetrics()
        .then((res) => {
          setTagMetrics(res);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [searchValue]);

  const changeTagPage = (page) => {
    changePage(page, searchObject, setSearchObject);
  };

  const handleInput = (e) => {
    setSearchValue(e.target.value);
  };

  const handleReset = () => {
    setSearchValue("");
    setNoSearchResults("");
  };

  const handleClick = () => {
    setLoading(true);
    setNoSearchResults("");
    searchTags(offsetNumber, searchValue)
      .then((res) => {
        if (res === 0) {
          setNoSearchResults("No results found!");
        } else {
          setTagMetrics(res);
        }
        setLoading(false);
      })
      .catch((error) => {});
  };

  const data = useMemo(() => tagMetrics, [tagMetrics]);

  const columns = useMemo(
    () => [
      {
        Header: "Tag Id",
        accessor: "id",
        className: "align-center",
        headerClassName: "align-center",
        Cell: (props) => props.row.original.id,
      },
      {
        Header: "Value",
        accessor: "value",
        className: "align-center",
        headerClassName: "align-center",
        Cell: (props) => props.row.original.value,
      },
      {
        Header: "Count",
        accessor: "count",
        className: "align-center",
        headerClassName: "align-center",
        Cell: (props) => props.row.original.count.toLocaleString(),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            className="search-wrapper"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "16px",
              gap: "1rem",
            }}
          >
            <div style={{ display: "flex", gap: "2rem" }}>
              <Input
                style={{ width: "40%" }}
                className="searchInput"
                id="searchInput"
                name="searchText"
                onChange={handleInput}
                placeholder="&#x1F50D;&nbsp;&nbsp;&nbsp; Search for tags"
                type="text"
                value={searchValue}
              />

              <Button className="btn" onClick={handleClick}>
                Search
              </Button>
              <Button className="btn" onClick={handleReset}>
                Reset
              </Button>
            </div>
            {noSearchResults && (
              <h3 style={{ textAlign: "center", color: "red" }}>
                {noSearchResults}
              </h3>
            )}
          </div>
          <div
            className="table-wrapper"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) => (
                      <th key={i} {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={i} {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <td key={i} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
