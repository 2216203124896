import { approveClip, updateOneThing } from "../../libs/request";
import { colors } from "../../helpers/constants";
import { fas } from "fontawesome.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { render } from "react-dom";
import { setBrandLogo } from "../../helpers/global";
import { toast } from "react-toastify";
import { useTable, useSortBy, useFlexLayout } from "react-table";
import context from "../../store/store";
import Loader from "../Loader";
import moment from "moment";
import PaginationBar from "../PaginationBar";
import React, { useContext, useEffect, useMemo, useState } from "react";

export default function DashboardTable({
  clips,
  handleShowMediaViewer,
  handleShowSubmissionDetails,
  setClips,
  handleTagsUsedChange,
  changePage,
  clipCount,
  searchObject,
  isLoading,
  mediaViewerClip,
}) {
  const { stateUser } = useContext(context);
  const offsetNumber = !Number.isNaN(parseInt(searchObject.offset, 10))
    ? searchObject.offset + 1
    : "";

  const saveNewField = (event, row, field, value, data) => {
    // Prevent reload.
    event.preventDefault();

    // Updates the clip, passes the clip ID, passes a key/value dict of the value to update.
    return new Promise((resolve, reject) => {
      updateOneThing("clip", row.id, { [field]: value })
        .then((response) => {
          if (response.status === 200) {
            // Find the index of the row that has just been edited.
            const rowIndex = data.findIndex((obj) => obj.noteId === row.noteId);

            // Copy the clips array into a new array.
            const newClips = [...data];

            // Update the target row index with the uppdated value.
            newClips[rowIndex][field] = value;

            // Update the state of clips.
            setClips(newClips);

            // Success
            toast.success("Updated");
            resolve();
          } else {
            console.log(response.error);
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong.");
          console.log(error.message);
          reject(error);
        });
    });
  };

  const renderTitleInput = (row, data) => {
    const { videoTitle } = row;
    const [value, setValue] = useState(videoTitle);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    useEffect(() => {
      setValue(videoTitle);
    }, [videoTitle]);

    return (
      <input
        value={value || ""}
        onChange={onChange}
        onBlur={(event) => saveNewField(event, row, "videoTitle", value, data)}
      />
    );
  };

  const updateFields = async (event, row, values, data) => {
    // Prevent reload.
    event.preventDefault();

    // Updates the clip, passes the clip ID, passes a key/value dict of the value to update.
    return new Promise((resolve, reject) => {
      updateOneThing("clip", row.id, values)
        .then((response) => {
          if (response.status === 200) {
            // Find the index of the row that has just been edited.
            const rowIndex = data.findIndex((obj) => obj.id === row.id);

            // Copy the clips array into a new array.
            const newClips = [...data];

            // Update the target row index with the uppdated value.
            Object.keys(values).forEach(function (key) {
              newClips[rowIndex][key] = values[key];
            });

            // Update the state of clips.
            setClips(newClips);

            // Success
            toast.success("Updated");
            resolve(response.status);
          } else {
            toast.error("Something went wrong.");
            console.log(response.error);
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error(error.message);
          reject(error);
        });
    });
  };

  const handleRating = async (event, row, type, data) => {
    // Allows for the event's information to be accessed beyond this line (it's needed lower down).
    event.persist();

    row[type] = row[type] === false ? true : false;
    type === "approved" ? (row["disapprove"] = false) : null;
    type === "disapprove" ? (row["approved"] = false) : null;

    // Deconstruct the row.
    const { approved, disapprove, isViral } = row;

    // Initate a array of values to update.
    const valuesToUpdate = {
      approved,
      disapprove,
      isViral,
    };

    // Update the values in the database.
    await updateFields(event, row, valuesToUpdate, data)
      .then(async (result) => {
        const { approved, isViral } = valuesToUpdate;
        if (result) {
          if (approved === true || isViral === true) {
            return new Promise(async (resolve, reject) => {
              await approveClip(row)
                .then((response) => {
                  if (response.status === 200) {
                    resolve();
                    return;
                  } else {
                    console.log(result.error);
                    reject(result.error);
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            });
          }
        }
      })
      .catch((error) => {
        toast.error("Something went wrong.");
        console.log(error);
      });
  };

  const handleToggle = (event, row, type, data) => {
    // Allows for the event's information to be accessed beyond this line (it's needed lower down).
    event.persist();

    // If currently true then set to false.
    if (row[type] === false) {
      row[type] = true;
    } else {
      row[type] = false;
    }

    const updatedValue = row[type];

    // Save this field in the database.
    saveNewField(event, row, type, updatedValue, data);
  };

  const renderAggregator = (row) => (
    <div className="centered">
      <img
        src={setBrandLogo(row.aggregator)}
        className="agg-logo-table"
        alt="DTMedia"
      />
    </div>
  );

  const renderSubmitter = (row) => {
    const hasName = row.firstName || row.lastName;
    const hasCredit = row.userCredit;
    return (
      <>
        <span>
          {hasName
            ? `${row.firstName || ""} ${row.lastName || ""}`
            : "Anonymous"}
        </span>
        <br />
        <small>@{hasCredit ? row.userCredit : "Handle: Unknown"}</small>
      </>
    );
  };

  const renderBrandManager = (row) => (
    <span className="icon" role="button">
      {row.isBrandManager === true && <FontAwesomeIcon icon={fas("check")} />}
    </span>
  );

  const renderDuration = (row) =>
    row.contentType === "image" ? (
      "--"
    ) : row.duration >= 0 ? (
      moment.utc(row.duration * 1000).format("mm:ss")
    ) : (
      <small className="obscure">N/A</small>
    );

  const renderMediaViewer = (row) => (
    <span
      className="icon"
      role="button"
      onClick={() => handleShowMediaViewer(row)}
    >
      <FontAwesomeIcon
        icon={row.contentType === "image" ? fas("image") : fas("video")}
        className={row.contentType === "image" ? "image-icon" : "video-icon"}
      />
    </span>
  );

  const renderPreview = (row) => (
    <img
      style={{ width: 85, height: 85, cursor: "pointer" }}
      src={`${row.contentType === "image" ? row.attachment : row.thumbnail}`}
      onClick={() => handleShowMediaViewer(row)}
    />
  );

  const renderSubmissionDetails = (props) => (
    <span
      key={props.row.original.id}
      className="icon"
      role="button"
      onClick={() => handleShowSubmissionDetails(props.row.original)}
    >
      <FontAwesomeIcon icon={fas("caret-down")} className="details" />
    </span>
  );

  const renderNSFW = (row, data) => {
    return (
      <span
        className="icon"
        onClick={(event) => handleToggle(event, row, "vimeo", data)}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon
          icon={fas("exclamation-triangle")}
          className={`${row.vimeo === true ? "active" : "inactive red"}`}
          color={`${row.vimeo === true ? colors.nsfwRed : colors.black}`}
          id="isNSFW"
        />
      </span>
    );
  };

  const renderApproved = (row, data) => {
    const { approved, disapprove, isViral } = row;
    return (
      <span className="centered">
        {/* Viral */}
        <span
          onClick={async (event) =>
            await handleRating(event, row, "isViral", data)
          }
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon
            icon={fas("fire")}
            id="viral"
            className={`${
              isViral === true ? "active viral" : "inactive viral"
            }`}
          />
        </span>

        {/* Approved */}
        <span
          onClick={async (event) =>
            await handleRating(event, row, "approved", data)
          }
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon
            icon={fas("thumbs-up")}
            id="up"
            className={`${approved === true ? "active" : "inactive"}`}
            color={`${colors.black}`}
          />
        </span>

        {/* Not Approved */}
        <span
          onClick={async (event) =>
            await handleRating(event, row, "disapprove", data)
          }
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon
            icon={fas("thumbs-down")}
            id="down"
            className={`${disapprove === true ? "active" : "inactive"}`}
            color={`${colors.black}`}
          />
        </span>
      </span>
    );
  };

  const renderBookmarked = (row, data) => (
    <span
      className="icon"
      onClick={(event) => handleToggle(event, row, "bookmarked", data)}
      role="button"
      tabIndex={0}
    >
      <FontAwesomeIcon
        className={`${row.bookmarked === true ? "active" : "inactive green"}`}
        color={`${row.bookmarked === true ? colors.green : colors.black}`}
        icon={fas("bookmark")}
        id="bookmarked"
      />
    </span>
  );

  const renderCredit = (row) => {
    const { platformCredit, userCredit } = row;
    if (userCredit && userCredit.length) {
      return userCredit.charAt(0) === "@"
        ? `${userCredit.toLowerCase()}`
        : `@${userCredit.toLowerCase()}`;
    }
    if (platformCredit && platformCredit.length) {
      return platformCredit;
    }
    return "None";
  };

  const renderHasTags = (row) => (
    <span className="icon">
      <FontAwesomeIcon
        className={row.tagIds.length > 1 ? "active" : "inactive green"}
        icon={fas("circle")}
      />
    </span>
  );

  const renderConfirmedRecord = (row) => (
    <span className="icon">
      <FontAwesomeIcon
        className={
          row.didYouRecordConfirm === true || row.isBrandManager
            ? "active"
            : "inactive green"
        }
        color={`${
          row.didYouRecordConfirm === true || row.isBrandManager
            ? colors.green
            : colors.black
        }`}
        icon={fas("circle")}
        onClick={(event) => {
          if (
            ["confirmers", "superAdmin"].some((group) =>
              stateUser.groups.includes(group)
            )
          ) {
            handleToggle(event, row, "didYouRecordConfirm");
          }
        }}
      />
    </span>
  );

  // Initiate positions for sticky elements.
  useEffect(() => {
    setTimeout(function () {
      document.querySelectorAll("#options, table thead").forEach((el) => {
        el.style.top = `${el.getBoundingClientRect().top}px`;
      });
    }, 750);
  }, []);

  const data = useMemo(() => clips, [clips]);

  const columns = stateUser.groups.includes("externalPartners")
    ? useMemo(
        () => [
          {
            Header: "Brand",
            accessor: "aggregator",
            className: "align-center",
            width: 50,
            Cell: (props) => renderAggregator(props.row.original),
          },
          {
            Header: "Date",
            accessor: "createdAt",
            className: "align-center",
            width: 50,
            Cell: (props) => {
              return moment(props.value).format("M/D/YY");
            },
          },
          {
            Header: "Duration",
            accessor: "duration",
            className: "align-center",
            width: 50,
            Cell: (props) => renderDuration(props.row.original),
          },
          {
            Header: "View",
            accessor: "attachment",
            className: "align-center",
            disableSortBy: true,
            width: 100,
            Cell: (props) => renderPreview(props.row.original),
          },
          {
            Header: "Title",
            accessor: "videoTitle",
            width: 100,
            Cell: (props) => (
              <div style={{ whiteSpace: "normal" }}>
                {props.row.original.videoTitle}
              </div>
            ),
          },
          {
            Header: "Description",
            accessor: "privateDescription",
            width: 100,
            Cell: (props) => (
              <div style={{ whiteSpace: "normal" }}>
                {props.row.original.privateDescription}
              </div>
            ),
          },
          {
            Header: "Submitter",
            accessor: "submitter",
            width: 100,
            Cell: (props) => renderSubmitter(props.row.original),
          },
        ],
        []
      )
    : useMemo(
        () => [
          {
            id: "expander",
            className: "align-center icon single small",
            disableSortBy: true,
            maxWidth: 40,
            Cell: (props) => renderSubmissionDetails(props),
          },
          {
            Header: "Brand",
            accessor: "aggregator",
            className: "align-center",
            width: 60,
            Cell: (props) => renderAggregator(props.row.original),
          },
          {
            Header: "Vetted",
            accessor: "isBrandManager",
            className: "align-center icon single",
            width: 60,
            Cell: (props) => renderBrandManager(props.row.original),
          },
          {
            Header: "Date",
            accessor: "createdAt",
            className: "align-center",
            width: 100,
            Cell: (props) => {
              return moment(props.value).format("M/D/YY");
            },
          },
          {
            Header: "Duration",
            accessor: "duration",
            className: "align-center",
            width: 80,
            Cell: (props) => renderDuration(props.row.original),
          },
          {
            Header: "View",
            accessor: "attachment",
            className: "align-center icon single",
            disableSortBy: true,
            width: 60,
            Cell: (props) => renderMediaViewer(props.row.original),
          },
          {
            Header: "NSFW",
            accessor: "vimeo",
            className: "align-center icon single",
            width: 60,
            Cell: (props) => renderNSFW(props.row.original, props.data),
          },
          {
            Header: "Rating",
            accessor: "rating",
            className: "align-center icon",
            disableSortBy: true,
            width: 150,
            Cell: (props) => renderApproved(props.row.original, props.data),
          },
          {
            Header: "Save",
            accessor: "bookmarked",
            className: "align-center",
            width: 80,
            Cell: (props) => renderBookmarked(props.row.original, props.data),
          },
          {
            Header: "Description",
            accessor: "videoTitle",
            className: "overflow-ellipsis",
            Cell: (props) => renderTitleInput(props.row.original, props.data),
          },
          {
            Header: "Submitter",
            accessor: "submitter",
            width: 100,
            Cell: (props) => renderSubmitter(props.row.original),
          },
          {
            Header: "Tags",
            className: "align-center icon single",
            width: 60,
            Cell: (props) => renderHasTags(props.row.original),
          },
          {
            Header: "Confirmed",
            className: "align-center icon single",
            width: 80,
            Cell: (props) => renderConfirmedRecord(props.row.original),
          },
        ],
        []
      );

  const defaultColumn = useMemo(
    () => ({
      minWidth: 20,
      width: 150,
      maxWidth: 500,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useSortBy
    );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="table-wrapper">
          <table {...getTableProps}>
            {/* Build the table header. */}
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      key={column.id}
                      className={column.className}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {/* Build the table body. */}
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    key={row.id}
                    className={
                      row.original.id === mediaViewerClip.id ? "active" : ""
                    }
                    style={
                      row.original.usersMarkedAsViwedIds.includes(stateUser.id)
                        ? { backgroundColor: "rgb(225,250,0, 0.8)" }
                        : {}
                    }
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          key={cell.value}
                          className={cell.column.className}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <PaginationBar
        changePage={changePage}
        clipCount={clipCount}
        length={clips.length}
        offset={searchObject.offset}
        offsetNumber={offsetNumber}
      />
    </>
  );
}
