import React from 'react'
import {
  Label, FormGroup, Col, Input,
} from 'reactstrap'

const DropDownField = ({
  smLabelSize, lgLabelSize, labelTitle,
  name, id, handleChange, value, placeholder, options, labelStyle,
}) => (
  <FormGroup row>
    <Label
      for={ labelTitle }
      sm={ smLabelSize }
      lg={ lgLabelSize }
      className="strong"
      style={labelStyle}
    >
      { labelTitle }
    </Label>
    <Col className="select-wrapper">
      <Input
        type="select"
        name={ name }
        id={ id }
        className={ value === '' ? 'default' : '' }
        onChange={ handleChange }
        value={ value }
      >
        { placeholder && <option disabled defaultValue value="">{ placeholder }</option> }
        { options.map((option) => (
          <option
            key={ option.val || option.value }
            value={ option.val || option.value }
          >
            { option.publicName || option.title || option.name }
            {' '}
          </option>
        ))}
      </Input>
    </Col>
  </FormGroup>
)

export default DropDownField
