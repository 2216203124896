import React, { useEffect, useRef, useMemo } from "react";
import { useTable, useSortBy, useFlexLayout } from "react-table";

export default function DataTable({ columns, data, styles }) {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 20,
      width: 150,
      maxWidth: 500,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useSortBy,
      useFlexLayout
    );

  return (
    <>
      {data && data.length > 0 ? (
        <div className='table-wrapper' style={styles ? styles : {}}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className={column.className}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr key={i} {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td key={i} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
