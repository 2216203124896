import { useEffect, useState } from "react";
import { clipBrowse, clipDownload } from "./request";
import {
  SEARCH_STOP_WORDS,
  DEFAULT_SEARCH_OBJECT,
  SEARCH_OPTIONS,
  DEFAULT_ADMIN_SEARCH_OBJECT,
} from "../helpers/constants";
import qs from "qs";

export function parseQS(URL) {
  return qs.parse(URL, { ignoreQueryPrefix: true });
}

// Clip Download Functions
export const exportAllClips = (searchObject, setIsLoading) => {
  setIsLoading(true);

  const whatToExport = { ...searchObject };
  whatToExport.searchTerms = whatToExport.searchTerms.filter(
    (term) => !SEARCH_STOP_WORDS.includes(term)
  );
  whatToExport.selectedAggregators = whatToExport.selectedAggregators
    .filter((agg) => agg.selected)
    .map((agg) => agg.val);
  whatToExport.selectedCategories = whatToExport.selectedCategories
    .filter((category) => category.selected)
    .map((category) => `${category.id}`);
  whatToExport.selectedRatings = whatToExport.selectedRatings.filter(
    (rating) => rating.selected
  );
  whatToExport.selectedRatings.forEach((rating) => {
    whatToExport[rating.val] = "1";
  });

  // Remove properties not needed for export
  delete whatToExport.limit;
  delete whatToExport.offset;

  // Add a flag to indicate full export
  whatToExport.fullExport = true;

  return clipDownload(whatToExport).then((response) => {
    setIsLoading(false);
    return response.blob();
  });

};

// Clip Search Functions
export const newClipSearch = (
  searchObject,
  setClipCount,
  setClips,
  setIsLoading,
  componentName,
  isExternalPartner
) => {
  setIsLoading(true);
  const whatToSearch = { ...searchObject };
  whatToSearch.searchTerms = whatToSearch.searchTerms.filter(
    (string) => !SEARCH_STOP_WORDS.includes(string)
  );
  whatToSearch.selectedAggregators = whatToSearch.selectedAggregators
    .filter((agg) => agg.selected)
    .map((agg) => agg.val);
  whatToSearch.selectedCategories = whatToSearch.selectedCategories
    .filter((category) => category.selected)
    .map((category) => `${category.id}`);
  whatToSearch.selectedRatings = whatToSearch.selectedRatings.filter(
    (rating) => rating.selected
  );
  whatToSearch.selectedRatings.forEach((rating) => {
    whatToSearch[rating.val] = "1";
  });
  whatToSearch.componentName = componentName;

  clipBrowse(whatToSearch, isExternalPartner)
    .then((data) => {
      setClips(
        data.submissions.map((clip) => {
          const { attachment } = clip;
          return {
            ...clip,
            fileUrl:
              attachment && attachment.includes("http")
                ? attachment
                : `${
                    attachment
                      ? attachment.substr(attachment.lastIndexOf("/"))
                      : ""
                  }`,
          };
        })
      );
      setClipCount(data.count);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const newClipDownload = (searchObject, setIsLoading) => {
  setIsLoading(true);
  const whatToSearch = { ...searchObject };
  whatToSearch.searchTerms = whatToSearch.searchTerms.filter(
    (string) => !SEARCH_STOP_WORDS.includes(string)
  );
  whatToSearch.selectedAggregators = whatToSearch.selectedAggregators
    .filter((agg) => agg.selected)
    .map((agg) => agg.val);
  whatToSearch.selectedCategories = whatToSearch.selectedCategories
    .filter((category) => category.selected)
    .map((category) => `${category.id}`);
  whatToSearch.selectedRatings = whatToSearch.selectedRatings.filter(
    (rating) => rating.selected
  );
  whatToSearch.selectedRatings.forEach((rating) => {
    whatToSearch[rating.val] = "1";
  });
  return clipDownload(whatToSearch).then((response) => {
    setIsLoading(false);
    return response.blob();
  });
};

export const changePage = (offset, searchObject, setSearchObject) => {
  setSearchObject({
    ...searchObject,
    offset,
  });
};

export const clearCheckboxes = (array, searchObject, setSearchObject) => {
  const changedArray = searchObject[array].map((item) => ({
    ...item,
    selected: false,
  }));
  setSearchObject({ ...searchObject, [array]: changedArray });
};

// part of a search function
export const handleCheckChangeSearch = (
  index,
  arrayName,
  searchObject,
  setSearchObject
) => {
  const changedArray = searchObject[arrayName];
  changedArray[index].selected = !changedArray[index].selected;
  setSearchObject({
    ...searchObject,
    [arrayName]: changedArray,
    offset: 0,
    showFeaturedClips: false,
  });
};

// part of a simple checkbox list
export const handleCheckChange = (index, array, setArray) => {
  const changedArray = array;
  changedArray[index].selected = !changedArray[index].selected;
  setArray(changedArray);
};

export const handleSearchChange = (
  searchString,
  searchObject,
  setSearchObject,
  setSearchString,
  searchSelection,
  admin = false
) => {
  if (!searchString.length) {
    setSearchObject(
      admin ? DEFAULT_ADMIN_SEARCH_OBJECT : DEFAULT_SEARCH_OBJECT
    );
    setSearchString("");
  } else {
    const searchTerms = searchString
      // .toLowerCase()
      .split(",")
      .filter((n) => n);
    let searchFields = [];
    const newSearchFields = {
      searchTerms,
      showFeaturedClips: false,
    };
    if (searchSelection === "all") {
      searchFields = SEARCH_OPTIONS.filter(
        (option) => !["all", "fullName", "duration"].includes(option.value)
      ).map((option) => option.value);
    } else if (searchSelection === "fullName") {
      searchFields = ["firstName", "lastName"];
    } else {
      searchFields = [searchSelection];
    }
    setSearchObject({
      ...searchObject,
      offset: 0,
      searchFields,
      ...newSearchFields,
    });
    setSearchString(searchString);
  }
};

export const handleSort = (field, direction, searchObject, setSearchObject) => {
  setSearchObject({
    ...searchObject,
    offset: 0,
    orderBy: {
      field,
      direction,
    },
  });
};

export const removeSearchTerm = (
  index,
  searchObject,
  setSearchObject,
  setSearchString,
  defaultIsFeatured
) => {
  const { searchTerms } = searchObject;
  searchTerms.splice(index, 1);
  setSearchObject({
    ...searchObject,
    offset: 0,
    searchTerms,
    showFeaturedClips: !searchTerms.length && defaultIsFeatured,
  });
  setSearchString(searchTerms.join(" "));
};

export const selectAllCheckboxes = (array, searchObject, setSearchObject) => {
  const changedArray = searchObject[array].map((item) => ({
    ...item,
    selected: true,
  }));
  setSearchObject({
    ...searchObject,
    [array]: changedArray,
    showFeaturedClips: false,
  });
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export const copyToClipboard = (event, msg) => {
  navigator.clipboard.writeText(msg);
  const target = event.target.closest(".copyable");
  target.classList.add("active");
  setTimeout(function () {
    target.classList.remove("active");
  }, 2000);
};
