const logger = {
  log: function(msg) {
    if (process.env.NODE_ENV == 'development') {
      console.log(msg);
    }
  },
  warn: function(msg) {
    if (process.env.NODE_ENV == 'development') {
      console.warn(msg);
    }
  },
  error: function(msg) {
    if (process.env.NODE_ENV == 'development') {
      console.error(msg);
    }
  },
  table: function(obj) {
    if (process.env.NODE_ENV == 'development') {
      console.table(obj);
    }
  },
};

export default logger;
