import { addToLibrary } from "../../libs/request/partnerLibrary";
import {
  approveClip,
  getClipTags,
  markAsViewed,
  updateOneThing,
} from "../../libs/request";
import { Button } from "reactstrap";
import { fas } from "fontawesome.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stripVideoUUID } from "../../helpers/global";
import { toast } from "react-toastify";
import AdminTags from "./AdminTags";
import context from "../../store/store";
import MicroTable from "../MicroTable";
import React, { useState, useEffect, useContext, useMemo } from "react";
import ReactPlayer from "react-player";
import { MarkAsViewed } from "./MarkAsViewed";

export default function MediaViewer({
  mediaViewerClip,
  handleTagsUsedChange,
  clips,
  setClips,
  setShowMediaViewer,
  setMediaViewerClip,
  updateMediaViewer,
}) {
  const [tags, setTags] = useState();
  const [strippedUUID, setStrippedUUID] = useState();
  const [userHasViewed, setUserHasViewed] = useState();
  const { stateUser } = useContext(context);
  const { id, viewedSubmissionIds } = stateUser;

  const loadData = async () => {
    await getClipTags(mediaViewerClip.id).then((result) => {
      const { tags } = result;
      const approvedTags = tags.filter((tag) => tag.approved === 1);
      setTags(approvedTags);
      setStrippedUUID(stripVideoUUID(mediaViewerClip.attachment));
    });
  };

  useEffect(() => {
    if (mediaViewerClip.id) {
      loadData();
      setUserHasViewed(!!mediaViewerClip.usersMarkedAsViwedIds.includes(id));
    }
  }, [mediaViewerClip]);

  useEffect(() => {
    if (mediaViewerClip.usersMarkedAsViwedIds.includes(id))
      setUserHasViewed(true);
  }, []);

  const handleApproval = (row, type) => {
    if (type === "approved" || type === "isViral" || type === "vimeo") {
      return new Promise((resolve, reject) => {
        approveClip(row)
          .then((response) => {
            if (response.status === 200) {
              resolve();
            } else {
              console.log(response.error);
              reject(response.error);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong.");
            console.log(error.message);
            reject(error);
          });
      }).catch((error) => {
        console.log("error", error);
      });
    }
  };

  const updateField = (event, row, field, value) => {
    // Prevent reload.
    event.preventDefault();

    // Updates the clip, passes the clip ID, passes a key/value dict of the value to update.
    return new Promise((resolve, reject) => {
      updateOneThing("clip", row.id, { field, value })
        .then((response) => {
          if (response.status === 200) {
            // Find the index of the row that has just been edited.
            const rowIndex = clips.findIndex(
              (obj) => obj.noteId === row.noteId
            );

            // Copy the clips array into a new array.
            const newClips = [...clips];

            // Update the target row index with the uppdated value.
            newClips[rowIndex][field] = value;

            // Update the state of clips.
            setClips(newClips);

            // Success
            toast.success("Updated");
            resolve();
          } else {
            toast.error("Something went wrong.");
            console.log(response.error);
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong.");
          console.log(error);
          reject(error);
        });
    });
  };

  const updateFields = async (event, row, values, data) => {
    // Prevent reload.
    event.preventDefault();

    // Updates the clip, passes the clip ID, passes a key/value dict of the value to update.
    return new Promise((resolve, reject) => {
      updateOneThing("clip", row.id, values)
        .then((response) => {
          if (response.status === 200) {
            // Find the index of the row that has just been edited.
            const rowIndex = data.findIndex((obj) => obj.noteId === row.noteId);

            // Copy the clips array into a new array.
            const newClips = [...data];

            // Update the target row index with the uppdated value.
            Object.keys(values).forEach(function (key) {
              newClips[rowIndex][key] = values[key];
            });

            // Update the state of clips.
            setClips(newClips);

            // Success
            toast.success("Updated");
            resolve(response.data);
          } else {
            toast.error(response.error);
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error(error.message);
          reject(error);
        });
    });
  };

  /**
   * Function to update video row in db with new rating
   * @param event
   * @param row
   * @param type
   * @return void
   */
  const handleToggle = (event, row, type) => {
    event.persist();

    if (row[type] === false) {
      row[type] = true;
    } else {
      row[type] = false;
    }

    const updatedValue = row[type];
    updateField(event, row, type, updatedValue);
    updateMediaViewer(row);
  };

  const handleRating = async (event, row, type, data) => {
    // Allows for the event's information to be accessed beyond this line (it's needed lower down).
    event.persist();

    row[type] = row[type] === false ? true : false;
    type === "approved" ? (row["disapprove"] = false) : null;
    type === "disapprove" ? (row["approved"] = false) : null;

    // Deconstruct the row.
    const { approved, disapprove, isViral } = row;

    // Initate a array of values to update.
    const valuesToUpdate = {
      approved,
      disapprove,
      isViral,
    };

    // Update the values in the database.
    return new Promise((resolve, reject) => {
      updateFields(event, row, valuesToUpdate, data)
        .then((response) => {
          const { approved, isViral } = response;
          if (response) {
            if (approved === true || isViral === true) {
              handleApproval(row, type);
              resolve();
            }
          } else {
            console.log(response.error);
            reject();
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  const handleAddToLibary = async () => {
    const { awsId, email } = stateUser;

    await addToLibrary(mediaViewerClip.id, awsId, email)
      .then(() => {
        toast.success("Added to library!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong . . .");
      });
  };

  const microTableData = useMemo(
    () => ({
      label: "Submission Information",
      data: [
        {
          key: "ID",
          value: mediaViewerClip.id,
          copyable: true,
          copyValue: mediaViewerClip.id,
          editable: false,
        },
        {
          key: "Shareable Link",
          value: "Click to copy link.",
          copyable: true,
          copyValue: `${window.location.origin}/media/${mediaViewerClip.id}`,
          editable: false,
        },
      ],
      expandable: false,
    }),
    [mediaViewerClip]
  );

  const handleMarkAsViewed = async () => {
    await markAsViewed({
      submissionId: mediaViewerClip.id,
      userId: id,
    }).then((result) => {
      setMediaViewerClip({ mediaViewerClip, ...result.data });
      setClips((current) => {
        current.find(
          (sub) => sub.id === mediaViewerClip.id
        ).usersMarkedAsViwedIds = result.data.usersMarkedAsViwedIds;
        return current;
      });
    });
  };

  return (
    <>
      <div className="slide half">
        <Button
          className="slide-close"
          onClick={() => {
            setShowMediaViewer(false);
            setMediaViewerClip({});
          }}
        ></Button>
        <div className="slide-container">
          <div className="slide-wrapper">
            <div className="right-col">
              {/* Media */}
              {mediaViewerClip.contentType !== "image" ? (
                <div className="player-wrapper">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    url={mediaViewerClip.attachment}
                    controls={true}
                  />
                </div>
              ) : (
                <img src={mediaViewerClip.attachment} />
              )}

              <MarkAsViewed
                handleClick={handleMarkAsViewed}
                userHasViewed={userHasViewed}
              />

              {/* External Partners */}
              {stateUser.groups.includes("externalPartners") && (
                <>
                  <div className="section">
                    <MicroTable
                      label={microTableData.label}
                      data={microTableData.data}
                      toggleState={false}
                      expandable={false}
                    />
                  </div>
                  <div className="section">
                    <h2>Actions</h2>
                    <Button className="btn" onClick={() => handleAddToLibary()}>
                      Add to Library
                    </Button>
                  </div>
                </>
              )}

              {/* Actions */}
              <div className="actions">
                {!stateUser.groups.includes("externalPartners") && (
                  <>
                    {/* Viral */}
                    <Button
                      className="viral"
                      onClick={(event) =>
                        handleRating(event, mediaViewerClip, "isViral", clips)
                      }
                    >
                      <FontAwesomeIcon icon={fas("fire")} />
                      <span>Viral</span>
                    </Button>

                    {/* Approve */}
                    <Button
                      className="approve"
                      onClick={(event) =>
                        handleRating(event, mediaViewerClip, "approved", clips)
                      }
                    >
                      <FontAwesomeIcon icon={fas("thumbs-up")} />
                      <span>Approve</span>
                    </Button>

                    {/* Disapprove */}
                    <Button
                      className="disapprove"
                      onClick={(event) =>
                        handleRating(
                          event,
                          mediaViewerClip,
                          "disapprove",
                          clips
                        )
                      }
                    >
                      <FontAwesomeIcon icon={fas("thumbs-down")} />
                      <span>Disapprove</span>
                    </Button>

                    {/* NSFW */}
                    <Button
                      className="nsfw"
                      onClick={(event) =>
                        handleToggle(event, mediaViewerClip, "vimeo")
                      }
                    >
                      <FontAwesomeIcon icon={fas("exclamation-triangle")} />
                      <span>NSFW</span>
                    </Button>
                  </>
                )}
              </div>

              {/* Tags */}
              {!stateUser.groups.includes("externalPartners") && (
                <AdminTags
                  currentClip={mediaViewerClip}
                  handleTagsUsedChange={handleTagsUsedChange}
                  updatedClip={mediaViewerClip}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
