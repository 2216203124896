import ReactPlayer from 'react-player/lazy';
import {Link} from 'react-router-dom';
import {setBrandLogo, stripVideoUUID} from '../../helpers/global';

const FeaturedGrid = (clips) => {
  return (
    <>
      <div className="featured-grid">
        {clips &&
          clips.clip.map((clip) => {
            const {
              id,
              noteId,
              aggregator,
              createdAt,
              description,
              videoTitle,
              fileUrl,
              source,
              thumbnail,
              isViral,
              approved,
              location,
            } = clip;
            return (
              <div className="grid-item">
                <img className="brand" src={setBrandLogo(clip.aggregator)} />
                {clip.contentType !== 'image' ? (
                  <ReactPlayer
                    url={stripVideoUUID(clip.attachment)}
                    light={clip.thumbnail}
                    controls={true}
                    height="400px"
                    width="100%"
                  />
                ) : (
                  <img className="media-image" src={clip.attachment} />
                )}
                <Link
                  className="link"
                  to={{
                    pathname: `/license/${clip.noteId}`,
                    state: {
                      clip: {
                        id,
                        noteId,
                        aggregator,
                        createdAt,
                        description,
                        videoTitle,
                        fileUrl,
                        isViral,
                        approved,
                        location,
                        thumbnail,
                      },
                    },
                  }}
                >View Details</Link>
              </div>
            );
          },
          )}
      </div>
    </>
  );
};

export default FeaturedGrid;
