import axios from "axios";
import { useEffect, useState } from "react";
import { ROOT_URL } from "../../helpers/constants";

export const useGetSingleBrandFeed = (id) => {
  const [data, setData] = useState();
  const [brandName, setBrandName] = useState();
  const [brandId, setBrandId] = useState();
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  async function fetchSingleFeed() {
    const response = await axios
      .get(`${ROOT_URL}brand_feed/content?id=${id}&skip=${offset * 20}`)
      .catch((err) => setError(err));
    setBrandId(response.data.brand.id);
    setBrandName(response.data.brand.name);
    setData(response.data.feed);
    setCount(response.data.count);
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchSingleFeed();
  }, [offset]);

  return {
    data,
    id,
    brandName,
    count,
    offset,
    setOffset,
    isLoading,
    error,
    fetchSingleFeed,
  };
};
