import { clipValidationFields } from "../helpers/constants";
import { Col, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { isBrandedSubmissionURL, isUserInGroup, loadStyles } from "../helpers/global";
import { parseQS } from "../libs/utils";
import { ReCaptchaComponent } from "../components/form";
import { submitClip, uploadAuthDoc } from "../libs/request";
import { ThankYouSubmission, UploadProgress, ToastErrorList } from "../components";
import { toast } from "react-toastify";
import { UserInfo, VideoDetails } from "../components/submit";
import { validateSubmission } from "../helpers/validationHelper";
import Context from "../store/store";
import React, { useState, useEffect, useContext } from "react";

// Define steps, which will help us determine which components we need to load and when to load them.
const steps = {
  USERINFO: 1,
  CATEGORY: 2,
  VIDEO: 3,
  COMPLETED: 4,
};

// Define the component.
const Submit = ({ authenticateUser }) => {
  const context = useContext(Context);
  const { activeBrand, stateUser, setRecaptcha, clipFile, setClipFile } = context;

  // States
  const [authDoc, setAuthDoc] = useState(null);
  const [backEndError, setBackEndError] = useState(false);
  const [errors, setErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(null);
  const [isBrandManager, setIsBrandManager] = useState(isUserInGroup(stateUser, "brandManager"));
  const [isLoading, setIsLoading] = useState(false);
  const [morePeople, setMorePeople] = useState(false);
  const [noAggregator, setNoAggregator] = useState(false);
  const [notValidFormat, setNotValidFormat] = useState(false);
  const [objectUrl, setObjectUrl] = useState(null);
  const [queryParams, setQueryParams] = useState(parseQS(window.location.search));
  const [revShareAccept, setRevShareAccept] = useState(false);
  const [revShareGroup, setRevShareGroup] = useState(null);
  const [search, setSearch] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [step, setStep] = useState(steps.USERINFO);
  const [video, setVideo] = useState(null);
  const [videoTooLong, setVideoTooLong] = useState(false);
  const recaptcha = true;

  // Initiate the submission.
  const [newClip, setNewClip] = useState({
    aggregator: activeBrand.slugname || "",
    authDoc: "null",
    contractFilename: "",
    didYouRecord: isBrandManager ? true : false,
    email: stateUser.email || "",
    fileUrl: "",
    firstName: "",
    isBrandManager: false,
    isLoggedIn: stateUser.awsId || false,
    lastName: "",
    location: "",
    noExclusive: isBrandManager ? true : false,
    noteId: "",
    overEighteen: isBrandManager ? true : false,
    paypalEmail: stateUser.paypalEmail || "",
    phone: stateUser.phone || "",
    privateDescription: "",
    reviewedRights: isBrandManager ? true : false,
    reviewedTermsPrivacy: isBrandManager ? true : false,
    stayUpdated: false,
    timeStamp: new Date().getTime(),
    userCredit: "",
    groupPermissionGiven: false,
  });

  // Run on render and then again only if stateUser has been changed.
  useEffect(() => {

    // If not logged in then set the step to position 1.
    !stateUser.awsId && setStep(steps.USERINFO);

    // Set states based on user. values.
    setIsBrandManager(isUserInGroup(stateUser, "brandManager"));
    setRevShareGroup(isUserInGroup(stateUser, "revShare"));

    // Update the clip.
    setNewClip({
      ...newClip,
      firstName: stateUser.firstName || newClip.firstName || "",
      lastName: stateUser.lastName || newClip.lastName || "",
      email: stateUser.email || newClip.email || "",
      paypalEmail: stateUser.paypalEmail || newClip.paypalEmail || "",
      phone: stateUser.phone || newClip.phone || "",
      isBrandManager: isBrandManager,
      isLoggedIn: stateUser.awsId || false,
    });

  }, [stateUser]);

  // Validation that runs when lots of actions happen.
  useEffect(() => {
    // If the user is logged in and an aggregator is set then proceed to the video stage.
    // TT - Might not be necessary.
    if (stateUser.awsId && newClip.aggregator) {
      setStep(steps.VIDEO);
    }
  }, [newClip, clipFile, notValidFormat, queryParams, videoTooLong]);

  // Update the page when the brand is changed.
  useEffect(() => {
    loadStyles(activeBrand.slugname);
  }, [activeBrand]);

  // Reset the form.
  const resetForm = () => {
    // Reset the clip.
    setNewClip({
      ...newClip,
      aggregator: activeBrand.slugname || "",
      authDoc: null,
      contractFilename: "",
      didYouRecord: isBrandManager ? true : false,
      email: stateUser.email || "",
      fileUrl: "",
      firstName: "",
      isBrandManager: false,
      isLoggedIn: stateUser.awsId || false,
      lastName: "",
      location: "",
      noExclusive: isBrandManager ? true : false,
      noteId: "",
      overEighteen: isBrandManager ? true : false,
      paypalEmail: stateUser.paypalEmail || "",
      phone: stateUser.phone || "",
      privateDescription: "",
      reviewedRights: isBrandManager ? true : false,
      reviewedTermsPrivacy: isBrandManager ? true : false,
      stayUpdated: false,
      timeStamp: new Date().getTime(),
      userCredit: "",
    });

    // Reset States
    setClipFile(null);
    setIsLoading(false);
    setMorePeople(false);
    setNotValidFormat(false);
    setStep(steps.USERINFO);
    setVideo(null);
    setVideoTooLong(false);
  };

  // Handle changes to the file upload field.
  const handleFileUploadChange = (event, type) => {
    // Access the file from the event.
    const tempFile = event.currentTarget.files[0];

    // If the file uploaded is not a video or an image.
    if (
      !tempFile?.type.includes("video/") &&
      !tempFile?.type.includes("image/")
    ) {
      setNotValidFormat(true);
      setVideoTooLong(false);
      setClipFile("");
      return;
    }

    setObjectUrl(URL.createObjectURL(tempFile));

    // Image Upload
    if (tempFile.type.includes("image/")) {
      isBrandManager && type === "authDoc"
        ? setAuthDoc(tempFile)
        : setClipFile(tempFile);
      setNotValidFormat(false);
      setVideoTooLong(false);

      // Video Upload
    } else {
      setClipFile(tempFile);
      setNotValidFormat(false);
      setVideoTooLong(false);
    }
  };

  // Handle an update to certain fields.
  const handleFieldUpdate = (event) => {
    const { target } = event;
    const { name } = target;

    // Set the value.
    const value = target.type === "checkbox" ? target.checked : target.value;

    // Set the new clip.
    setNewClip((oldClip) => ({
      ...oldClip,
      [name]: value,
    }));

    // Set the search.
    setSearch(name === "location" ? value : "");

    // Handle changes to the aggregator.
    if (target.name === "aggregator") {
      window.setTimeout(() => {
        setStep(stateUser && stateUser.awsId ? steps.VIDEO : steps.USERINFO);
      }, 500);
    }
  };

  // Handle when revenue share is checked.
  const handleRevShareCheck = (event) => {
    setRevShareAccept(event.target.checked);
  };

  // Validation that runs prior to submission running.
  const handleValidation = () => {
    return new Promise((resolve, reject) => {
      // Validate the clip.
      const validationResult = validateSubmission({
        clip: newClip,
        file: clipFile,
        isBrandManager: isBrandManager,
        notValidFormat: notValidFormat,
        videoTooLong: videoTooLong,
      });
      // Set the state of the errors.
      setErrors(validationResult);

      // Iterate through the items in the errors object.
      // If anything is false then load errors.
      if (Object.values(validationResult).some((item) => item === false)) {
        // Update states.
        setHasErrors(true);
        setNoAggregator(validationResult.aggregator);

        // Iterate through the errors.
        const errorList = Object.entries(validationResult)
          .filter((item) => item[1] === false)
          .map((item) => ({
            key: item[0],
            label:
              item[0] === "revShareError"
                ? validationResult.revShareResult
                : clipValidationFields[item[0]],
          }));

        const errorObject = {
          message: "Please fill all the required fields:",
          list: errorList,
        };

        // Reject continuance.
        reject(errorObject);

        // No errors.
      } else {
        // Update state.
        setHasErrors(false);
        setNoAggregator(false);

        // Resolve to next step.
        resolve(true);
      }
    });
  };

  const handleSelectSuggest = (geocodedPrediction) => {
    setSearch("");
    setNewClip({ ...newClip, location: geocodedPrediction.formatted_address });
  };

  // Verify the recaptcha.
  const verifyReCaptcha = (success) => {
    if (success) {
      setRecaptcha(true);
    }
  };

  // Handle submission click.
  const handleClickSubmission = async () => {
    try {
      setNewClip({
        ...newClip,
        aggregator: activeBrand.slugname,
        reviewedRights:
          stateUser && stateUser.awsId ? true : newClip.reviewedRights,
        revShare: errors.revShareResult === false || revShareGroup,
      });

      // setUploadProgress(0);
      setShowProgress(true);

      await submitClip(
        {
          ...newClip,
          aggregator: activeBrand.slugname,
          revShareLink: queryParams.rsID,
          revShare: errors.revShareResult === false || revShareGroup,
          appearances: morePeople,
        },
        clipFile
      )
        .then(async ({ data }) => {
          if (data && data.id && authDoc !== null) {
            await uploadAuthDoc(data.id, authDoc);
          } else {
            setBackEndError(true);
          }
          setStep(steps.COMPLETED);
          setIsLoading(false);
          // setUploadProgress(0);
        })
        .catch((error) => {
          // setUploadProgress(0);
          setIsLoading(false);
          setShowProgress(false);
          setBackEndError(true);
          toast.error(`clip upload failed with error ${error.message}`, {
            autoClose: false,
          });
        });
    } catch (err) {
      toast.error(err.message, { autoClose: false });
      setIsLoading(false);
    }
  };

  // Submitting the form.
  // Flow: handleFinalSubmit > handleClickSubmission > handleSubmitClip
  const handleFinalSubmit = async (event) => {
    // Prevent reload.
    event.preventDefault();

    if (isBrandManager && !authDoc) {
      toast.error("Authorization Confirmation document required!", {
        autoClose: false,
      });
    } else {
      // Update the isLoading state.
      setIsLoading(true);

      // Validate the fields.
      await handleValidation()
        .then(() => {
          handleClickSubmission();
        })
        .catch((error) => {
          // If error is found then show a toast with the error.
          if (error) {
            toast.error(
              error.list ? <ToastErrorList error={error} /> : error.message
            );
          }

          // Update the isLoading state.
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>{activeBrand.name} | Submit Content</title>
        <meta
          name="description"
          content="Submit media to {activeBrand.name} and go viral!"
        />
      </Helmet>
      <Row
        className={`submit ${
          !isBrandedSubmissionURL() && isBrandManager ? "brandManager" : ""
        }`}
      >
        <Col className="content">
          {/* User Information */}
          {step === steps.USERINFO && (
            <UserInfo
              {...newClip}
              authenticateUser={authenticateUser}
              handleFieldUpdate={handleFieldUpdate}
              onComplete={() => setStep(steps.VIDEO)}
            />
          )}

          {/* Video Details */}
          {step === steps.VIDEO && (
            <>
              <VideoDetails
                {...newClip}
                authenticateUser={authenticateUser}
                clipFile={clipFile}
                handleChangeCategory={() => setStep(steps.CATEGORY)}
                handleFieldUpdate={handleFieldUpdate}
                handleFileUploadChange={handleFileUploadChange}
                handleFinalSubmit={handleFinalSubmit}
                handleRevShareCheck={handleRevShareCheck}
                handleSelectSuggest={handleSelectSuggest}
                isBrandManager={isBrandManager}
                isLoading={isLoading}
                notValidFormat={notValidFormat}
                recaptcha={recaptcha}
                revShareAccept={revShareAccept}
                search={search}
                videoTooLong={videoTooLong}
              />
            </>
          )}

          {/* Confirmation */}
          {step === steps.COMPLETED && (
            <>
              <ThankYouSubmission
                noteId={newClip.noteId}
                resetForm={resetForm}
              />
            </>
          )}

          {/* Recaptcha */}
          {!recaptcha && (
            <ReCaptchaComponent
              sitekey="6LfI1L0UAAAAADPSGJhDiiPzUaX-NUZc5-hMs-kV"
              verifyReCaptcha={verifyReCaptcha}
            />
          )}

          {/* Loader */}
          <UploadProgress
            styling={!isLoading ? "hidden" : ""}
            title="Large files or long videos may take longer to upload. Thanks for your patience."
            progressText={showProgress ? "Uploading" : "Processing"}
            showProgress={showProgress}
            progress={0}
          />
        </Col>
      </Row>
    </>
  );
};

export default Submit;
