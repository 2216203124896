import React from "react";
import MobileNavigation from "./Mobile";
import SidebarNavigation from "./Sidebar";
import TopNavigation from "./Top";

const Header = ({ type }) => {
  return (
    <>
      <header>
        {/* Mobile */}
        <MobileNavigation></MobileNavigation>

        {/* Sidebar */}
        {type == "sidebar" ||
          (type == "default" && <SidebarNavigation></SidebarNavigation>)}

        {/* Top */}
        {type == "top" && <TopNavigation></TopNavigation>}
      </header>
    </>
  );
};

export default Header;
