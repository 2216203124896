import axios from "axios";

export const get = (url, data = {}) => {
  const p = new Promise((resolve, reject) => {
    fetch(url, {
      credentials: "include",
    })
      .then((response) => {
        resolve(response.json());
      })
      .catch((error) => {
        reject(error);
      });
  });
  return p;
};

export const post = (url, data = {}) => {
  const p = new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        resolve(response.json());
      })
      .catch((error) => {
        reject(error);
      });
  });
  return p;
};

export const put = (url, data = {}) => {
  const p = new Promise((resolve, reject) => {
    fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        resolve(response.json());
      })
      .catch((error) => {
        reject(err);
      });
  });
  return p;
};

export const apiDelete = (url) => {
  const p = new Promise((resolve, reject) => {
    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return p;
};

export const updateOneThing = (type, id, body) => {
  return new Promise((resolve, reject) => {
    const stringifiedBody = JSON.stringify(body);
    axios
      .put(`/api/${type}/${id}`, stringifiedBody, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        resolve({ error: error });
      });
  });
};

export const create = async (type, object) =>
  fetch(`/api/${type}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(object),
  });

export const show = async (type, id) => {
  if (!id) return {};
  fetch(`/api/${type}/${id}`).then((response) => response.json());
};
