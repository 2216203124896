import qs from "qs";
import { USER_FETCH, USER_UPDATE, USER_CLEAR } from "./types";
import { get } from "../libs/request";
import { ROOT_URL } from "../helpers/constants";

export const userUpdate = (userUpdates) => ({
  type: USER_UPDATE,
  payload: userUpdates,
});

export const userFetch = async (userId, dispatch) => {
  const userCookie = document.cookie.split("=")[1];
  const cookies = { userCookie: userCookie };
  if (userId) {
    const url = `${ROOT_URL}user/${userId}`;
    await get(url, cookies).then((response) => {
      dispatch({
        type: USER_UPDATE,
        payload: response,
      });
    });
  } else {
    dispatch({
      type: USER_FETCH,
      payload: {},
    });
  }
};

export const userClear = () => ({
  type: USER_CLEAR,
  payload: {},
});

export const searchLogs = (searchObject) =>
  get(`${ROOT_URL}userlogs?${qs.stringify(searchObject)}&${Date.now()}`);
// export const searchAdminUsers = (searchUserGroup) =>
//   get(`${ROOT_URL}adminusers?group=${searchUserGroup}&${Date.now()}`);
export const searchAlLUsers = (searchUserGroup, searchString) =>
  get(
    `${ROOT_URL}searchUsers?group=${searchUserGroup}&searchValue=${searchString}&adminSearch=false`
  );
export const searchAdminUsers = (searchUserGroup, searchValue, adminSearch) =>
  get(
    `${ROOT_URL}searchUsers?group=${searchUserGroup}&searchValue=${searchValue}&adminSearch=${adminSearch}`
  );
export const addUserToGroup = (username, group) =>
  get(
    `${ROOT_URL}addusertogroup?username=${username}&group=${group}&${Date.now()}`
  );
export const removeUserFromGroup = (username, group) =>
  get(
    `${ROOT_URL}removeuserfromgroup?username=${username}&group=${group}&${Date.now()}`
  );

export const deleteUser = (username) =>
  get(`${ROOT_URL}deleteUser?username=${username}`);
