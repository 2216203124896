import React, { useContext, useState, useEffect } from "react";
import Context from "../store/store";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import { setBrandLogo, stripVideoUUID } from "../helpers/global";
import { getClipTags, getSingleClip } from "../libs/request";
import { CustomButton } from "../components/form";
import { AGGREGATORS } from "../helpers/constants";
import { isUserInGroup } from "../helpers/global";
import { Loader } from "../components";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "fontawesome.macro";
import { copyToClipboard } from "../libs/utils";
import { Button } from "reactstrap";
import { downloadCount } from "../libs/request/partnerLibrary";

const LicenseIndividual = ({ location }) => {
  const context = useContext(Context);
  const { stateUser } = context;
  const [clipExists, setClipExists] = useState(false);
  const [clip, setClip] = useState(location.state ? location.state.clip : {});
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [strippedUUID, setStrippedUUID] = useState();

  // Return the formal name of a brand based on an clip.aggregator value.
  const setBrandName = (brand) => {
    if (AGGREGATORS.some((agg) => agg.slugname === brand)) {
      const target = AGGREGATORS.find((agg) => agg.slugname === brand);
      return target.name;
    }
    return null;
  };

  const loadData = () => {
    setClipExists(true);
    const noteId = window.location.pathname.split("/").pop();
    getSingleClip(noteId)
      .then((response) => {
        if (!!response) {
          const { attachment, id } = response;
          response.fileUrl =
            attachment && attachment.includes("http")
              ? attachment
              : `${
                  attachment
                    ? attachment.substr(attachment.lastIndexOf("/"))
                    : ""
                }`;
          setClip(response);
          setTags(response.tags);
          setLoading(false);
          setStrippedUUID(stripVideoUUID(attachment));
        } else {
          setClipExists(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong . . .");
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleCopyToClipboard = (e, msg) => {
    copyToClipboard(e, msg);
  };

  const handleDownloadSubmission = async () => {
    await downloadCount(clip.noteId, stateUser.awsId)
      .then((response) => {
        toast.success("Successfully downloaded!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong . . .");
      });
  };

  return (
    <div className={`single-video ${!clipExists && "invalid"}`}>
      {loading ? (
        <Loader />
      ) : !clipExists ? (
        <div>Media does not exist</div>
      ) : (
        <div className="content-section">
          <Helmet>
            <title>License Media | Doing Things Media</title>
            <meta name="description" content={clip.description} />
          </Helmet>
          {clip.fileUrl && (
            <ReactPlayer
              config={{
                file: {
                  attributes: {
                    onContextMenu: (e) => e.preventDefault(),
                    controlsList: isUserInGroup(
                      stateUser,
                      "ableToDownloadMedia"
                    )
                      ? ""
                      : "nodownload",
                  },
                },
              }}
              controls
              pip={false}
              url={clip.fileUrl}
              style={{
                backgroundColor: "transparent",
                maxHeight: "50vh",
                maxWidth: "100%",
              }}
            />
          )}
          <div className="top-section">
            <div className="brand">
              <img
                className="brand-logo"
                src={setBrandLogo(clip.aggregator)}
                width="30"
                alt={clip.aggregator}
              />
              <div className="brand-text">
                <h2>{setBrandName(clip.aggregator)}</h2>
              </div>
            </div>
            <div className="buttons">
              {stateUser.groups.includes("externalPartners") ? (
                <a href={clip.attachment} download>
                  <Button
                    className="btn primary"
                    onClick={handleDownloadSubmission}
                  >
                    Download
                  </Button>
                </a>
              ) : (
                <CustomButton
                  className="btn primary"
                  navTo={`mailto:licensing@doingthingsmedia.com?subject=I want to license a video!&body=I'm interested in licensing the video linked below:%0D%0A%0D%0Ahttps://doingthings.media/license/${clip.noteId}`}
                  title="License this video"
                  blankTarget
                />
              )}
            </div>
          </div>
          <div className="bottom-section">
            <div className="video-details">
              <h3 className="label">Media Details</h3>
              <div className="details-row">
                <h4
                  className="copyable"
                  onClick={(e) => handleCopyToClipboard(e, clip.id)}
                >
                  <span>Media ID:</span> {clip.id}
                  <FontAwesomeIcon icon={fas("copy")} />
                </h4>
                {stateUser.groups.includes("externalPartners") && (
                  <>
                    <h4>
                      <span>Video Title:</span>{" "}
                      {clip.videoTitle !== ""
                        ? clip.videoTitle
                        : "No Title Yet!"}
                    </h4>
                    <h4>
                      <span>User Credit:</span>{" "}
                      {clip.userCredit !== ""
                        ? clip.userCredit
                        : "Awaiting User Confirmation."}
                    </h4>
                  </>
                )}
              </div>
            </div>
            <div className="tags">
              <h3>Tags</h3>
              <div className="tag-grid">
                {tags && tags.length > 0 ? (
                  tags.map((tag) => (
                    <div className="tag" key={tag.id}>
                      {tag.value}
                    </div>
                  ))
                ) : (
                  <div>Media not tagged.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LicenseIndividual;
