import React, { createContext, useReducer } from 'react';
import externalpartnerReducer from '../reducers/ExternalPartnerReducer';

const defaultExternalPartner = { id: '', email: '' };
export const ExternalPartnerContext = createContext();

export function ExternalPartnerProvider({ children }) {
  const [externalPartnerState, externalPartnerDispatch] = useReducer(
    externalpartnerReducer,
    defaultExternalPartner
  );

  return (
    <ExternalPartnerContext.Provider
      value={{ externalPartnerState, externalPartnerDispatch }}
    >
      {children}
    </ExternalPartnerContext.Provider>
  );
}
