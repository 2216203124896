import React, {useState, useEffect} from "react";
import {Container, Row, Col, Form, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {Helmet} from "react-helmet";
import {forgotPassword, confirmResetPassword} from "../libs/awsLib";
import {TextInput, CustomButton} from "../components/form";


const ForgotPassword = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState({
    emailState: "",
    passwordState: "",
    confirmationCodeState: "",
  });
  const [awsError, setAwsError] = useState("");

  useEffect(() => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{6,}/;

    if (confirmationCode.length > 0) {
      if (confirmationCode.length === 6 ) {
        setValidate((oldValidate) => ({...oldValidate, confirmationCodeState: "has-success"}));
      } else {
        setValidate((oldValidate) => ({...oldValidate, confirmationCodeState: "has-danger"}));
      }
    }

    if (password.length > 0) {
      if (passwordRegex.test(password)) {
        setValidate((oldValidate) => ({...oldValidate, passwordState: "has-success"}));
      } else {
        setValidate((oldValidate) => ({...oldValidate, passwordState: "has-danger"}));
      }
    }
  }, [password, confirmationCode]);

  const toggleModal = () => {
    setAwsError("");
    setShowModal(!showModal);
  };

  const showVerificationModal = () => {
    setAwsError("");
    setShowModal(true);
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    if (!Object.values(validate).includes("has-danger")) {
      forgotPassword(email, showVerificationModal)
        .catch((error) => {
          setAwsError(error.message);
        });
    }
  };

  const handleResetPasswordCode = async (event) => {
    event.preventDefault();

    if (!Object.values(validate).includes("has-danger")) {
      confirmResetPassword(email, password, confirmationCode)
        .then((result) => {
          toggleModal();
          props.authenticateUser(result.accessToken.jwtToken);
          props.history.push("/");
        })
        .catch((error) => {
          setAwsError(error.message);
        });
    }
  };

  return (
    <Container className="forgot-screen" fluid>
      <Helmet>
        <title>Forgot Password | Doing Things Media</title>
        <meta
          name="description"
          content="Forgot Password? Enter your email here."
        />
      </Helmet>
      <div className="forgot-password">
        <div className='form-head'>
          <h2>Forgot Password</h2>
          <p>Confirm your email and we'll send the instructions.</p>
        </div>
        <Form onSubmit={ handleForgotPassword }>
          <Row className='form-row'>
            <Col>
              <TextInput
                styling="standard-input"
                id="email"
                required
                name="email"
                value={ email }
                labelTitle="Email"
                handleChange={ (e) => setEmail(e.target.value) }
              />
            </Col>
          </Row>
          <Row className="btn-row">
            <a href="/" alt="" className="go-back">Return to Sign In Page</a>
            <CustomButton
              value="Send Forgot Password Code"
              title="Submit"
              styling="primary"
              type="submit"
            />
            <p className="error">{awsError}</p>
          </Row>
        </Form>
      </div>
      <Modal isOpen={ showModal } toggle={ toggleModal }>
        <ModalHeader className="forgot-password" toggle={ toggleModal }>
          Reset Password Code
        </ModalHeader>
        <Form onSubmit={ handleResetPasswordCode }>
          <ModalBody className="forgot-password-body">
            <p>Input the password reset code that was emailed to you and your new password below.</p>
            <TextInput
              text="Reset Code *"
              id="confirmationCode"
              name="confirmationCode"
              labelTitle="Reset Code"
              value={ confirmationCode }
              placeholder="123456"
              handleChange={ (e) => setConfirmationCode(e.target.value) }
              valid={ validate.confirmationCodeState === "has-success" }
              invalid={ validate.confirmationCodeState === "has-danger" }
              feedbackText="a 6 digit number you recieved from your email"
            />
            <TextInput
              type="password"
              text="New Password *"
              labelSize={ 12 }
              id="password"
              name="password"
              labelTitle="New Password"
              value={ password }
              handleChange={ (e) => setPassword(e.target.value) }
              valid={ validate.passwordState === "has-success" }
              invalid={ validate.passwordState === "has-danger" }
              feedbackText="a password that includes at least eight characters, a number, and a special character"
            />
            <p className="login-error">{awsError}</p>
            {validate.passwordState === "has-danger" && (<span className="login-error">Your password must be 8 characters or more. It must also contain an uppercase letter, special character, and a number.</span>)}
            {validate.confirmationCodeState === "has-danger" && (<span className="login-error">Your confirmation code must be 6 characters long.</span>)}
            <br />
          </ModalBody>
          <ModalFooter className="forgot-password-footer">
            <CustomButton
              type="submit"
              value="resetPassword"
              title="Submit"
              styling="primary"
            />
            {" "}
          </ModalFooter>
        </Form>
      </Modal>
    </Container>
  );
};

export default ForgotPassword;
