import { Button } from "reactstrap";
import { fas } from "fontawesome.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { removeSubFromCampaign } from "../../libs/request/mRssFeed";
import { toast } from "react-toastify";
import { useMemo, useState } from "react";
import CampaignSubActions from "../../components/mrss/campaignActions";
import DataTable from "../DataTable";
import moment from "moment";

export default function FeedTable({
  tableData,
  labelName,
  labelFeed,
  totalDuration,
  itemCount,
  loadData,
  campaignId,
  externalPartnerId,
  showActionsColumn = true,
  removeFromLibrary = false,
}) {
  // Used for mRSS & External Partner Library Data presentation
  const [showActions, setShowActions] = useState(false);
  const [activeSub, setActiveSub] = useState();

  const handleShowSubActions = (submission) => {
    if (!showActions) {
      setActiveSub(submission);
      setShowActions(true);
    } else {
      setActiveSub({});
      setShowActions(false);
    }
  };

  const renderThumbnail = (submission) => (
    <div
      className="thumbnail"
      style={{ backgroundImage: `url("${submission.thumbnail}")` }}
    ></div>
  );

  const removeSub = async (submission) => {
    if (!!removeFromLibrary) {
      removeFromLibrary(submission.id);
      return;
    }

    if (externalPartnerId) {
      await removeFromLibrary(submission.id, externalPartnerId).then(() => {
        toast.success("Removed successfully.");
        loadData();
      });
    } else {
      await removeSubFromCampaign(submission.id, campaignId).then(async () => {
        toast.success("Removed successfully.");
        await loadData();
      });
    }
  };

  const renderActions = (submission) =>
    showActionsColumn && (
      <div className="actions">
        <Button className="danger" onClick={() => removeSub(submission)}>
          <FontAwesomeIcon icon={fas("trash")} />
        </Button>
      </div>
    );

  const renderTitle = (submission) => (
    <>
      <div className="title flex column left">
        <h2>{submission.videoTitle ? submission.videoTitle : "Untitled"}</h2>
        <p>{submission.privateDescription}</p>
      </div>
    </>
  );

  const renderDetails = (submission) => (
    <div className="actions">
      <span
        className="icon"
        role="button"
        onClick={() => handleShowSubActions(submission)}
      >
        <FontAwesomeIcon icon={fas("caret-down")} className="details" />
      </span>
    </div>
  );

  const renderTags = (subTags) => (
    <>
      {subTags && subTags.length ? (
        <>
          <div className="tag-wrapper">
            <div className="tag-grid">
              {subTags.map((tag, i) => (
                <span className="tag" key={i}>
                  {tag.value ? tag.value : tag}
                </span>
              ))}
            </div>
          </div>
        </>
      ) : (
        <span>No tags yet!</span>
      )}
    </>
  );

  const data = useMemo(() => tableData, [tableData]);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        disableSortBy: true,
        width: 20,
        Cell: (props) => renderDetails(props.row.original),
      },
      {
        Header: "Thumbnail",
        accessor: "thumbnail",
        width: 85,
        Cell: (props) => renderThumbnail(props.row.original),
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: (props) => renderTitle(props.row.original),
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: (props) =>
          renderTags(props.row.original.tags || props.row.original.tagValues),
      },
      {
        Header: "Actions",
        accessor: "actions",
        width: 75,
        className: "align-right",
        Cell: (props) => renderActions(props.row.original),
      },
    ],
    [tableData]
  );

  return (
    <>
      <div className="brand-feed list">
        <div className="header">
          <div className="header-col">
            <h1>{labelFeed}</h1>
            <span>{labelName}</span>
          </div>
          <div className="header-col">
            <span>
              {totalDuration
                ? `${itemCount.toLocaleString()} items with a total duration of ${moment
                    .utc(totalDuration * 1000)
                    .format("HH:mm:ss")}`
                : `${itemCount.toLocaleString()} items in this feed.`}
            </span>
          </div>
        </div>
        <DataTable columns={columns} data={data} />
        {showActions && (
          <CampaignSubActions
            submission={activeSub}
            setActiveSub={setActiveSub}
            setShowActions={setShowActions}
          />
        )}
      </div>
    </>
  );
}
