import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loader } from '../components'
import { deleteUser, searchAdminUsers, searchAlLUsers } from '../actions'
import { TextInput, DropDownField } from '../components/form'
import EditUser from './EditUser'
import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react'
import DataTable from './DataTable'
import Context from '../store/store'
import { fas } from 'fontawesome.macro'
import { toast } from 'react-toastify'

const adminGroups = [
  'ableToDownloadMedia',
  'admin',
  'brandManager',
  'externalPartners',
  'confirmers',
  'jukin',
  'pitchHammer',
]

const AdminUsers = () => {
  const [searchUserGroup, setSearchUserGroup] = useState('admin')
  const [targetUserGroup, setTargetUserGroup] = useState('')
  const [searchString, setSearchString] = useState('')
  const [adminUsers, setAdminUsers] = useState([])
  const [standardUsers, setStandardUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [errorAddUser, setErrorAddUser] = useState('')
  const [errorSearchUser, setErrorSearchUser] = useState('')
  const [showAdminData, setShowAdminData] = useState(true)
  const [showEditUser, setShowEditUser] = useState(false)
  const [userToEdit, setUserToEdit] = useState({})

  const { stateUser } = useContext(Context)

  const loadAdminUsers = useCallback(() => {
    setIsLoading(true)
    searchAdminUsers(searchUserGroup, searchString, true).then((response) => {
      if (response.message) {
        setErrorSearchUser(response.message)
        return
      }
      setAdminUsers(response)
      setIsLoading(false)
    })
  })

  useEffect(() => {
    loadAdminUsers()
  }, [])

  const editUser = (user) => {
    setUserToEdit(user)
    setShowEditUser(true)
  }

  const handleDeleteUser = (awsId) => {
    deleteUser(awsId).then(() => {
      toast.success('Successfully deleted user.')
      loadAdminUsers()
    }).catch(err => {
      console.log(err)
      toast.error('Something went wrong...')
    })
  }

  const renderGroups = (groups) => (
    <>
      {(groups && groups.length) ? (
        <>
          <div className="tag-grid">
            {groups.map((group, i) => <span className="tag" key={i}>{group}</span>)}
          </div>
        </>
      ) : (
        <div className='tag-grid'>
          <span className='tag'>None</span>
        </div>
      )}
    </>
  )

  const renderActions = ({ row }) => (
    <div style={{ display: 'flex', gap: '.5rem' }}>
      <Button
        className='btn edit'
        onClick={() => editUser(row.original)}
      >
        <FontAwesomeIcon icon={fas('edit')} />
      </Button>
      <Button
        className='btn danger'
        onClick={() => handleDeleteUser(row.original.awsId)}
      >
        <FontAwesomeIcon icon={fas('trash')} />
      </Button>
    </div>)

  const toggleTableData = () => {
    setSearchString('')
    setShowAdminData(!showAdminData)
  }

  const handleSearch = () => {
    setIsLoading(true)
    errorSearchUser ? setErrorSearchUser('') : null
    const adminSearch = showAdminData ? adminGroups.includes(searchUserGroup) : false
    if (adminSearch) {
      searchAdminUsers(searchUserGroup, searchString, adminSearch).then((res) => {
        setAdminUsers(res)
        setIsLoading(false)
      })
    } else {
      if (searchString === '' || searchString === ' ') {
        setErrorSearchUser('Please enter a search keyword!')
        setIsLoading(false)
        return
      } else {
        if (searchString.length < 3) {
          setErrorSearchUser('Keyword must be longer than three characters!')
          setIsLoading(false)
          return
        } else {
          searchAlLUsers('all', searchString, adminSearch).then((res) => {
            setStandardUsers(res)
            setIsLoading(false)
          })
        }
      }
      setIsLoading(false)
    }
  }

  const adminData = useMemo(() => adminUsers, [adminUsers])
  const standardUserData = useMemo(() => standardUsers, [standardUsers])

  const columns = useMemo(() => [
    {
      Header: 'First Name',
      accessor: 'firstName',
      minWidth: 85,
      Cell: (props) => props.row.original.firstName,
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      minWidth: 85,
      Cell: (props) => props.row.original.lastName,
    },
    {
      Header: 'Email',
      accessor: 'email',
      minWidth: 85,
      Cell: (props) => props.row.original.email,
    },
    {
      Header: 'Groups',
      accessor: 'groups',
      minWidth: 85,
      Cell: (props) => renderGroups(props.row.original.groups),
    },
    {
      Header: 'Actions',
      minWidth: 85,
      Cell: (props) => renderActions(props),
    },
  ], [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {showEditUser && (
            <EditUser
              user={userToEdit}
              stateUser={stateUser}
              adminGroups={adminGroups}
              setTargetUserGroup={setTargetUserGroup}
              setShowEditUser={setShowEditUser}
              setUserToEdit={setUserToEdit}
              loadAdminUsers={loadAdminUsers}
              setStandardUsers={setStandardUsers}
              setSearchString={setSearchString}
              setErrorAddUser={setErrorAddUser}
              setErrorSearchUser={setErrorSearchUser}
            />
          )}

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', marginTop: 16, gap: 16 }}>
            <div className='dropdownContainer' style={{ display: 'flex', justifyContent: 'center', gap: '2rem', width: '100%' }}>
              <TextInput
                labelTitle='Search user'
                textPlaceholder="Name or Email to Search"
                handleChange={(e) => setSearchString(e.target.value)}
                value={searchString}
                styling="standard-input"
              />
              {showAdminData && (
                <DropDownField
                  labelTitle="User group"
                  placeholder="Select user group"
                  handleChange={(e) => setSearchUserGroup(e.target.value)}
                  value={searchUserGroup}
                  options={adminGroups.map((g) => ({ title: g, value: g }))}
                />
              )}
              <Button className='btn' onClick={handleSearch}>Search</Button>
            </div>
            <div style={{ marginBottom: '1rem' }}>
              {errorSearchUser && (
                <div style={{ textAlign: 'center', color: 'red' }}>{errorSearchUser}</div>
              )}
              <Button className='btn' onClick={toggleTableData}>{showAdminData ? 'Toggle standard user data' : 'Toggle admin user data'}</Button>
            </div>
          </div>
          {errorAddUser && (<div className="center-red-text">{errorAddUser}</div>)}

          {showAdminData && adminData.length > 0 || !showAdminData && standardUsers.length > 0
            ? <DataTable columns={columns} data={showAdminData ? adminData : standardUserData} />
            : <div style={{ width: '100%', textAlign: 'center', padding: '2rem' }}>
              <h1 className='heading'>{searchString !== '' ? 'No Results Found!' : 'Please search for a user!'}</h1>
            </div>
          }
        </>
      )}
    </>
  )
}

export default AdminUsers
