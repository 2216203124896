import React, { useState } from 'react'
import {
  FormGroup, Input, Label, Dropdown, DropdownMenu,
  DropdownItem, DropdownToggle,
} from 'reactstrap'

const CategoryCheck = ({
  dashboardTitle, selectedCategories, simple, clearCheckboxes, selectAllCheckboxes,
  handleCheckChange,
}) => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false)
  const toggle = () => { setDropdownOpen(!dropdownOpen) }

  return (
    <div>
      <Dropdown nav isOpen={ dropdownOpen } toggle={ toggle }>
        <DropdownToggle nav caret>
          { dashboardTitle || 'Category' }
        </DropdownToggle>
        <DropdownMenu>
          { selectedCategories.map((category, index) => (
            <DropdownItem className="custom-dropdown-item" key={ category.name } onClick={ () => handleCheckChange(index, 'selectedCategories') }>
              <FormGroup check>
                <Label check>
                  <span className={ `checkbox ${ category.selected ? 'checked' : '' }` } />
                  <Input
                    type="checkbox"
                    key={ category.name }
                    checked={ category.selected }
                    value={ category.val }
                    readOnly
                  />
                  { category.name }
                </Label>
              </FormGroup>
            </DropdownItem>
          )) }
          { !simple && (
            <>
              <DropdownItem divider />
              <DropdownItem onClick={ () => clearCheckboxes('selectedCategories') }>Clear</DropdownItem>
              <DropdownItem onClick={ () => selectAllCheckboxes('selectedCategories') }>Select All</DropdownItem>
            </>
          ) }
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default CategoryCheck
