import { createContext, useReducer } from 'react';
import bulkDownloadReducer from '../reducers/BulkDownloadReducer';

const initalState = {
  bulkDownloads: [],
  submissions: [],
  totalDuration: 0,
  selectedDuration: 0,
};
export const BulkDownloadContext = createContext();

export function BulkDownloadProvider({ children }) {
  const [state, dispatch] = useReducer(bulkDownloadReducer, initalState);

  return (
    <BulkDownloadContext.Provider value={{ state, dispatch }}>
      {children}
    </BulkDownloadContext.Provider>
  );
}
