import { NavLink } from "react-router-dom";
import Context from "../../store/store";
import React, { useContext } from "react";

const Menu = () => {
  const context = useContext(Context);
  const { handleLogout, stateUser } = context;

  return (
    <>
      <ul className="menu">

        {/* Dashboard */}
        <li>
          <span>
            <NavLink activeClassName="active" to="/dashboard/">
              Dashboard
            </NavLink>
          </span>
        </li>

        {/* Partner Dashboard */}
        {stateUser.groups.includes("externalPartners") && (
          <>
            <li>
              <span>
                <NavLink activeClassName="active" to="/partners/library/">
                  Library
                </NavLink>
              </span>
            </li>
            <li className="hasSubmenu">
              <span>
                Feeds
                <svg
                  height="512"
                  viewBox="0 0 64 64"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M46.293 25.914 32.707 39.5a1 1 0 0 1-1.414 0L17.707 25.914a1 1 0 0 1 .707-1.707h27.172a1 1 0 0 1 .707 1.707z" />
                </svg>
                <ul className="submenu">
                  <li>
                    <NavLink to="/admin/mrss/">
                      <span>mRSS</span>
                    </NavLink>
                  </li>
                </ul>
              </span>
            </li>
          </>
        )}

        {/* Links for those in the "superAdmin" group. */}
        {stateUser.groups.includes("superAdmin") && (
          <>
            {/* Feeds */}
            <li className="hasSubmenu">
              <span>
                Feeds
                <svg
                  height="512"
                  viewBox="0 0 64 64"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M46.293 25.914 32.707 39.5a1 1 0 0 1-1.414 0L17.707 25.914a1 1 0 0 1 .707-1.707h27.172a1 1 0 0 1 .707 1.707z" />
                </svg>
                <ul className="submenu">
                  <li>
                    <NavLink to="/admin/mrss/">
                      <span>mRSS</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/partners/">
                      <span>Partners</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/brand_feed/">
                      <span>Brand Feeds</span>
                    </NavLink>
                  </li>
                </ul>
              </span>
            </li>

            {/* Stats */}
            <li className="hasSubmenu">
              <span>
                Stats
                <svg
                  height="512"
                  viewBox="0 0 64 64"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M46.293 25.914 32.707 39.5a1 1 0 0 1-1.414 0L17.707 25.914a1 1 0 0 1 .707-1.707h27.172a1 1 0 0 1 .707 1.707z" />
                </svg>
              </span>
              <ul className="submenu">
                <li>
                  <NavLink activeClassName="active" to="/admin/stats/">
                    <span>Platform Stats</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/tags/">
                    <span>Tag Metrics</span>
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* Logs */}
            {/* <li className="hasSubmenu">
              <span>
                Logs
                <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><path d="M46.293 25.914 32.707 39.5a1 1 0 0 1-1.414 0L17.707 25.914a1 1 0 0 1 .707-1.707h27.172a1 1 0 0 1 .707 1.707z" /></svg>
              </span>
              <ul className="submenu">
                <li><NavLink to="/admin/logs/"><span>User Activity</span></NavLink></li>
              </ul>
            </li> */}

            {/* Admin */}
            <li className="hasSubmenu">
              <span>
                Admin
                <svg
                  height="512"
                  viewBox="0 0 64 64"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M46.293 25.914 32.707 39.5a1 1 0 0 1-1.414 0L17.707 25.914a1 1 0 0 1 .707-1.707h27.172a1 1 0 0 1 .707 1.707z" />
                </svg>
              </span>
              <ul className="submenu">
                <li>
                  <NavLink to="/admin/users/">
                    <span>Manage Users</span>
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* Account */}
            {stateUser.awsId && (
              <li className="hasSubmenu">
                <span>
                  Account
                  <svg
                    height="512"
                    viewBox="0 0 64 64"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M46.293 25.914 32.707 39.5a1 1 0 0 1-1.414 0L17.707 25.914a1 1 0 0 1 .707-1.707h27.172a1 1 0 0 1 .707 1.707z" />
                  </svg>
                </span>
                <ul className="submenu">
                  <li>
                    <NavLink to="/admin/users/">
                      <span>Manage Profile</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#" onClick={handleLogout}>
                      Log Out
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
          </>
        )}
      </ul>
    </>
  );
};

export default Menu;
