import qs from "qs";
import { apiDelete, get, post } from "./common";
import { ROOT_URL } from "../../helpers/constants";
import axios from "axios";

function returnData(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        reject(error);
      });
  });
}

function postData(url, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        Sentry.captureException(error);
        reject(error);
      });
  });
}

function putData(url, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        reject(error);
      });
  });
}

export async function adminUpdateSubmission(clipId, body) {
  return fetch(`/api/clip/${clipId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    body: JSON.stringify(body),
  });
}

export async function approveClip(clip) {
  const data = postData("/api/clip/approve", clip);
  return data;
}

export const getSingleClip = (id) => {
  const url = `${ROOT_URL}getSingleClip?id=${id}`;
  const data = returnData(url);
  return data;
};

export const mongoSearch = (queryObject) => {
  const queryParams = qs.stringify(queryObject);
  const url = `${ROOT_URL}lightning?${queryParams}`;
  return get(url);
};

// Return clips based on provided params.
export const clipBrowse = (queryObject, isExternalPartner) => {
  const queryParams = qs.stringify(queryObject);
  const url = `${ROOT_URL}clip/browse?${queryParams}&isExternalPartner=${isExternalPartner}&${Date.now()}`;
  return get(url);
};

// Download a clip based on its params.
export const clipDownload = (queryObject) => {
  const queryParams = qs.stringify(queryObject);
  const url = `${ROOT_URL}clip/download?${queryParams}&${Date.now()}`;
  return fetch(url);
};

export const clipCount = (queryObject) => {
  const queryParams = qs.stringify(queryObject);
  const url = `${ROOT_URL}clip/count?${queryParams}&${Date.now()}`;
  return get(url);
};

export const getClipTags = (clipId) => {
  const url = `${ROOT_URL}clip/tags?clipId=${clipId}`;
  return get(url);
};

export const addClipTag = (clipTag) => {
  const url = `${ROOT_URL}tag_clip`;
  return post(url, clipTag);
};

export const removeClipTag = (subId, tagClipId) => {
  const url = `${ROOT_URL}tag_clip?subId=${subId}&tagId=${tagClipId}`;
  return apiDelete(url);
};

export async function submitClip(clip, clipFile, onProgress) {
  const formData = new FormData();
  formData.append("file", clipFile);

  Object.keys(clip).forEach((key) => {
    console.log(key);
    if (key === "revShare") {
      formData.append(key, clip[key] ? 1 : 0);
    } else if (key === "appearances") {
      if (clip[key]) {
        formData.append(key, JSON.stringify(clip[key]));
      }
    } else {
      formData.append(key, clip[key]);
    }
  });

  return await axios
    .post("/api/clip", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
}

export const uploadAuthDoc = (id, authDoc) => {
  const formData = new FormData();
  formData.append("file", authDoc);
  formData.append("id", id);
  return fetch("/api/uploadAuthDoc", {
    method: "POST",
    body: formData,
  });
};

export const submitTempClip = (clip, clipFile, isBrandManager = false) =>
  fetch("/api/tempClip", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...clip,
      size: clipFile.size,
      originalFilename: clipFile.name,
      type: clipFile.type,
      isBrandManager: isBrandManager,
    }),
  }).then((response) => response.json());

export const uploadClipFile = (clipId, file) => {
  const data = new FormData();
  data.append("file", file);
  return fetch(`/api/clipFile/${clipId}`, {
    method: "POST",
    body: data,
  }).then((response) => response.json());
};

export const getClipFiles = (clipId) => get(`/api/clipFile/${clipId}`);

export const deleteClipFile = (fileId) => apiDelete(`/api/clipFile/${fileId}`);

export async function adminUpdateSubmissionThumbnail(clipId, thumbnailTime) {
  return fetch(`/api/clipThumbnail/${clipId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    body: JSON.stringify({
      thumbnailTime,
    }),
  });
}

export async function deleteClip(clipId) {
  return fetch(`/api/clip/${clipId}`, {
    method: "delete",
  });
}

export const watermarkClip = (noteId) => fetch(`/api/addWatermark/${noteId}`);

export const markAsViewed = async ({ submissionId, userId }) =>
  await postData("/api/viewed", { submissionId, userId });
