import React from 'react'

const Loader = ({
  styling,
}) => (
  <div>
    <div className={`loader ${ styling ? styling : '' }`}>
      <div className='blocks'>
        <div className='block block1'></div>
        <div className='block block2'></div>
        <div className='block block3'></div>
        <div className='block block4'></div>
      </div>
    </div>
  </div>
)

export default Loader
