import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'
import moment from 'moment'
import { Container, Row, Col } from 'reactstrap'
import { useDebounce } from '../libs/utils'
import { searchLogs } from '../actions'
import { setBrandLogo } from '../helpers/global'
import { actionTypeValues } from '../helpers/constants'
import { Loader, PaginationBar } from '../components'
import { TextInput, DropDownField, CustomButton } from '../components/form'

const AdminUserLogsScreen = () => {
  const [searchString, setSearchString] = useState('')
  const [searchType, setSearchType] = useState('clip')
  const [searchAction, setSearchAction] = useState('')
  const [searchUserName, setSearchUserName] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [searchObject, setSearchObject] = useState({
    term: '',
    type: 'clip',
    action: '',
    username: '',
    page: 0,
  })
  const [logCount, setLogCount] = useState(0)
  const [userLogs, setUserLogs] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setSearchObject((prevSearchObject) => {
      if (
        JSON.stringify(prevSearchObject) ===
        JSON.stringify({
          term: searchString,
          type: searchType,
          action: searchAction,
          username: searchUserName,
          page: currentPage,
        })
      ) {
        return prevSearchObject
      }
      let page = currentPage
      if (currentPage === prevSearchObject.page) {
        setCurrentPage(0)
        page = 0
      }
      return {
        term: searchString,
        type: searchType,
        action: searchAction,
        username: searchUserName,
        page,
      }
    })
  }, [currentPage, searchAction, searchString, searchType, searchUserName])

  const debouncedSearchObject = useDebounce(searchObject, 500)

  const loadLogs = useCallback(() => {
    setIsLoading(true)
    searchLogs(debouncedSearchObject)
      .then((response) => {
        setUserLogs(response.logs)
        setLogCount(response.count)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [debouncedSearchObject])

  useEffect(() => {
    loadLogs()
  }, [debouncedSearchObject, loadLogs])

  const changePage = (page) => {
    setCurrentPage(page)
    setIsLoading(true)
  }

  const offsetNumber = !Number.isNaN(parseInt(currentPage, 10))
    ? currentPage + 1
    : ''

  const renderDetailKey = (key, value) => {
    if (!value) {
      return ''
    }
    const ratingStatus = [
      'posted',
      'approved',
      'bookmarked',
      'isViral',
      'revShare',
      'didYouRecordConfirm',
      'vimeo',
      'isCurrentlyFeatured',
      'noExclusive',
      'overEighteen',
      'stayUpdated',
      'didYouRecord',
    ]
    const label =
      key.charAt(0).toUpperCase() + key.replace(/([A-Z])/g, ' $1').slice(1)

    switch (key) {
      case 'aggregator':
        return (
          <img
            src={setBrandLogo(value)}
            className='agg-logo-table'
            title={value}
            alt={value}
          />
        )
      case 'videoTitle':
        return (
          <span>
            Video Title :&nbsp;
            {value}
          </span>
        )
      case 'email':
        return (
          <span>
            User Email :&nbsp;
            {value}
          </span>
        )
      case 'description':
        return (
          <span>
            Video Description :&nbsp;
            {value}
          </span>
        )
      case 'attachment':
        return (
          <span>
            Video File :&nbsp;
            <a href={value}>{value}</a>
          </span>
        )
      case 'contractFilename':
        return (
          <span>
            Contract :&nbsp;
            <a href={value}>{value}</a>
          </span>
        )
      case 'disapprove':
        return (
          <span>
            Disapproved :&nbsp;
            {value ? 'True' : 'False'}
          </span>
        )
      case 'wouldDownload':
        return (
          <span>
            Would Download :&nbsp;
            {value ? 'Yes' : 'No'}
          </span>
        )
      case 'clipUrl':
        return (
          <span>
            Clip Url :&nbsp;
            <a href={value}>{value}</a>
          </span>
        )
      case 'isLoggedIn':
        return (
          <span>
            Is Logged In :&nbsp;
            {value === 'nope' ? 'No' : 'Yes'}
          </span>
        )
      default:
        if (ratingStatus.includes(key)) {
          return (
            <div key={key}>
              {label}
              &nbsp;:&nbsp;
              {value ? 'Yes' : 'No'}
            </div>
          )
        }
        return (
          <span>
            {label}
            &nbsp;:&nbsp;
            {value && value.toString()}
          </span>
        )
    }
  }

  const renderVideoDetails = ({ original }) => {
    const details = JSON.parse(original.details)
    return (
      <Container className='additional-info'>
        <Col className='row-info' key='objectId'>
          Video Id :&nbsp;
          {original.objectId}
        </Col>
        {Object.keys(details).map((key) => (
          <Col className='row-info' key={key}>
            {renderDetailKey(key, details[key])}
          </Col>
        ))}
      </Container>
    )
  }

  const data = useMemo(() => userLogs, [userLogs])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'username',
        minWidth: 85,
        Cell: (props) =>
          (props.row.original.awsId != null &&
            `${props.row.original.firstName || ''} ${props.row.original.lastName || ''}`) ||
          'Not Logged In',
      },
      {
        Header: 'Action',
        accessor: 'action',
        className: 'capitalize',
        Cell: (props) => props.row.original.action,
      },
      {
        Header: 'Date and Time',
        accessor: 'time',
        Cell: (props) =>
          moment(props.row.original.logTime).format('MMMM Do YYYY, h:mm:ss a'),
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    )

  return (
    <Row className='dashboard-screen'>
      {/* <Col xs={ 2 } sm={ 2 } md={ 2 } lg={ 2 }>
        <Navigation logo="cyan" pageStyle="white" tikTok="white" />
      </Col> */}
      <Col xs={12} sm={12} md={12} lg={12} className='content'>
        <div className='dropdown-row'>
          <TextInput
            type='text'
            textPlaceholder='Search Term'
            id='searchInput'
            name='searchText'
            handleChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            styling='standard-input'
          />
          <TextInput
            styling='standard-input'
            id='searchUserName'
            name='searchUserName'
            handleChange={(e) => setSearchUserName(e.target.value)}
            textPlaceholder='Search User Name'
            type='text'
            value={searchUserName}
          />
          <DropDownField
            placeholder='Select action type'
            labelTitle='Action type'
            id='logAction'
            name='logType'
            handleChange={(e) => setSearchAction(e.target.value)}
            value={searchAction}
            options={actionTypeValues}
          />
          <CustomButton
            title='Refresh Results'
            handleClick={() => {
              loadLogs()
            }}
            styling='btn simple'
            styles={{marginTop: 8}}
          />
        </div>
        <div className='table-container'>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                    <th key={i} {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <tr key={i} {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td key={i} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <PaginationBar
            changePage={changePage}
            clipCount={logCount}
            length={userLogs.length}
            offset={currentPage}
            offsetNumber={offsetNumber}
          />
        </div>
        <Loader styling={!isLoading ? 'hidden' : ''} />
      </Col>
    </Row>
  )
}

export default AdminUserLogsScreen
