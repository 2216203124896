const externalpartnerReducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return { ...state, ...action.payload };
    case 'CLEAR':
      return (state = {});
    default:
      break;
  }
};

export default externalpartnerReducer;
