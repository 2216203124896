import React, {useState} from "react";
import {
  FormGroup, Input, Label, Dropdown, DropdownMenu,
  DropdownItem, DropdownToggle,
} from "reactstrap";
import {SORTBY} from "../../helpers/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fas} from "fontawesome.macro";

const SortByDate = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div>
      <Dropdown nav isOpen={ dropdownOpen } toggle={ toggle }>
        <DropdownToggle nav caret>Date</DropdownToggle>
        <DropdownMenu>
          { SORTBY.map((category) => (
            <DropdownItem className="custom-dropdown-item" key={ category.name } onClick={ () => props.handleSort("createdAt", category.val) }>
              <FormGroup check>
                <Label check>
                  <FontAwesomeIcon icon={fas("circle")} className={ `${ props.orderBy.direction === category.val ? "checked " : "" }checkbox-circle` } color={ props.iconColor } />
                  <Input
                    type="checkbox"
                    key={ category.val }
                    onChange={ () => props.handleSort("createdAt", category.val) }
                    checked={ props.orderBy.direction === category.val }
                    value={ category.name }
                  />
                  { category.name }
                </Label>
              </FormGroup>
            </DropdownItem>
          )) }
          <DropdownItem divider />
          <DropdownItem>Clear Filter</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SortByDate;
