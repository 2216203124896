import { useGetSingleBrandFeed } from "../../hooks/BrandFeed/useGetSingleBrandFeed";
import { useMutateBrandFeed } from "../../hooks/BrandFeed/useMutateBrandFeed";
import { useParams } from "react-router-dom";
import FeedTable from "../../screens/components/FeedTable";
import Loader from "../Loader";
import PaginationBar from "../PaginationBar";

export default function BrandFeedView() {
  const { id } = useParams();
  const {
    data,
    id: brandId,
    brandName,
    count,
    offset,
    setOffset,
    isLoading,
    error,
    fetchSingleFeed,
  } = useGetSingleBrandFeed(id);
  const { removeFromFeed } = useMutateBrandFeed(brandId);

  const changePage = (num) => {
    setOffset(num);
  };

  const removeSubFromFeed = async (subId) => {
    await removeFromFeed(subId).then(async () => {
      await fetchSingleFeed();
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      {!!data && !!count && (
        <>
          <FeedTable
            labelFeed="Brand Feed"
            tableData={data}
            labelName={brandName}
            itemCount={count}
            loadData={fetchSingleFeed}
            campaignId={id}
            removeFromLibrary={(subId) => removeSubFromFeed(subId)}
          />
          <PaginationBar
            clipCount={count}
            length={data.length}
            changePage={changePage}
            offset={offset}
            offsetNumber={offset + 1}
          />
        </>
      )}
    </>
  );
}
