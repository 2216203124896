import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Container, Row, Col, Form } from 'reactstrap'
import { Navigation } from '../components'
import { TextInput, CustomButton } from '../components/form'


const LinkGen = () => {
  const [ email, setEmail ] = useState('')
  const [ newLink, setNewLink ] = useState('')

  const handleLinkSubmit = (event) => {
    event.preventDefault()
    let rsID = uuidv4()
    rsID = `${ rsID.substring(0, 2) }d${ rsID.substring(3) }`
    rsID = `${ rsID.substring(0, rsID.length - 1) }t`
    setNewLink(`https://www.doingthings.media/submit?rsID=${ rsID }&user=${ email }`)
  }

  return (
    <div>
      <Row className="link-generation-container">
        <Col md={ 12 } lg={ 12 } className="vertical-align">
          <Container>
            <h2>
              Generate links for revenue share and large uploads by entering an email below.
              Upon clicking submit, you will be provided with a new link.
            </h2>
            <Container className="revenue-share-form">
              <Form onSubmit={ handleLinkSubmit }>
                <div className="form-row">
                  <TextInput
                    id="email"
                    name="email"
                    type="text"
                    value={ email.replace(/ /g, '') }
                    handleChange={ (e) => setEmail(e.target.value) }
                    textPlaceholder="Enter email"
                  />
                  <CustomButton
                    title="Get My Link"
                    styling="btn alt"
                    value="Submit"
                    type="submit"
                  />
                </div>
                <Row>
                  <h3>Your link will display below.</h3>
                </Row>
                <Row>
                  <Row>
                    <a href={ newLink }>
                      { newLink }
                    </a>
                  </Row>
                </Row>
              </Form>
            </Container>
          </Container>
        </Col>
      </Row>
    </div>
  )
}

export default LinkGen