export const ADD_DOWNLOAD = "ADD_DOWNLOAD";
export const REMOVE_DOWNLOAD = "REMOVE_DOWNLOAD";
export const SET_STATE = "SET_STATE";

const bulkDownloadReducer = (state, action) => {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    case ADD_DOWNLOAD:
      return {
        ...state,
        bulkDownloads: state.bulkDownloads.concat(action.payload.download),
        selectedDuration: (state.selectedDuration +=
          action.payload.download.duration),
      };
    case REMOVE_DOWNLOAD:
      return {
        ...state,
        bulkDownloads: state.bulkDownloads.filter(
          (download) => download.id !== action.payload.download.id
        ),
        selectedDuration:
          state.selectedDuration - action.payload.download.duration > 0
            ? state.selectedDuration - action.payload.download.duration
            : 0,
      };
    default:
      break;
  }
};

export default bulkDownloadReducer;
