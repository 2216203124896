import axios from "axios";
import { ROOT_URL } from "../../helpers/constants";

function returnData(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        reject(error);
      });
  });
}

export const getFeed = () => {
  const url = `${ROOT_URL}feed/fetch`;
  const data = returnData(url);
  return data;
};

export const addSubToFeed = (noteId) => {
  const url = `${ROOT_URL}feed/add?noteId=${noteId}`;
  const data = returnData(url);
  return data;
};

export const removeSubFromFeed = (id, noteId) => {
  const url = `${ROOT_URL}feed/remove?id=${id}&noteId=${noteId}`;
  const data = returnData(url);
  return data;
};

export const addSubToCampaign = (id, campaignId) => {
  const url = `${ROOT_URL}campaign/addSub?id=${id}&campaignId=${campaignId}`;
  const data = returnData(url);
  return data;
};

export const removeSubFromCampaign = (subId, campaignId) => {
  const url = `${ROOT_URL}campaign/removeSub?clipId=${subId}&campaignId=${campaignId}`;
  const data = returnData(url);
  return data;
};

export const getAllCampaigns = () => {
  const url = `${ROOT_URL}campaign/all`;
  const data = returnData(url);
  return data;
};

export const getCampaigns = (id = undefined) => {
  const url = `${ROOT_URL}campaign/all?id=${id}`;
  const data = returnData(url);
  return data;
};

export const getSingleCampaign = (campaignId) => {
  const url = `${ROOT_URL}campaign/fetch?campaignId=${campaignId}`;
  const data = returnData(url);
  return data;
};

export const createCampaign = (campaignName) => {
  const url = `${ROOT_URL}campaign/create?campaignName=${campaignName}`;
  const data = returnData(url);
  return data;
};

export const removeCampign = (campaignId) => {
  const url = `${ROOT_URL}campaign/remove?campaignId=${campaignId}`;
  const data = returnData(url);
  return data;
};

export const editCampaign = (campaignId, campaignName) => {
  const url = `${ROOT_URL}campaign/edit?campaignId=${campaignId}&campaignName=${campaignName}`;
  const data = returnData(url);
  return data;
};
