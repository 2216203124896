// Regex pattern to validate an email address.
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Regex pattern to validate a phone number.
export const phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;

// Validate a Revenue Share
async function validateRevShare(revShareQuery, email) {
  if (revShareQuery.user && revShareQuery.rsID) {
    // Must only evaluate to 'truthy' for errors if email or rsID invalid
    return validateRevShareEmail(revShareQuery.user, email) ||
      validateRevShareID(revShareQuery.rsID) ||
      await validateRevShareNotUsed(revShareQuery.rsID) || false;
  }

  // No query params so no errors
  return null;
}

// Validate a rev share email address.
function validateRevShareEmail(urlUser, formEmail) {
  const response = "Email in form does not match user in revenue share link, please check email matches the email you provided to Doing Things Media.";
  return urlUser === formEmail ? false : response;
}

// Validate a rev share ID.
function validateRevShareID(rsID) {
  const response = "Revenue share ID is not valid. The link you followed may have been changed or it may have expired. Please reach out to Doing Things Media for a new link.";
  return revShareIDRegexMatch(rsID) ? false : response;
}

// Determine if a rev share ID has been used already.
async function validateRevShareNotUsed(rsID) {
  const response = await fetch(`/api/revShare/${rsID}`);
  const json = await response.json();
  return json.message;
}

// Validate a rev share ID.
function revShareIDRegexMatch(rsID) {
  return /^.{2}d.+-.+t$/.test(rsID);
}

// Validate a submission.
export function validateSubmission(input) {
  // Initiate the output where everything is invalid.
  const results = {

    // User Info
    email: emailRegex.test(input.clip.email),

    // Brand
    aggregator: input.clip.aggregator.length > 0,

    // Video Details
    file: input.file ? true : false,
    privateDescription: input.clip.privateDescription.length > 0,
    location: input.clip.location.length > 0,

    // Terms
    didYouRecord: input.clip.didYouRecord,
    noExclusive: input.clip.noExclusive,
    overEighteen: input.clip.overEighteen,
    reviewedRights: input.clip.reviewedRights,
    reviewedTermsPrivacy: input.clip.reviewedTermsPrivacy,

  };

  // If a Brand Manager then mark fields as False (which means valid for some reason).
  if (input.isBrandManager) {
    results.firstName = true;
    results.lastName = true;
    results.didYouRecord = true;
    results.noExclusive = true;
    results.overEighteen = true;
    results.reviewedRights = true;
    results.reviewedTermsPrivacy = true;
    results.email = true;
  }

  // Return the results.
  return results;
}
