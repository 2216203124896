import React, { useState, useEffect, useContext, useCallback } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import pluralize from "pluralize";
import stemmer from "stemmer";
import { toast } from "react-toastify";
import {
  addClipTag,
  createTag,
  getClipTags,
  removeClipTag,
  tagList,
} from "../../libs/request";
import { handleCheckChange } from "../../libs/utils";
import Context from "../../store/store";

const errorAutoClose = 10000;

const AdminTags = ({ currentClip, handleTagsUsedChange, updatedClip }) => {
  const context = useContext(Context);
  const { aggregator } = updatedClip;
  const { stateUser } = context;
  const [newTag, setNewTag] = useState("");
  const [newTags, setNewTags] = useState([]);
  const [addTagMessage, setAddTagMessage] = useState("");
  const [selectedCategories, setSelectedCategories] = useState({
    id: 15,
    name: "Other",
    selected: false,
    title: "Other",
    val: "other",
  });
  const [suggestions, setSuggestions] = useState(
    context.tags.map((tagObject) => {
      tagObject.id = `${tagObject.id}`;
      tagObject.text = tagObject.value;
      tagObject.className = tagObject.approved ? "" : "pendingTag";
      return tagObject;
    })
  );
  const [tags, setTags] = useState([]);
  const [prevAgg, setPrevAgg] = useState(currentClip.aggregator);

  useEffect(() => {
    getClipTags(currentClip.id).then((result) => {
      if (result.tags.length === 0) {
        return;
      }
      const { tags } = result;
      const tagObj = tags.map((tagObject) => ({
        ...tagObject,
        text: tagObject.value,
        className: tagObject.approved ? "" : "pendingTag",
      }));
      setTags(tagObj);
    });
  }, [currentClip.id]);

  const handleDelete = (i) => {
    if (i > -1) {
      return new Promise((resolve, reject) => {
        removeClipTag(currentClip.id, tags[i].id)
          .then((result) => {
            if (result.status === 200) {
              if (
                tags.filter(
                  (tag, index) =>
                    tag.text.toLowerCase() !==
                      currentClip.aggregator.toLowerCase() && index !== i
                ).length === 0
              ) {
                handleTagsUsedChange(currentClip.noteId, false);
              }
              setTags(tags.filter((tag, index) => index !== i));
              toast.success("Tag Removed");
              resolve();
            } else {
              reject(result.error);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong.");
            toast.error(error.message);
          });
      });
    } else {
      return Promise.resolve();
    }
  };

  const generateTag = (tagCandidate) => {
    createTag(tagCandidate)
      .then((response) => {
        if (response.error) {
          toast.error(response.error, { autoClose: errorAutoClose });
          return;
        } else {
          const tagId = `${response.id}`;
          addClipTag({
            tagId,
            clipId: currentClip.id,
          });
          setTags([
            ...tags,
            {
              ...tagCandidate,
              text: `${response.value}`,
            },
          ]);
          toast.success("Tag added!");
        }
      })
      .catch((err) => toast.error(err, { autoClose: false }));
  };

  // const handleAggChange = useCallback(async () => {
  //   const oldAgg = prevAgg;
  //   const newAgg = aggregator;

  //   if (![oldAgg, newAgg].includes(undefined) && tags.length > 0) {
  //     const oldTag = tags.filter((tag) => tag.text === oldAgg.toLowerCase());
  //     const newAggTag = context.tags.filter(
  //       (tagId) => tagId.text === newAgg.toLowerCase()
  //     );

  //     if (oldAgg !== newAgg) {
  //       if (
  //         tags.filter((tag) => tag.text !== newAggTag[0].text).length > 0 ||
  //         tags === ""
  //       ) {
  //         if (
  //           tags.filter((tag) => tag.text === prevAgg.toLowerCase()).length > 0
  //         ) {
  //           await removeClipTag(oldTag[0].id)
  //             .then((result) => {
  //               if (result === "OK") {
  //                 return;
  //               }
  //               toast.error(result.error ? result.error : result, {
  //                 autoClose: errorAutoClose,
  //               });
  //             })
  //             .catch((error) => {
  //               toast.error(error.message, { autoClose: false });
  //             });
  //         }

  //         const tagId = newAggTag[0].id;
  //         await addClipTag({
  //           tagId,
  //           clipId: currentClip.id,
  //         })
  //           .then((response) => {
  //             const filterTags = tags.filter(
  //               (tag) => tag.text !== prevAgg.toLowerCase()
  //             );
  //             setTags([
  //               ...filterTags,
  //               { ...newAggTag[0], id: `${response.id}` },
  //             ]);
  //             handleTagsUsedChange(currentClip.noteId, true);
  //             toast.success("Tag updated");
  //           })
  //           .catch((err) => toast.error(err, { autoClose: false }));
  //       }
  //     }
  //     setPrevAgg(newAgg);
  //   }
  // }, [
  //   aggregator,
  //   context.tags,
  //   currentClip.id,
  //   currentClip.noteId,
  //   handleTagsUsedChange,
  //   prevAgg,
  //   tags,
  // ]);

  // useEffect(() => {
  //   handleAggChange();
  // }, [aggregator, handleAggChange]);

  // useEffect(() => {
  //   handleAggChange();
  // }, [handleAggChange, updatedClip.aggregator]);

  const handleCheckChangeTags = (index) => {
    handleCheckChange(index, selectedCategories, setSelectedCategories);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const appliedTags = tags.slice();

    appliedTags.splice(currPos, 1);
    appliedTags.splice(newPos, 0, tag);

    // re-render
    setTags(appliedTags);
  };

  const handleAddition = (tag) => {
    const tagId = tag.id;
    addClipTag({
      tagId,
      clipId: currentClip.id,
    })
      .then((response) => {
        if (response.error) {
          toast.error(response.error, { autoClose: errorAutoClose });
          return;
        }
        setTags([...tags, { ...tag, id: `${response.id}` }]);
        handleTagsUsedChange(currentClip.noteId, true);
        toast.success("Tag added");
      })
      .catch((err) => toast.error(err, { autoClose: false }));
  };

  const handleRapidAddition = (tag) => {
    setAddTagMessage("");
    const { text } = tag;
    if (tags.filter((t) => t.value === text).length) {
      setAddTagMessage("Tag is already added!");
      return;
    } else if (context.tags.filter((t) => t.value === text).length) {
      setTags([...tags, tag]);
      handleAddition(tag);
    } else if (text.includes(" ")) {
      const value = context.tags.filter((t) => t.value === text);
      value.length ? setTags([...tags, tag]) : generateTag(text);
      // : setAddTagMessage(
      //     "Tag is multiple words. Is there a single word that can replace this tag? That will make searches easier."
      //   )
    } else {
      const stemmed = stemmer(text);
      const singular = pluralize.singular(text);
      if (context.tags.filter((t) => t.value === stemmed).length) {
        setTags([...tags, tag]);
        handleAddition(tag);
      } else if (context.tags.filter((t) => t.value === singular).length) {
        setTags([...tags, tag]);
      } else if (!context.tags.filter((t) => t.value === tag).length) {
        generateTag(text);
      } else {
        handleAddition(tag);
      }
    }
  };

  return (
    <div className="admin-tags">
      {addTagMessage && <span style={{ color: "red" }}>{addTagMessage}</span>}
      <ReactTags
        tags={tags}
        suggestions={suggestions}
        handleDelete={handleDelete}
        handleAddition={(tag) => handleRapidAddition(tag)}
        handleDrag={handleDrag}
        autocomplete={1}
        placeholder="Enter your tags here..."
        readOnly={stateUser.groups.includes("externalPartners")}
      />
    </div>
  );
};

export default AdminTags;
