import React, { useState } from "react";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FileUpload = ({ label, onChange, id }) => {
  const filenameId = `${id}-text`;
  const [fileUploaded, setFileUploaded] = useState(false);

  // Handles capturing filename and populating filename container
  const handleFileName = (event) => {
    const filename = document.getElementById(filenameId);
    const mediaFile = event.currentTarget.files[0];
    filename.innerText = mediaFile.name;
    setFileUploaded(true);
  };

  const handleChange = (e) => {
    onChange(e);
    handleFileName(e);
  };

  return (
    <>
      <label htmlFor={id} className="fileUpload">
        {/* <FontAwesomeIcon
          aria-hidden="true"
          icon='file-upload'
          className="fileUpload__icon"
        /> */}
        <span className="fileUpload__text">
          {label} <span className="required">*</span>
        </span>
        <Input
          type="file"
          name="videoUpload"
          label={label}
          id={id}
          onChange={(e) => handleChange(e)}
          className="fileUpload__input"
        />
      </label>
      <div className={"fileUpload__info " + (fileUploaded ? "active" : "")}>
        <span className="fileUpload__current">Current File:</span>
        <span id={filenameId} className="fileUpload__filename"></span>
      </div>
    </>
  );
};

export default FileUpload;
