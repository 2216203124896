export const SET_DATA = 'SET_DATA';

const agreementViewerReducer = (state, action) => {
  switch (action.type) {
    case SET_DATA:
      return { ...state, submission: { ...action.payload }, loading: false };
    default:
      break;
  }
};

export default agreementViewerReducer;
