import React, { useState, useEffect, useContext } from 'react'
import {
  Container, Modal, ModalHeader, ModalBody, ModalFooter, Col,
} from 'reactstrap'
import AdminTags from './AdminTags'
import AdminFiles from './AdminFiles'
import {
  CustomButton, TextInput, DropDownField, Checkbox, RadioImg,
} from '../form'
import { AGGREGATORS, yesNoOptions } from '../../helpers/constants'
import { isUserInGroup } from '../../helpers/global'
import Context from '../../store/store'

const admins = ['admin', 'superAdmin']
const executives = ['executiveAdmin', 'superAdmin']

const DashboardModal = ({
  currentClip, isOpen, updateForm, toggle, handleTagsUsedChange,
}) => {
  const aggregators = AGGREGATORS.filter(
    (aggregator) => aggregator.submittable
  )

  const context = useContext(Context)
  const { stateUser, isMobile } = context
  const [updatedClip, setUpdatedClip] = useState({})
  useEffect(() => {
    setUpdatedClip({
      ...currentClip,
    })
  }, [currentClip])

  const handleUpdate = async (event) => {
    // tagId being brought in, but would have to update another table (tags_clips)
    // this is not how setUpdateClip works, so maybe we do this later?
    const { target } = event
    const value = target.type === 'checkbox' ? +(target.checked) : target.value
    const { name } = target
    // aggregator radioImg does not allow passed name property
    setUpdatedClip({
      ...updatedClip,
      [name || 'aggregator']: value,
    })
  }

  return (
    <Modal isOpen={isOpen} className={isMobile ? 'mobile-dashboard' : 'dashboard-modal'}>
      <ModalHeader>Update Submission</ModalHeader>
      <ModalBody>
        {isUserInGroup(stateUser, executives) && (
          <>
            <Container>
              <div className="brand-options">
                <h1>Change brand:</h1>
                <RadioImg
                  options={aggregators}
                  defaultValue={updatedClip.aggregator || ''}
                  onChange={handleUpdate}
                />
              </div>
            </Container>
            <TextInput
              // textPlaceholder="First Name*"
              labelTitle="First Name"
              htmlFor="first-name"
              id="first-name"
              name="firstName"
              value={updatedClip.firstName || ''}
              handleChange={handleUpdate}
              type="text"
            />
            <TextInput
              htmlFor="last-name"
              labelTitle="Last Name"
              id="last-name"
              name="lastName"
              value={updatedClip.lastName || ''}
              handleChange={handleUpdate}
              type="text"
            // textPlaceholder="Last Name*"
            />
            <TextInput
              handleChange={handleUpdate}
              value={updatedClip.email || ''}
              required
              labelTitle="Email"
              id="email"
              name="email"
              htmlFor="email"
              type="email"
            // textPlaceholder="Email"
            />
            <TextInput
              id="paypalEmail"
              name="paypalEmail"
              value={updatedClip.paypalEmail || ''}
              labelTitle="Paypal Email"
              handleChange={handleUpdate}
              type="email"
            // textPlaceholder="Paypal Email*"
            />
            <TextInput
              textPlaceholder="Instagram Handle"
              id="userCredit"
              name="userCredit"
              labelTitle="Instagram user to credit"
              value={updatedClip.userCredit || ''}
              handleChange={handleUpdate}
              type="text"
            />
            <TextInput
              text="Private Description"
              value={updatedClip.privateDescription || ''}
              handleChange={handleUpdate}
              textPlaceholder="Add a private description to the video"
              type="textarea"
              labelTitle="Private Description"
              id="privateDescription"
              name="privateDescription"
              styling="essay-input"
            />
            <Col className="copy">
              <CustomButton
                styling="simple"
                title="Copy User Description"
                type="submit"
                handleClick={() => setUpdatedClip({
                  ...updatedClip,
                  description: updatedClip.privateDescription,
                })}
              />
            </Col>
            <TextInput
              text="Public Description"
              value={updatedClip.description || ''}
              handleChange={handleUpdate}
              textPlaceholder="Add a new public description to the video"
              labelTitle="Public Description"
              type="textarea"
              id="description"
              name="description"
              styling="essay-input"
            />
          </>
        )}
        {isUserInGroup(stateUser, admins) && (
          <DropDownField
            options={yesNoOptions}
            labelTitle="Clip Used In A Compilation"
            value={updatedClip.isInACompilation}
            id="isInACompilation"
            name="isInACompilation"
            handleChange={handleUpdate}
            lgCol={12}
          />
        )}
        {isUserInGroup(stateUser, executives) && (
          <>
            <TextInput
              textPlaceholder="Location (Optional)"
              id="location"
              name="location"
              labelTitle="Location"
              value={updatedClip.location || ''}
              handleChange={handleUpdate}
              type="location"
            />
            {stateUser.groups && stateUser.groups.includes('superAdmin') && (
              <DropDownField
                options={yesNoOptions}
                labelTitle="User recorded video"
                value={updatedClip.didYouRecordConfirm}
                id="didYouRecordConfirm"
                name="didYouRecordConfirm"
                handleChange={handleUpdate}
                lgCol={12}
              />
            )}
            <Col md={12} lg={12}>
              <AdminTags
                currentClip={currentClip}
                handleTagsUsedChange={handleTagsUsedChange}
                updatedClip={updatedClip}
              />
            </Col>
            <Col md={12} lg={12}>
              <AdminFiles currentClip={currentClip} />
            </Col>
          </>
        )}
        {stateUser.groups && stateUser.groups.includes('superAdmin') && (
          <div>
            <Checkbox
              faIcon="square"
              checkedIcon="check"
              styles="checkbox-input"
              id="isCurrentlyFeatured"
              name="isCurrentlyFeatured"
              checked={!!updatedClip.isCurrentlyFeatured}
              handleChange={handleUpdate}
              text="Add this to the licensing page to be currently featured."
            />
            <Checkbox
              faIcon="square"
              checkedIcon="check"
              styles="checkbox-input"
              id="revShare"
              name="revShare"
              checked={!!updatedClip.revShare}
              handleChange={handleUpdate}
              text="Add this video to the revenue share program."
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <CustomButton
          handleClick={() => {
            updateForm(currentClip.noteId, updatedClip)
          }}
          styling="simple"
          title="Update"
        />
        {' '}
        <CustomButton handleClick={toggle} styling="simple" title="Cancel" />
      </ModalFooter>
    </Modal>
  )
}

export default DashboardModal
