import React from 'react'
import { Button } from 'reactstrap'

const CustomButton = ({
  blankTarget,
  disableMe,
  handleClick,
  icon,
  navTo,
  styles,
  styling,
  title
}) => (
  <Button
    className={styling}
    style={styles}
    onClick={handleClick}
    href={navTo}
    disabled={disableMe}
    target={blankTarget ? '_blank' : ""}
  >
    {icon == 'chevron-left' && (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.69 233.37"><path d="M111.03 2.34A7.998 7.998 0 0 1 124.68 8v217.37c0 2.12-.84 4.15-2.34 5.65-3.12 3.12-8.19 3.13-11.31 0L2.34 122.34c-3.12-3.12-3.12-8.19 0-11.31L111.03 2.34Z"/></svg>
    )}
    <span>{title}</span>
    {icon == 'chevron-right' && (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.69 233.37"><path d="m13.66 2.34 108.68 108.69c3.12 3.12 3.12 8.19 0 11.31L13.66 231.03c-3.12 3.12-8.19 3.12-11.31 0a7.985 7.985 0 0 1-2.34-5.65V8C0 3.58 3.58 0 8 0c2.12 0 4.15.84 5.65 2.34Z"/></svg>
    )}
  </Button>
)

export default CustomButton
