import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import { setBrandLogo, stripVideoUUID } from "../helpers/global";
import { getClipTags, getSingleClip } from "../libs/request";
import { AGGREGATORS } from "../helpers/constants";
import { Loader } from "../components";

const MediaIndividual = () => {
  const [clip, setClip] = useState(null);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [strippedUUID, setStrippedUUID] = useState();

  // Return the formal name of a brand based on an clip.aggregator value.
  const setBrandName = (brand) => {
    if (AGGREGATORS.some((agg) => agg.slugname === brand)) {
      const target = AGGREGATORS.find((agg) => agg.slugname === brand);
      return target.name;
    }
    return null;
  };

  const loadData = () => {
    const id = window.location.pathname.split("/").pop();
    getSingleClip(id)
      .then((result) => {
        if (result !== undefined) {
          const { attachment, id } = result;
          result.fileUrl =
            attachment && attachment.includes("http")
              ? attachment
              : `${
                  attachment
                    ? attachment.substr(attachment.lastIndexOf("/"))
                    : ""
                }`;
          setClip(result);
          setTags(result.tags);
          setStrippedUUID(stripVideoUUID(attachment));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // If this component is rendered due to a click on a result in /license/ then additional context is passed in location.state
  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={`single-video ${!clip && "invalid"}`}>
          {!clip || !!clip.deleted ? (
            <h3>Sorry, this media is not available.</h3>
          ) : (
            <div className="content-section">
              <Helmet>
                <title>License Media | Doing Things Media</title>
                <meta name="description" content={clip.description} />
              </Helmet>
              {clip.fileUrl && (
                <ReactPlayer
                  config={{
                    file: {
                      attributes: {
                        onContextMenu: (e) => e.preventDefault(),
                        controlsList: "nodownload",
                      },
                    },
                  }}
                  controls
                  pip={false}
                  url={strippedUUID}
                  style={{
                    backgroundColor: "transparent",
                    maxHeight: "50vh",
                    maxWidth: "100%",
                  }}
                />
              )}
              <div className="top-section">
                <div className="brand">
                  <img
                    className="brand-logo"
                    src={setBrandLogo(clip.aggregator)}
                    width="30"
                    alt={clip.aggregator}
                  />
                  <div className="brand-text">
                    <h2>{setBrandName(clip.aggregator)}</h2>
                  </div>
                </div>
              </div>
              <div className="bottom-section">
                <div className="video-details">
                  <h3 className="label">Video Details</h3>
                  <div className="details-row">
                    <h4>
                      <span>Media ID:</span> {clip.id}
                    </h4>
                  </div>
                </div>
                <div className="tags">
                  <h3>Tags</h3>
                  <div className="tag-grid">
                    {tags && tags.length > 0 ? (
                      tags.map((tag) => (
                        <div className="tag" key={tag.id}>
                          {tag.value}
                        </div>
                      ))
                    ) : (
                      <div>Media not tagged.</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MediaIndividual;
