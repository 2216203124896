import { adminGroup, dtmAdmins } from "./helpers/constants";
import { isUserInGroup } from "./helpers/global";
import { Route, Redirect } from "react-router-dom";
import Context from "./store/store";
import React, { useContext, useEffect } from "react";

const routePermissions = {
  "/dashboard": {
    loginRequired: true,
    allowedGroups: adminGroup,
  },
  "/profile": {
    loginRequired: true,
    allowedGroups: false,
  },
  "/link-gen": {
    loginRequired: true,
    allowedGroups: dtmAdmins,
  },
  "/admin/stats": {
    loginRequired: true,
    allowedGroups: dtmAdmins,
  },
  "/admin/logs": {
    loginRequired: true,
    allowedGroups: ["superAdmin"],
  },
  "/admin/brand": {
    loginRequired: true,
    allowedGroups: dtmAdmins,
  },
  "/admin/users": {
    loginRequired: true,
    allowedGroups: ["superAdmin"],
  },
  "/admin/tags": {
    loginRequired: true,
    allowedGroups: ["superAdmin"],
  },
  "/admin/mrss": {
    loginRequired: true,
    allowedGroups: ["superAdmin", "externalPartners"],
  },
  "/admin/partner/": {
    loginRequired: true,
    allowedGroups: ["superAdmin"],
  },
  "/brandsubmission": {
    loginRequired: true,
    allowedGroups: ["admin", "brandManager"],
  },
};

export default ({ component: Component, props: childProps, ...rest }) => {

  // Correcting the destructuring to extract path from rest and necessary methods from the context.
  const context = useContext(Context);
  const { path } = rest;
  const { setMenuExpanded, stateUser } = context;

  // A React hook to collapse the menu every time the path changes.
  useEffect(() => {
    setMenuExpanded(false);
  }, [path, setMenuExpanded]);

  // A helper function to check if the user has permission to access the route.
  const checkPermissions = () => {
    if (path) {
      // Retrieve permissions for both the exact path and the path without a trailing slash, if applicable.
      const routePermission = routePermissions[path] || routePermissions[path.replace(/\/$/, "")];

      if (routePermission && routePermission.loginRequired) {
        // Check if stateUser exists and isAuthenticated is strictly not false before checking other conditions.
        return stateUser && stateUser.isAuthenticated !== false && (stateUser.awsId || !routePermission.allowedGroups || isUserInGroup(stateUser, routePermission.allowedGroups));
      }
    }
    return true;
  };

  // The main render method. It uses the checkPermissions function to conditionally render the component or redirect to the root path.
  return (
    <Route
      {...rest}
      render={props =>
        checkPermissions()
          ? <Component {...props} {...childProps} />
          : <Redirect to="/" />
      }
    />
  );
};