import React from "react";

const UploadProgress = ({
  styling,
  title,
  showProgress,
  progress,
  progressText,
}) => (
  <div>
    <div className={`upload-progress ${styling}`}>
      <div className="loaderContents">
        {progressText && (
          <div>
            <div className="progressText">{progressText}</div>
          </div>
        )}
        {title && (
          <div>
            <div className="title">{title}</div>
          </div>
        )}
        {showProgress && (
          <div>
            <div className="progressContainer"></div>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default UploadProgress;
