import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { Col, Form, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Checkbox, CustomButton } from "../components/form";
import { fetchBrandFromSlugname, loadStyles } from "../helpers/global";
import { getSingleClip, updateOneThing } from "../libs/request";
import { parseQS } from "../libs/utils";

// Green Checkmark
function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <ellipse cx="256" cy="256" fill="#32bea6" rx="256" ry="255.832"></ellipse>
      <path
        fill="#fff"
        d="M235.472 392.08l-121.04-94.296 34.416-44.168 74.328 57.904 122.672-177.016 46.032 31.888z"
      ></path>
    </svg>
  );
}

// Red X
function InvalidIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <ellipse cx="256" cy="256" fill="#e04f5f" rx="256" ry="255.832"></ellipse>
      <path
        fill="#fff"
        d="M376.821 337.177L337.23 376.77 135.225 174.773l39.592-39.593z"
      ></path>
      <path
        fill="#fff"
        d="M376.824 174.82L174.827 376.824l-39.593-39.592L337.23 135.228z"
      ></path>
    </svg>
  );
}

const ConfirmOwnership = (props) => {
  // Define States
  const [didYouRecordConfirm, setDidYouRecordConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isAlreadyConfirmed, setIsAlreadyConfirmed] = useState(false);
  const [submission, setSubmission] = useState(null);
  const [queryParams, setQueryParams] = useState(
    parseQS(window.location.search)
  );

  const loadData = () => {
    // Load the submission object using the UUID from the URL.
    if (queryParams.uuid) {
      try {
        return new Promise((resolve, reject) => {
          getSingleClip(queryParams.uuid)
            .then((response) => {
              if (!!response) {
                // Update State
                setIsAlreadyConfirmed(response.didYouRecordConfirm);
                setSubmission(response);

                // Load Styles
                loadStyles(response.aggregator);

                // Set the active brand.
                props.setActiveBrand(
                  fetchBrandFromSlugname(response.aggregator)
                );

                // Hide the loading screen.
                setTimeout(() => {
                  setLoading(false);
                }, 500);
              } else {
                toast.error("Something went wrong.");
                console.log(response.error);
                reject(response.error);
              }
            })
            .catch((error) => {
              toast.error(error.message);
              reject(error);
            });
        });
      } catch (error) {
        toast.error("Something went wrong.");
        console.log(error);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  // Called once the form is submitted.
  const verifyOwnership = (event) => {
    // Prevent reload.
    event.preventDefault();

    // If not confirmed.
    if (!didYouRecordConfirm) {
      setErrorMessage(
        "Please check the box above to confirm you own the content."
      );

      // If confirmed.
    } else if (!!queryParams.uuid) {
      // Update object in the database.
      updateOneThing("clip", queryParams.uuid, {
        didYouRecordConfirm: didYouRecordConfirm,
      }).then(() => {
        toast.success("Confirmation Submitted");
        props.history.push("/");
      });

      // URL is malformed.
    } else {
      setErrorMessage(
        "Please make sure you did not alter the link that was emailed to you. It contains information that we use to validate your submission."
      );
    }
  };

  if (isAlreadyConfirmed) {
    return (
      <div className="valign">
        <h1>This clip has already been confirmed.</h1>
      </div>
    );
  }
  if (isLoading) {
    return (
      <div className="valign">
        <h1>Loading your awesome clip...</h1>
      </div>
    );
  }
  return (
    <div className="confirm-ownership">
      <Row>
        <Col>
          <Helmet>
            <title>Confirm Ownership | Doing Things Media</title>
            <meta
              name="description"
              content="Confirm that you own the content you uploaded."
            />
          </Helmet>
          <Container>
            <div className="content">
              {/* Video Info */}
              <h1>Confirm that you own this {submission.contentType}</h1>
              <div className="media">
                <>
                  {submission.contentType == "video" && (
                    <video src={submission.attachment} controls>
                      Your browser does not support the video tag.
                    </video>
                  )}
                  {submission.contentType == "image" && (
                    <img src={submission.attachment} />
                  )}
                </>
                <div className="table">
                  <table>
                    <tbody>
                      <tr>
                        <td>Submission Date</td>
                        <td>
                          <Moment format="M/D/YY">
                            {submission.createdAt}
                          </Moment>
                          {" at "}
                          <Moment format="h:mma">{submission.createdAt}</Moment>
                        </td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>{submission.privateDescription}</td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>{submission.location}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Submitter Info */}
              <h1>Confirm your information</h1>
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td>First Name</td>
                      <td>{submission.firstName}</td>
                    </tr>
                    <tr>
                      <td>Last Name</td>
                      <td>{submission.lastName}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{submission.email}</td>
                    </tr>
                    <tr>
                      <td>User Credit</td>
                      <td>{submission.userCredit}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Legal Info */}
              <h1>Confirm this information</h1>
              <div className="table">
                <table className="borderless">
                  <tbody>
                    <tr>
                      {submission.reviewedRights ? (
                        <td className="valid">{CheckIcon()}</td>
                      ) : (
                        <td className="invalid">{InvalidIcon()}</td>
                      )}
                      <td>
                        I have reviewed and agreed to the{" "}
                        <a
                          href="https://doingthingsmedia.com/legal/agreement/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Licensing Agreement
                        </a>{" "}
                        and understand that I am transferring exclusive rights
                        to the submitted content.
                      </td>
                    </tr>
                    <tr>
                      {submission.reviewedTermsPrivacy ? (
                        <td className="valid">{CheckIcon()}</td>
                      ) : (
                        <td className="invalid">{InvalidIcon()}</td>
                      )}
                      <td>
                        I have reviewed and agreed to the{" "}
                        <a
                          href="https://doingthingsmedia.com/legal/terms/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://doingthingsmedia.com/legal/privacy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </td>
                    </tr>
                    <tr>
                      {submission.didYouRecord ? (
                        <td className="valid">{CheckIcon()}</td>
                      ) : (
                        <td className="invalid">{InvalidIcon()}</td>
                      )}
                      <td>
                        I was holding the camera when creating this video.
                      </td>
                    </tr>
                    <tr>
                      {submission.overEighteen ? (
                        <td className="valid">{CheckIcon()}</td>
                      ) : (
                        <td className="invalid">{InvalidIcon()}</td>
                      )}
                      <td>I am over the age of 18.</td>
                    </tr>
                    <tr>
                      {submission.noExclusive ? (
                        <td className="valid">{CheckIcon()}</td>
                      ) : (
                        <td className="invalid">{InvalidIcon()}</td>
                      )}
                      <td>
                        I have not signed an agreement with anyone else or any
                        other websites regarding the submitted content.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <Form onSubmit={verifyOwnership}>
              <Checkbox
                id="didYouRecordConfirm"
                className="checkbox"
                name="didYouRecordConfirm"
                checked={didYouRecordConfirm}
                text="By checking this box and clicking submit, I confirm that I own the content above."
                onClick={() => setDidYouRecordConfirm(!didYouRecordConfirm)}
                faIcon="check"
                checkedIcon="check"
                styles="checkbox-input-blue"
              />
              <span className="login-error">{errorMessage}</span>
              <Row className="btn-row">
                <CustomButton styling="primary" type="submit" title="Submit" />
              </Row>
            </Form>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmOwnership;
