import React from 'react'
import { Label, Row, Col, FormGroup, Input } from 'reactstrap'

const Checkbox = ({
  text, id, checked, name, handleChange, required, onClick, styles,
}) => (
  <Row className={styles}>
    <Col xs={12} sm={12}>
      <FormGroup check>
        <Label check>
          <Input
            required={required}
            type="checkbox"
            checked={checked}
            name={name}
            onChange={handleChange}
            onClick={onClick}
            id={id}
          />
          <div>
            <span className={`checkbox ${styles} ${checked ? 'checked' : ''}`} />
            {name === 'reviewedTermsPrivacy' ? (
              <>
                <div>I have reviewed and agreed to the <a style={{textDecoration: 'underline'}} href='https://www.doingthingsmedia.com/legal/terms' target='_blank'>Terms of Service</a> and <a style={{textDecoration: 'underline'}} href='https://www.doingthingsmedia.com/legal/privacy' target='_blank'>Privacy Policy</a></div>
              </>
            ) : name === 'reviewedRights' ? (
              <>
                <div>I have reviewed and agreed to the <a style={{textDecoration: 'underline'}} href='https://www.doingthingsmedia.com/legal/agreement' target='_blank'>License Agreement</a> and understand that I am transferring exclusive rights to the submitted content. </div>
              </>
            ) : text  }
          </div>
        </Label>
      </FormGroup>
    </Col>
  </Row>
)

export default Checkbox