import { Col, Button, Navbar } from 'reactstrap';
import { isBrandedSubmissionURL } from '../../helpers/global';
import Menu from './Menu';
import Context from '../../store/store';
import React, { useContext } from 'react';


const MobileNavigation = () => {
  const context = useContext(Context);
  const { activeBrand, setMenuExpanded, stateUser } = context;

  return (

    <Navbar id="mobile">

      <div className="mobile-header" style={{ display: 'none' }}>

        {/* Logo */}
        <a href="/" alt="Doing Things Media" className="branding">
          <img src={activeBrand.icon} alt={activeBrand.name} />
        </a>

        {/* Toggle */}
        <div className="mobile menu-collapsed">
          {(!isBrandedSubmissionURL() && stateUser.isAdmin === true) && (
            <Col className="menu-button">
              <Button alt="home" onClick={() => setMenuExpanded(true)}>
                <svg width="30" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="#E1FA00" strokeWidth="3" d="M0 1.5h30M0 9.5h30M0 17.5h30" /></svg>
              </Button>
              <Button alt="close" onClick={() => setMenuExpanded(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.33 23.33"><g fill="none" stroke="#e1fa00" strokeWidth="3"><path d="M1.06 22.27 22.27 1.06M1.06 1.06l21.21 21.21" /></g></svg>
              </Button>
            </Col>
          )}
        </div>

      </div>

      {/* Dropdown Menu */}
      {!isBrandedSubmissionURL() && stateUser.isAdmin === true && (
        <div className={`mobile-dropdown ${setMenuExpanded ? 'expanded' : ''}`}>
          <Menu></Menu>
        </div>
      )}

    </Navbar>

  );
};

export default MobileNavigation;
