import React, {useState} from "react";
import {Row, Col, Form, Input, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {Helmet} from "react-helmet";
import {Checkbox, TextInput, CustomButton} from "./form";

const REQUIRED_FIELDS_STEP1 = [
  "firstName",
  "lastName",
  "password",
  "email",
];
const REQUIRED_FIELDS_STEP2 = [
  "termsAgreement",
  "overEighteen",
];

// We are pulling in props from SignInSignUp for now,
// but there may be a cleaner useReducer solution
const SignUp = ({
  awsError,
  handleConfirmationCode,
  handleSignUp,
  showModal,
  toggle,
  toggleModal,
}) => {
  const [confirmationCode, setConfirmationCode] = useState("");
  const [validate, setValidate] = useState({
    confirmState: "",
    confirmationCodeState: "",
    emailState: "",
  });
  const [newSignUpData, setNewSignUpData] = useState({});
  const [localError, setLocalError] = useState("");
  const [secondStep, setSecondStep] = useState(false);

  const validateEmail = (value) => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      validate.emailState = "has-success";
    } else {
      validate.emailState = "has-danger";
    }
    setValidate(validate);
  };

  const validateConfirm = (value) => {
    const confirm = value;
    if (newSignUpData.password === confirm) {
      validate.confirmState = "has-success";
      setLocalError("");
    } else {
      validate.confirmState = "has-danger";
      setLocalError("Please ensure your passwords match.");
    }
    setValidate(validate);
  };

  const validateConfirmationCode = (value) => {
    const tempCode = value;
    if (tempCode.length === 6) {
      validate.confirmationCodeState = "has-success";
    } else {
      validate.confirmationCodeState = "has-danger";
    }
    setValidate(validate);
    setConfirmationCode(value);
  };

  const handleFieldUpdate = (event) => {
    const {target} = event;
    const value = target.type === "checkbox" ? +(target.checked) : target.value;
    const {name} = target;
    if (name === "email") {
      validateEmail(value);
      setNewSignUpData({...newSignUpData, [name]: value});
    } else if (name === "confirmPassword") {
      validateConfirm(value);
      setNewSignUpData({...newSignUpData, [name]: value});
    } else if (name === "confirmationCode") {
      validateConfirmationCode(value);
    } else {
      setNewSignUpData({...newSignUpData, [name]: value});
    }
  };

  const checkPasswordM3 = (password) => {
    const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const result = re.test(password);
    return result;
  };

  return (

    <Form onSubmit={(event) => {
      event.preventDefault();
      if (secondStep) {
        const tempError = REQUIRED_FIELDS_STEP2.reduce(
          (acc, curr) => !!acc && !!newSignUpData[curr], true,
        ) ?
          "" : "Please fill out all required fields (marked with *).";
        setLocalError(tempError);
        if (!tempError) {
          handleSignUp(newSignUpData);
        }
      } else {
        const tempError = REQUIRED_FIELDS_STEP1.reduce(
          (acc, curr) => !!acc && !!newSignUpData[curr], true,
        ) ?
          "" : "Please fill out all required fields (marked with *).";
        setLocalError(tempError);
        if (!tempError) {
          const pwValidation = checkPasswordM3(newSignUpData.password);
          pwValidation ? setSecondStep(true) : setLocalError("Password must contain at least 8 characters, 1 number, 1 uppercase letter, and 1 special character.");
        }
      }
    }}>

      <Helmet>
        <title>Sign Up | Doing Things Media</title>
        <meta name="description" content="Create an account for Doing Things Media" />
      </Helmet>

      {!secondStep && (
        <>
          <Row className="form-row split">
            <Col>
              <TextInput
                htmlFor="first-name"
                id="first-name"
                name="firstName"
                labelTitle="First Name"
                labelClass="required"
                value={newSignUpData.firstName || ""}
                type="text"
                styling="standard-input"
                handleChange={handleFieldUpdate}
                feedbackText="first name"
              />
            </Col>
            <Col>
              <TextInput
                htmlFor="last-name"
                id="last-name"
                name="lastName"
                labelTitle="Last Name"
                labelClass="required"
                value={newSignUpData.lastName || ""}
                styling="standard-input"
                type="text"
                handleChange={handleFieldUpdate}
                feedbackText="last name"
              />
            </Col>
          </Row>
          <Row className="form-row split">
            <Col>
              <TextInput
                id="phone"
                name="phone"
                labelTitle="Phone Number"
                labelClass="required"
                styling="standard-input"
                value={newSignUpData.phone || ""}
                handleChange={handleFieldUpdate}
              />
            </Col>
            <Col>
              <TextInput
                id="email"
                name="email"
                labelTitle="Email Address"
                labelClass="required"
                styling="standard-input"
                value={ newSignUpData.email || "" }
                handleChange={ handleFieldUpdate }
                invalid={ validate.emailState === "has-danger" }
                feedbackText="valid email"
              />
            </Col>
          </Row>
          <Row className="form-row split">
            <Col>
              <TextInput
                id="password"
                name="password"
                labelTitle="Password"
                labelClass="required"
                styling="standard-input"
                type="password"
                value={ newSignUpData.password || "" }
                handleChange={ handleFieldUpdate }
              />
            </Col>
            <Col>
              <TextInput
                id="confirmPassword"
                name="confirmPassword"
                labelTitle="Confirm Password"
                labelClass="required"
                styling="standard-input"
                type="password"
                value={ newSignUpData.confirmPassword || "" }
                handleChange={ handleFieldUpdate }
                invalid={ validate.confirmState === "has-danger" }
              />
            </Col>
          </Row>
          <Row className="disclaimer-container">
            <Col>
              <p>Your password must be 8 characters or more. It must also contain an uppercase letter, special character, and a number.</p>
            </Col>
          </Row>
          <Row className="error-container">
            <Col>
              <p>{localError || awsError}</p>
            </Col>
          </Row>
          <Row className="btn-container centered">
            <Col>
              <CustomButton
                title="Next"
                value="SIGN UP"
                type="submit"
                styling="primary"
              />
            </Col>
          </Row>
        </>
      )}
      {secondStep && (
        <>
          <Row className="form-row">
            <Col>
              <TextInput
                htmlFor="instagramUser"
                id="instagramUser"
                name="instagramUser"
                labelTitle="Instagram Username"
                value={newSignUpData.instagramUser || ""}
                styling="standard-input"
                type="text"
                handleChange={handleFieldUpdate}
                feedbackText="Instagram username to credit"
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col>
              <Checkbox
                id="over-eighteen"
                name="overEighteen"
                checked={!!newSignUpData.overEighteen}
                handleChange={handleFieldUpdate}
                text="I am over the age of 18."
              />
            </Col>
          </Row>
          <Row className="form-row">
            <Col>
              <Checkbox
                id="termsAgreement"
                name="termsAgreement"
                checked={!!newSignUpData.termsAgreement}
                handleChange={handleFieldUpdate}
                text={(
                  <div>
                    I have reviewed and agreed to the
                    {" "}
                    <a href="https://doingthingsmedia.com/legal/agreement/" target="_blank" className="teal-link-text" rel="noreferrer">Licensing Agreement</a>
                    ,
                    {" "}
                    <a href="https://doingthingsmedia.com/legal/terms/" target="_blank" className="teal-link-text" rel="noreferrer">Terms of Service</a>
                    , and
                    {" "}
                    <a href="https://doingthingsmedia.com/legal/privacy/" target="_blank" className="teal-link-text" rel="noreferrer">Privacy Policy</a>
                    .*
                  </div>
                )}
              />
            </Col>
          </Row>
          <Row className="error-container">
            <Col>
              <p className="error">{localError || awsError}</p>
            </Col>
          </Row>
          <Row className="btn-container">
            <Col>
              <CustomButton
                styling="alt"
                title="Previous"
                value="Previous"
                type="button"
                handleClick={() => {
                  setSecondStep(false);
                  setLocalError("");
                }}
              />
              <CustomButton
                styling="primary"
                title="Confirm"
                value="SIGN UP"
                type="submit"
              />
            </Col>
          </Row>
        </>
      )}

      <Modal isOpen={showModal} toggle={toggleModal}>
        <ModalHeader className="forgot-password" toggle={toggleModal}>
          Confirmation Code
        </ModalHeader>
        <Form
          onSubmit={
            (event) => handleConfirmationCode(event, newSignUpData, confirmationCode)
          }
        >
          <ModalBody>
            <p>Input the confirmation code that was emailed to you below.</p>
            <Row className='form-row'>
              <Col>
                <TextInput
                  text="Confirmation Code"
                  id="confirmationCode"
                  name="confirmationCode"
                  labelTitle="Confirmation Code"
                  value={confirmationCode}
                  handleChange={handleFieldUpdate}
                  invalid={validate.confirmationCodeState === "has-danger"}
                  feedbackText="a 6 digit confirmation code"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="forgot-password-footer">
            <Input type="submit" value="Submit" className='btn primary' />
            {" "}
          </ModalFooter>
        </Form>
      </Modal>

    </Form>
  );
};

export default SignUp;
