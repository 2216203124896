import React from "react";
import ReactPlayer from "react-player";
import { Button } from "reactstrap";
import { stripVideoUUID } from "../../helpers/global";
import { useContext, useEffect, useState } from "react";
import MicroTable from "../MicroTable";

const generateMicroTableData = (clipData) => [
  {
    label: "Submission Information",
    data: [
      {
        key: "Shareable Link",
        value: "Click to copy link.",
        copyable: true,
        copyValue: `${window.location.origin}/media/${clipData.id}`,
        editable: false,
      },
    ],
    expandable: false,
  }
];

export default function CampaignSubActions({ submission, setActiveSub, setShowActions }) {

  const handleClose = () => {
    setActiveSub({});
    setShowActions(false);
  };

  const [microTableData, setMicroTableData] = useState(
    generateMicroTableData(submission)
  );

  useEffect(() => {
    if (submission.id) {
      setMicroTableData(generateMicroTableData(submission));
    }
  }, []);

  return (
    <>
      <div className="slide half">
        <Button
          className='slide-close'
          onClick={() => handleClose()}
        ></Button>
        <div className='slide-container'>
          <div className='slide-wrapper'>
            <div className='left-col'></div>
            <div className='right-col'>
              {submission.contentType !== "image" ? (
                <div className='player-wrapper'>
                  <ReactPlayer
                    width='100%'
                    height='100%'
                    url={stripVideoUUID(submission.attachment)}
                    controls={true}
                  />
                </div>
              ) : (
                <img src={submission.attachment} />
              )}
              {microTableData.map((item, i) => (
                <MicroTable
                  key={i}
                  label={item.label}
                  data={item.data}
                  expandable={item.expandable}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
