import React, { useState, useEffect, useReducer } from "react";
import { adminGroup } from "./helpers/constants";
import { authUser, signOutUser } from "./libs/awsLib";
import {
  determineNavigation,
  fetchBrandFromURL,
  loadStyles,
} from "./helpers/global";
import logger from "./helpers/logging";
import Header from "./components/navigation/Header";
import { tagList } from "./libs/request";
import { ToastContainer } from "react-toastify";
import { useHistory, withRouter } from "react-router-dom";
import { userFetch, userClear } from "./actions";
import * as UserReducer from "./reducers/User";
import Context from "./store/store";
import MobileDetect from "mobile-detect";
import Routes from "./Routes";

// Styles
import "./styles/main.scss";
import "react-toastify/dist/ReactToastify.css";

// Reducers
import { USER_UPDATE } from "./actions/types";
import { BulkDownloadProvider } from "./store/bulkDownloadsStore";
import { ExternalPartnerProvider } from "./store/ExternalPartnerStore";
import { AgreementViewerProvider } from "./store/agreementViewerStore";

// Define the App.
const App = () => {
  /** ***************************************************
  States
  *****************************************************/

  const [activeBrand, setActiveBrand] = useState(fetchBrandFromURL());
  const [clipFile, setClipFile] = useState(null);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [tags, setTags] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [activeNavigation, setActiveNavigation] = useState("sidebar");

  // Device Detection
  const md = new MobileDetect(window.navigator.userAgent);
  const [isMobile, setIsMobile] = useState(md.mobile());

  /** ***************************************************
  Authentication
  *****************************************************/

  // Function that fetches a cookie based on name.
  const getCookie = (name) => {
    const parts = `; ${document.cookie}`.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
    return false;
  };

  // Parse a JSON Web Token
  const parseJWT = (token) => {
    if (token && token.length) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    }
    return false;
  };

  // Set a user using a cookie.
  const setUserWithCookie = () => {
    let defaultUser = UserReducer.INITIAL_STATE;
    const userCookie = getCookie("userToken");
    if (userCookie) {
      const parsedCookieToken = parseJWT(userCookie);
      if (parsedCookieToken) {
        defaultUser = {
          groups: parsedCookieToken["cognito:groups"] || [],
          awsId: parsedCookieToken.sub,
          email: parsedCookieToken.email,
        };
      }
    }
    return useReducer(UserReducer.UserReducer, defaultUser);
  };
  const [stateUser, dispatchUser] = setUserWithCookie();

  // Set a User
  const setUser = (userGroups, sub, email) => {
    const user = {
      awsId: sub,
      groups: userGroups,
      email,
      isAdmin: userGroups.includes("admin"),
      isAuthenticated: true,
    };
    dispatchUser({
      type: USER_UPDATE,
      payload: user,
    });
    userFetch(sub, dispatchUser);
  };

  // Logout User
  const handleLogout = (event) => {
    event.preventDefault();
    signOutUser();
    dispatchUser(userClear());
    document.cookie = "userToken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    history.push("/");
  };

  // Authenticate User
  const authenticateUser = (JWT) => {
    let authTimeout = false;

    // Load the local token.
    const parsedToken = parseJWT(JWT);
    if (parsedToken) {
      // Set the cookie.
      document.cookie = `userToken=${JWT};expires=${new Date(
        parsedToken.exp * 1000
      ).toUTCString()};SameSite=none;Secure;path=/;`;

      // Set the user.
      setUser(
        parsedToken["cognito:groups"] || [],
        parsedToken.sub,
        parsedToken.email
      );

      // If authentication has timed out.
      if (authTimeout) {
        window.clearTimeout(authTimeout);
        authTimeout = false;
      }

      // Reset authentication.
      window.setTimeout(() => {
        authTimeout = false;
        authUser().then((token) => authenticateUser(token));
      }, parsedToken.exp * 1000 - Date.now() + 2000);

      // Return the token.
      return parsedToken;
    }

    return false;
  };

  const loadTags = async () => {
    // Load tag list.
    await tagList().then((allTags) => {
      setTags(allTags);
    });
  };

  /** ***************************************************
  Actions
  *****************************************************/

  // The following useEffect will run whenever the URL changes
  const history = useHistory();
  useEffect(() => {
    return history.listen(() => {
      // Update the navigation.
      setActiveNavigation(determineNavigation());

      // Load styles.
      loadStyles();
    });
  }, [history]);

  // Run once when the app renders.
  useEffect(() => {
    // Update the navigation.
    setActiveNavigation(determineNavigation());

    // Load Styling
    loadStyles();

    // Authentication promise.
    authUser().then((token) => {
      authenticateUser(token);
    });

    loadTags();
  }, []);

  // Define properties (and functions) that can be accessed throughout the app.
  const globalProps = {
    authenticateUser,
    userFetch,
    setActiveBrand,
  };

  /** ***************************************************
  Render
  *****************************************************/
  return (
    <Context.Provider
      value={{
        activeBrand,
        setActiveBrand,
        clipFile,
        dispatchUser,
        handleLogout,
        isAdmin,
        isAuthenticated,
        isMobile,
        menuExpanded,
        recaptcha,
        setClipFile,
        setMenuExpanded,
        setRecaptcha,
        stateUser,
        tags,
      }}
    >
      {/* Toasts */}
      <ToastContainer autoClose={5000} theme="dark" />

      {/* Content */}
      <main className={`nav-${activeNavigation}`}>
        {/* Navigation */}
        <Header type={activeNavigation}></Header>

        {/* Content */}
        <section id="content">
          <BulkDownloadProvider>
            <ExternalPartnerProvider>
              <AgreementViewerProvider>
                <Routes globalProps={globalProps} stateUser={stateUser} />
              </AgreementViewerProvider>
            </ExternalPartnerProvider>
          </BulkDownloadProvider>
        </section>
      </main>
    </Context.Provider>
  );
};

export default withRouter(App);
