import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import register from "./registerServiceWorker";

Sentry.init({
  dsn: "https://3ae7c5acaf5341ba8a2974a046f7efc7@o131435.ingest.sentry.io/6326137",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.25,
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

register();
