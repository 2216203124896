import { Button, Input } from "reactstrap";
import {
  createCampaign,
  getAllCampaigns,
  removeCampign,
} from "../../libs/request/mRssFeed";
import { fas } from "fontawesome.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Context from "../../store/store";
import DataTable from "../../screens/DataTable";
import ListActions from "./ListActions";
import Loader from "../Loader";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";

export default function CampaignList() {
  const { stateUser } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [showActions, setShowActions] = useState(false);
  const [campaignData, setCampaignData] = useState();
  const [showCampaigns, setShowCampaigns] = useState(true);
  const [activeSubmission, setActiveSubmission] = useState();
  const [activeCampaign, setActiveCampaign] = useState();
  const [newCampaignName, setNewCampaignName] = useState();
  const [searching, setSearching] = useState(true);
  const history = useHistory();

  const loadData = () => {
    getAllCampaigns()
      .then((res) => {
        setCampaignData(res);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleCampaignRemoval = async (campaignId) => {
    await removeCampign(campaignId)
      .then((res) => {
        loadData();
        toast.success("Deleted Campaign");
      })
      .catch((error) => {
        toast.error("Error deleting campaign.");
      });
  };

  const handleViewCampaign = (campaignId) => {
    history.push(`/admin/mrss/campaign/view/${campaignId}`);
  };

  const handleViewXML = (campaignId) => {
    if (window.location.hostname == "localhost") {
      window.alert(
        "XML won't render on localhost. Should work fine on dev and production."
      );
    } else {
      window.open(
        `${window.location.origin}/api/lightning/xml/${campaignId}`,
        "_blank"
      );
    }
  };

  const createNewCampaign = async () => {
    if (!newCampaignName) {
      toast.error("Please enter a campaign name!");
      return;
    }
    await createCampaign(newCampaignName)
      .then((res) => {
        toast.success("New campaign created.");
        loadData();
        setSearching(false);
      })
      .catch((error) => {
        toast.error("Something went wrong.");
        setSearching(false);
      });
  };

  const renderCampaignActions = (campaign) => (
    <div className='actions'>
      <Button onClick={() => handleViewXML(campaign.id)}>
        <FontAwesomeIcon icon={fas("rss")} />
      </Button>
      <Button onClick={() => handleViewCampaign(campaign.id)}>
        <FontAwesomeIcon icon={fas("edit")} />
      </Button>
      {!stateUser.groups.includes("externalPartners") && (
        <Button
          className='danger'
          onClick={() => handleCampaignRemoval(campaign.id)}
        >
          <FontAwesomeIcon icon={fas("trash")} />
        </Button>
      )}
    </div>
  );

  const campaignTableData = useMemo(() => campaignData, [campaignData]);
  const campaignColumns = useMemo(
    () => [
      {
        Header: "Campaign",
        accessor: "campaign",
        Cell: (props) => <div className="flex left">{props.row.original.campaignName}</div>
      },
      {
        Header: "Created",
        accessor: "dateCreated",
        Cell: (props) => {
          return <div className="flex left">{moment(props.row.original.dateCreated).format("M/D/YY")}</div>
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        className: "align-right",
        Cell: (props) =>
          props ? renderCampaignActions(props.row.original) : null,
      },
    ],
    []
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className='mrss list'>
          <div className='header'>
            <div className='header-col'>
              <h1>MRSS Feeds</h1>
              <span>{campaignTableData.length} Feeds</span>
            </div>
            <div className='header-col inline-form'>
              {showCampaigns && (
                <>
                  <Input
                    placeholder='Enter a campaign name here...'
                    onChange={(e) => setNewCampaignName(e.currentTarget.value)}
                  />
                  <Button onClick={createNewCampaign}>
                    Create Campaign
                  </Button>
                </>
              )}
            </div>
          </div>
          {showCampaigns && (
            <DataTable columns={campaignColumns} data={campaignTableData} />
          )}
          {showActions && (
            <ListActions
              submission={activeSubmission}
              campaignData={campaignData}
              activeCampaign={activeCampaign}
              loadData={loadData}
              setActiveCampaign={setActiveCampaign}
              setShowActions={setShowActions}
              setActiveSubmission={setActiveSubmission}
              mediaViewerClip={activeSubmission}
            />
          )}
        </div>
      )}
    </>
  );
}
