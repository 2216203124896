import React from 'react'
import { Row } from 'reactstrap'
import { CustomButton } from './form'

const ThankYouSubmission = ({ resetForm }) => {
  return (
    <>
      <div className="thank-you">
        <div className="thank-you-header">
          <h2>Congrats!</h2>
          <p className="thank-you-text">
            Your video is now on the way to being reviewed! Once it's approved you will receive an email from our team. 
          </p>
        </div>
        <Row className="btn-row">
          <CustomButton
            styling="primary"
            title="Submit Another Video"
            handleClick={ resetForm || null }
          />
        </Row>
      </div>
    </>
  )

}

export default ThankYouSubmission