import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

export default function EditUserDropdown({ setDropdownToggle, dropdownToggle, label, availableGroups, handleSelect, handleClick, handleClearGroupChanges, action, confirmationMessage }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Dropdown toggle={() => setDropdownToggle(!dropdownToggle)} isOpen={dropdownToggle}>
        <DropdownToggle>{label}</DropdownToggle>
        <DropdownMenu>
          {availableGroups.map((group, i) => (
            <DropdownItem key={i} onClick={() => handleSelect(group)}>
              {group}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {confirmationMessage && <span style={{ color: 'red' }}>{confirmationMessage}</span>}
      <div style={{ display: 'flex', gap: '1.5rem' }}>
        <Button className="btn" onClick={() => handleClick()}>Save</Button>
        <Button className="btn" onClick={() => handleClearGroupChanges(action)}>Clear</Button>
      </div>
    </div>
  )
}