import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "reactstrap";
import { Loader } from "../components";
import { fetchBrandFromSlugname } from "../helpers/global";
import { platformMetrics } from "../libs/request";

const AdminStatsScreen = () => {
  const [platformMetricData, setPlatformMetricData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    platformMetrics().then((response) => {
      setPlatformMetricData({ ...response });
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Row className="dashboard-screen admin-stats">
      <Col>
        <Row>
          {/* Left Column */}
          <Col>
            {/* Brand Breakdown */}
            <Table>
              <thead>
                <tr>
                  <th>Brand</th>
                  <th>Number</th>
                </tr>
              </thead>
              <tbody>
                {platformMetricData.aggregatorSubmissions.map(
                  (stat) =>
                    stat.aggregator != "" &&
                    stat.aggregator != "[object Object]" && (
                      <tr key={stat.aggregator}>
                        <td>{fetchBrandFromSlugname(stat.aggregator).name}</td>
                        <td>{stat.count}</td>
                      </tr>
                    )
                )}
              </tbody>
            </Table>
          </Col>

          <Col>
            {/* Top Submitters */}
            <Table>
              <thead>
                <tr>
                  <th>Top User Submittors</th>
                </tr>
              </thead>
              <tbody>
                {platformMetricData.userSubmissions.topTenUserCredit.map(
                  (user) => (
                    <tr key={`${user.userCredit}-${user.count}`}>
                      <td>{user.userCredit}</td>
                      <td>{user.count}</td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>Videos Submitted by Signed In Users</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{platformMetricData.userSubmissions.signedInUsers}</td>
                </tr>
              </tbody>
              {platformMetricData !== undefined && (
                <>
                  <thead>
                    <tr>
                      <th>Total Submissions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {
                          platformMetricData.reviewedSubmissionsMetrics
                            .totalSubmissionsCount
                        }
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th>Total Reviewed Submissions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {
                          platformMetricData.reviewedSubmissionsMetrics
                            .reviewedSubmissions
                        }
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th>Not Reviewed Submissions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {platformMetricData.reviewedSubmissionsMetrics
                          .totalSubmissionCount -
                          platformMetricData.reviewedSubmissionsMetrics
                            .reviewedSubmissions}
                      </td>
                    </tr>
                  </tbody>
                </>
              )}
            </Table>

            <Table>
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>24 Hours</td>
                  <td>{platformMetricData.datedSubmissions.last24Hours}</td>
                </tr>
                <tr>
                  <td>Month</td>
                  <td>{platformMetricData.datedSubmissions.lastMonth}</td>
                </tr>
                <tr>
                  <td>Week</td>
                  <td>{platformMetricData.datedSubmissions.lastWeek}</td>
                </tr>
                <tr>
                  <td>Year</td>
                  <td>{platformMetricData.datedSubmissions.currentYear}</td>
                </tr>
                <tr>
                  <td>Viral</td>
                  <td>{platformMetricData.markedSubmissions.isViral}</td>
                </tr>
                <tr>
                  <td>Approved</td>
                  <td>{platformMetricData.markedSubmissions.approved}</td>
                </tr>
                <tr>
                  <td>Vimeo</td>
                  <td>{platformMetricData.markedSubmissions.vimeo}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AdminStatsScreen;
