import React, { useState, useEffect } from "react";
import { Col, Input, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PaginationBar } from "../components";
import { AggregatorCheckbox, SortByDate } from "../components/form";
import FeaturedGrid from "../components/licensing/FeaturedGrid";
import { DEFAULT_SEARCH_OBJECT, LICECERATINGS } from "../helpers/constants";
import {
  changePage,
  clearCheckboxes,
  handleCheckChangeSearch,
  handleSearchChange,
  handleSort,
  newClipSearch,
  removeSearchTerm,
  selectAllCheckboxes,
  useDebounce,
} from "../libs/utils";
import ReactPlayer from "react-player";
import { Loader } from "../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "fontawesome.macro";
import { stripVideoUUID } from "../helpers/global";

const LicenseIndex = (props) => {
  let urlSearchObject = {};
  let defaultSearchString = "";
  try {
    urlSearchObject = {
      selectedAggregators: [],
      selectedCategories: [],
      selectedRatings: [],
      ...JSON.parse(decodeURIComponent(window.location.hash.replace(/^#/, ""))),
    };
    urlSearchObject = {
      ...urlSearchObject,
      selectedAggregators: DEFAULT_SEARCH_OBJECT.selectedAggregators.map(
        (o) => ({
          ...o,
          selected: urlSearchObject.selectedAggregators.includes(o.val),
        })
      ),
      selectedCategories: DEFAULT_SEARCH_OBJECT.selectedCategories.map((o) => ({
        ...o,
        selected: urlSearchObject.selectedCategories.includes(o.val),
      })),
      selectedRatings: LICECERATINGS.map((o) => ({
        ...o,
        selected: urlSearchObject.selectedRatings.includes(o.val),
      })),
    };
    defaultSearchString = urlSearchObject.searchTerms
      ? urlSearchObject.searchTerms.join(" ")
      : "";
  } catch (e) {
    urlSearchObject = {};
  }
  const [clips, setClips] = useState([]);
  const [clipCount, setClipCount] = useState(clips.length);
  const [isLoading, setIsLoading] = useState(false);
  const [searchString, setSearchString] = useState(defaultSearchString);
  const [searchObject, setSearchObject] = useState({
    ...DEFAULT_SEARCH_OBJECT,
    selectedRatings: LICECERATINGS,
    showFeaturedClips: true,
    licensing: true,
    ...urlSearchObject,
  });

  const debouncedSearchObject = useDebounce(searchObject, 500);

  useEffect(() => {
    handleSearchChangeLicense(searchString);
  }, [searchString]);

  useEffect(() => {
    if (typeof searchObject.offset === "number") {
      newClipSearch(
        searchObject,
        setClipCount,
        setClips,
        setIsLoading,
        "licenseVideos"
      );
    }
  }, [searchObject]);

  useEffect(() => {
    if (searchObject.showFeaturedClips) {
      return;
    }
    const historySearchObject = {
      offset: searchObject.offset,
      orderBy: searchObject.orderBy,
      searchTerms: searchObject.searchTerms,
      selectedAggregators: searchObject.selectedAggregators
        .filter((o) => o.selected)
        .map((o) => o.val),
      selectedCategories: searchObject.selectedCategories
        .filter((o) => o.selected)
        .map((o) => o.val),
      selectedRatings: searchObject.selectedRatings
        .filter((o) => o.selected)
        .map((o) => o.val),
      showFeaturedClips: searchObject.showFeaturedClips,
      selectedTags: searchObject.selectedTags,
    };
    if (!historySearchObject.offset) {
      delete historySearchObject.offset;
    }
    if (!historySearchObject.searchTerms.length) {
      delete historySearchObject.searchTerms;
    }
    if (!historySearchObject.selectedTags.length) {
      delete historySearchObject.selectedTags;
    }
    if (!historySearchObject.selectedAggregators.length) {
      delete historySearchObject.selectedAggregators;
    }
    if (!historySearchObject.selectedCategories.length) {
      delete historySearchObject.selectedCategories;
    }
    if (!historySearchObject.selectedRatings.length) {
      delete historySearchObject.selectedRatings;
    }
    if (historySearchObject.orderBy.direction === "DESC") {
      delete historySearchObject.orderBy;
    }
    props.history.replace(
      `#${encodeURIComponent(JSON.stringify(historySearchObject))}`
    );
  }, [searchObject]);

  const changePageLicense = (page) => {
    changePage(page, searchObject, setSearchObject);
  };

  const handleCheckChangeLicense = (index, array) => {
    handleCheckChangeSearch(index, array, searchObject, setSearchObject);
  };

  const clearCheckboxesLicense = (array) => {
    clearCheckboxes(array, searchObject, setSearchObject);
  };

  const selectAllCheckboxesLicense = (array) => {
    selectAllCheckboxes(array, searchObject, setSearchObject);
  };

  const handleAddSearchTerm = (event) => {
    if (event.key === "Enter") {
      // Parse out URL-related strings if someone pastes in an entire URL.
      let value = event.target.value;
      value = value.replace(window.location.origin, "");
      value = value.replace("/license", "");

      setSearchString(searchString + ` ${value}`);
      event.target.value = "";
    }
  };

  const handleSearchChangeLicense = (string) => {
    handleSearchChange(
      string,
      searchObject,
      setSearchObject,
      setSearchString,
      "all"
    );
  };

  const handleSortLicense = (field, direction) => {
    handleSort(field, direction, searchObject, setSearchObject);
  };

  const removeSearchTermLicense = (index) => {
    removeSearchTerm(index, searchObject, setSearchObject, setSearchString);
  };

  const offsetNumber = !Number.isNaN(parseInt(searchObject.offset, 10))
    ? searchObject.offset + 1
    : "";

  return (
    <Container className="license-screen" fluid>
      <Helmet>
        <title>License Media | Doing Things Media</title>
        <meta
          name="description"
          content="Browse our selection of trending media."
        />
      </Helmet>
      <Row>
        <Col>
          <h1>License Videos</h1>
          <div className="options">
            <div className="search-wrapper">
              <div id="search" className="input-group">
                <Input
                  className="searchInput"
                  id="searchInput"
                  name="searchText"
                  onKeyDown={handleAddSearchTerm}
                  placeholder="&#x1F50D;&nbsp; Search for a video or keyword"
                  type="text"
                />
              </div>
              {searchObject.searchTerms.length ? (
                <div className="search-terms">
                  <div className="search-terms__container">
                    {searchObject.searchTerms.map((term, index) => (
                      <div className="search-terms__term" key={index}>
                        <span className="search-terms__text">{term}</span>
                        <span
                          className="search-terms__clear"
                          onClick={() => removeSearchTermLicense(index)}
                          role="button"
                        >
                          <FontAwesomeIcon
                            icon={fas("times")}
                            color="#000000"
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
            <div id="filter" className="input-group">
              <AggregatorCheckbox
                clearCheckboxes={clearCheckboxesLicense}
                handleCheckChange={handleCheckChangeLicense}
                selectAllCheckboxes={selectAllCheckboxesLicense}
                selectedAggregators={searchObject.selectedAggregators}
              />
              <SortByDate
                handleSort={handleSortLicense}
                orderBy={searchObject.orderBy}
              />
            </div>
          </div>
          {isLoading ? (
            <Loader styling="contained" />
          ) : clips.length ? (
            <Container className="video-search-container">
              {searchObject.showFeaturedClips ? (
                <FeaturedGrid clip={clips} />
              ) : (
                <div className="video-card-grid">
                  {clips.map((clip) => {
                    const { id, source, thumbnail } = clip;
                    return (
                      <div key={id} className="video-card-item">
                        {/* Do not render the thumbnail. Rather save it into the clip.source
                          attribute and render the whole card if and only if source is set */}

                        {(thumbnail !== "" || source) && (
                          <Link
                            to={{
                              pathname: `/license/${clip.id}`,
                              state: {
                                clip: clip,
                              },
                            }}
                          >
                            {!!clip.attachment && (
                              <ReactPlayer
                                key={clip.id}
                                url={stripVideoUUID(clip.attachment)}
                                light={clip.thumbnail}
                                controls={true}
                                width="100%"
                              />
                            )}
                          </Link>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </Container>
          ) : (
            <Container>
              <h2>No clips found.</h2>
            </Container>
          )}
          {clipCount > 8 ? (
            <PaginationBar
              changePage={changePageLicense}
              clipCount={clipCount}
              length={clipCount}
              offset={searchObject.offset}
              offsetNumber={offsetNumber}
              parent={this}
              page="license"
            />
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default LicenseIndex;
