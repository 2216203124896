import { ExternalPartnerContext } from "../../store/ExternalPartnerStore";
import { getLibrary } from "../../libs/request/partnerLibrary";
import { useContext, useEffect, useState } from "react";
import FeedTable from "../../screens/components/FeedTable";
import Loader from "../Loader";

export default function PartnerLibrarySingle() {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState();
  const { externalPartnerState, externalPartnerDispatch } = useContext(
    ExternalPartnerContext
  );

  const loadData = async () => {
    const userId = location.pathname.split("/").pop();
    await getLibrary(userId)
      .then((response) => {
        const tempUserData = {
          user: {
            id: response.id,
            email: response.email,
          },
          submissions: response.submissions,
        };
        setUserData({ ...tempUserData });
        if (
          externalPartnerState.id === "" &&
          response.user &&
          response.user.email
        ) {
          externalPartnerDispatch({
            type: "ADD",
            payload: { id: userId, email: response.user.email },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(async () => {
    await loadData();
  }, []);

  useEffect(() => {
    setUserData({ ...userData, user: { ...externalPartnerState } });
  }, [externalPartnerState]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : userData.submissions ? (
        <div className="partner-dashboard single">
          <FeedTable
            tableData={userData.submissions}
            memoData={userData.submissions}
            tags={userData.tags}
            labelName={userData.user.email}
            labelFeed={"Partner Library"}
            itemCount={userData.submissions.length}
            loadData={loadData}
            externalPartnerId={userData.user.id}
          />
        </div>
      ) : (
        <p>No submissions found.</p>
      )}
    </>
  );
}
