import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from 'fontawesome.macro'
import React, { useState } from 'react'
import {
  FormGroup, Input, Label, Dropdown, DropdownMenu,
  DropdownItem, DropdownToggle,
} from 'reactstrap'

const RatingCheckbox = ({
  selectedRatings, handleCheckChange, clearCheckboxes, selectAllCheckboxes,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => { setDropdownOpen(!dropdownOpen) }
  return (
    <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle nav caret>
        Ratings
      </DropdownToggle>
      <DropdownMenu>
        {selectedRatings.map((rating, index) => (
          <DropdownItem className="custom-dropdown-item" key={rating.name} onClick={() => handleCheckChange(index, 'selectedRatings')}>
            <FormGroup check>
              <Label check>
                <span className={`checkbox ${rating.selected ? 'checked' : ''}`} />
                <Input
                  type="checkbox"
                  key={rating.name}
                  checked={rating.selected}
                  value={rating.val}
                  readOnly
                />
                {rating.icon && <FontAwesomeIcon fill='#ff0000' icon={rating.icon} />} {rating.name}
              </Label>
            </FormGroup>
          </DropdownItem>
        ))}
        <DropdownItem divider />
        <DropdownItem onClick={() => clearCheckboxes('selectedRatings')}>Clear</DropdownItem>
        <DropdownItem onClick={() => selectAllCheckboxes('selectedRatings')}>Select All</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default RatingCheckbox
