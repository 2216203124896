import Context from "../../store/store";
import { getSingleCampaign } from "../../libs/request/mRssFeed";
import Loader from "../Loader";
import React, { useContext, useEffect, useState } from "react";
import FeedTable from "../../screens/components/FeedTable";

export default function CampaignView() {
  const { stateUser } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [campaignData, setCampaignData] = useState();

  const loadCampaignData = async () => {
    const campaignId = window.location.href.split("/").pop();
    await getSingleCampaign(campaignId)
      .then((res) => {
        let totalDuration = 0;
        res.submissions.forEach(
          (submission) => (totalDuration += parseInt(submission.duration))
        );
        const campaignObj = {
          ...res,
          totalDuration: totalDuration,
        };
        setCampaignData({ ...campaignObj });
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadCampaignData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div id="campaign" className="mrss">
            <FeedTable
              tableData={campaignData.submissions}
              memoData={campaignData}
              labelName={campaignData.campaignName}
              labelFeed={"MRSS FEED"}
              totalDuration={campaignData.totalDuration}
              itemCount={campaignData.submissions.length}
              loadData={loadCampaignData}
              campaignId={campaignData.id}
              showActionsColumn={!stateUser.groups.includes("externalPartners")}
            />
          </div>
        </>
      )}
    </>
  );
}
