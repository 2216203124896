import React from 'react'
import { Container } from 'reactstrap'
import { Helmet } from 'react-helmet'

const RevshareAgreement = () => (
  <div>
    <Helmet>
      <title>Revenue Sharing Aggreement | Doing Things Media</title>
      <meta
        name="description"
        content="Welcome to Doing Things!  A community of like-minded individuals, who have voluntarily decided to participate in a forum to share content for potential use by Steve Media, LLC"
      />
    </Helmet>
    <Container className="padding-top-2">
      <p>
        Welcome to Doing Things!  A community of like-minded individuals, who
        have voluntarily decided to participate in a forum to share content for
        potential use by Steve Media, LLC (
        <strong>“Company”</strong>
        ) for all
        things Doing Things. “Company” shall apply equally to Steve Media, LLC;
        Reid Hailey; Derek Lucas; and their manager, attorneys, and any and all
        other representatives. This Intellectual Property Purchase Agreement (the
        <strong>“Agreement”</strong>
        ) is made as of the date of signature by and
        between Company and you (the
        <strong>“Assignor”</strong>
        ). The Company and
        Assignor are hereinafter referred to collectively as the “Parties,” and
        each individually as a “Party.”
      </p>
      <p>
        <strong>WHEREAS</strong>
        , the Assignor voluntarily uploaded or desires
        to upload motion picture content including, without limitation, video,
        animation, and other images (collectively, the
        <strong>“Content”</strong>
        )
        to the Company website and/or mobile application (collectively, the
        <strong>“Site”</strong>
        ) and is provided the opportunity to have such
        Content featured on Company’s YouTube channel, social media channels and
        in any other media or medium (collectively, the
        <strong>“Channels”</strong>
        {' '}
        ) all of which inured to the Assignor’s
        benefit.
      </p>
      <p>
        <strong>NOW, THEREFORE</strong>
        {' '}
        , in consideration of the mutual
        promises and undertakings herein contained and for other good and valuable
        consideration, the receipt and sufficiency of which is hereby
        acknowledged, and intending to be legally bound, the Parties agree as
        follows:
      </p>
      <ol>
        <li>
          The foregoing recitals are confirmed by the Parties as true and
          correct, deemed a substantive part of this Agreement, and incorporated
          herein by reference.
        </li>
        <li>
          <strong>Site Terms of Service.</strong>
          <ol type="a">
            <li>
              Assignor understands and acknowledges that the Company has
              invested, and continues to invest, substantial time, money, and
              specialized knowledge into developing its Site, the brand, content,
              resources and technology; creating contacts with individuals in the
              Company’s industry; generating relationships with brands, partners,
              and affiliates and training its employees.  Assignor understands and
              acknowledges that as a result of these efforts, the Company has
              created, and continues to use and create, Confidential Information
              as defined herein below. This Confidential Information provides the
              Company with a competitive advantage over others in the
              marketplace.
            </li>
            <li>
              Assignor understands and acknowledges that the Company is
              creating content that may be featured on the Channels, off the
              Channels and for other commercial purposes (collectively, the
              <strong>“Material”</strong>
              ).
            </li>
          </ol>
        </li>

        <li>
          <strong>Assignment</strong>
          . Assignor hereby irrevocably and in
          perpetuity conveys, transfers, and assigns to Company, and Company
          hereby accepts, all worldwide right, title, and interest in and to all
          rights, including but not limited to, all copyright and intellectual
          property rights (collectively, the “IP Rights”) in and to the Content,
          subject to the following:
          <ol type="a">
            <li>
              <strong>Scope</strong>
              .

              <ol type="i">
                <li>
                  This assignment includes all rights to apply, reproduce,
                  prepare derivative works, distribute copies, perform, display,
                  and license such Content in any and all media, now known and
                  hereafter devised. and to use the Content in and in connection
                  with the development, production, distribution and exploitation
                  of the Material, and in the advertisements, merchandising,
                  publicity, and promotions for the Material. The rights granted
                  herein shall also include the right to revise and fictionalize
                  the Content as Company sees fit in Company’s sole
                  discretion.
                </li>
                <li>
                  Assignor hereby irrevocably waives the exercise of any
                  “moral rights,” “droit moral,” and any analogous rights, however
                  denominated, in favor of the Company, in any jurisdiction of the
                  world, which Assignors has. The rights assigned to Company
                  include any so-called “rental and lending” or similar rights and
                  any and all allied, ancillary and subsidiary rights for any
                  purpose, as part of the Content or otherwise.
                </li>
                <li>
                  At Company’s request, Assignor shall execute and deliver to
                  Company such instruments consistent herewith and consistent with
                  the Agreement as Company may reasonably deem necessary to
                  establish, protect, enforce and/or defend any or all of
                  Company’s rights in the Content and/or under this Agreement, and
                  if Assignor fails to do so within five (5) business days after
                  Company’s written request, Company shall have the right to
                  execute any and all such documents and do any and all such other
                  acts consistent herewith in Assignor’s name, and Company is
                  hereby irrevocably appointed as Assignor’s attorney-in-fact for
                  such purposes, which power is coupled with an interest, with
                  full power of substitution and delegation.
                </li>
              </ol>
            </li>
            <li>
              <strong>Compensation</strong>
              . In consideration of such
              assignment whereby Company shall be the sole and exclusive owner of
              the Content and Material, Company agrees to pay 60% of Net Revenue
              earned in connection with Company’s exploitation of the Content.
              “Net Revenue” shall mean the total revenue received less Company’s
              expenses, which include without limitation: all costs and expenses
              relating to any edits, packaging, distribution, and exploitation of
              the Content, Materials and related advertisements. For clarity,
              Parties understand and agree that not all uses of the Content, or
              variations thereof, will result in monetary compensation, including
              uses of the Content by the Company in its ordinary course of
              business.
            </li>
          </ol>
        </li>
        <li>
          Notwithstanding the foregoing, Company has no obligation to Assignor
          to use the Content in any manner.
        </li>
        <li>
          <strong>Payments and Reporting</strong>
          . Unless otherwise noted
          herein or later agreed, not later than the thirtieth (30th) day after
          the close of every calendar quarter during which time there is any use
          and monetization of such Content pursuant to this Agreement, Company
          shall furnish to Assignor in a form and format Company deems acceptable,
          a statement outlining such compensation details and payments.
        </li>
        <li>
          Assignor agrees to keep in strictest confidence and shall not
          disclose to any third party at any time any ideas, projects,
          experiments, information or materials of any kind that he/she reads,
          hears or otherwise acquires or learns in connection with or as a result
          of his/her uploading Content that is maintained in confidence by the
          Company or the outside party who provided the information (collectively,
          the “
          <strong>Confidential Information</strong>
          ”). Confidential
          Information shall include the Content itself and associated IP Rights as
          well as the existence of this signed Agreement unless disclosure is
          required by law. Assignor further understands and acknowledges that this
          Confidential Information and the Company’s ability to reserve it for the
          exclusive knowledge and use of the Company is of great competitive
          importance and commercial value to the Company, and that improper use or
          disclosure of the Confidential Information by the Assignor might cause
          the Company to incur financial costs, loss of business advantage,
          liability under confidentiality agreements with third parties, civil
          damages and criminal penalties.
        </li>
        <li>
          Assignor acknowledges and agrees that the Confidential Information
          is confidential and the exclusive property of the Company.  Assignor
          agrees that he/she will not, directly or indirectly, divulge,
          communicate, take for his/her own purpose or the purpose of others, copy
          or disclose, or permit others to use, copy or disclose, any Confidential
          Information to any person. Assignor acknowledges that with respect to
          confidentiality as set forth in this Agreement his/her obligations shall
          continue in perpetuity or until terminated by the Company in
          writing.
        </li>
        <li>
          Assignor shall not directly or indirectly participate in any
          interviews or write, prepare, assist in the preparation of, or be a
          source for, any books, memoirs, articles, programs, blogs, vlogs,
          motion picture productions or stories of any kind whatsoever
          concerning the Company, Content or Material.  In the event of any
          breach of this provision by Assignor, copyright in any such
          unauthorized material shall be deemed automatically and immediately
          transferred to Company as of its creation, and this Agreement shall
          constitute a valid transfer of copyright.
        </li>
        <li>
          Assignor acknowledges that any breach by him/her of any of the
          confidentiality provisions of this Agreement would cause Company
          irreparable injury and damage that cannot be reasonably or adequately
          compensated by damages in an action at law.   Assignor hereby
          expressly agrees that Company shall be entitled to injunctive and
          other equitable relief (without posting any bond) to prevent or cure
          any breach or threatened breach of the confidentiality provisions of
          this Agreement by Assignor.
        </li>
        <li>
          Neither the Assignor nor anyone acting on the Assignor’s behalf
          shall at any time use any of Company’s names, logos, trade names or
          trademarks (including, but not limited to, the title of the Content) for
          any purpose or in any manner whatsoever.
        </li>
        <li>
          Assignor represents and warrants to Company that Assignor: (a)  is
          free and clear to enter into this Agreement and that Assignor’s
          performance thereunder will not conflict with any other transaction or
          agreement ot which Assignor may be a party; exclusively owns all
          right, title, and interest in and to the Content; (b) has not granted
          and will not grant any licenses or other rights to the Content, in
          whole or in part, to any third party; (c) the Content is free of any
          liens, encumbrances, security interests, and restrictions on transfer;
          (d) there are no legal actions, investigations, claims, or proceedings
          pending or threatened relating to the Content; (e) his/her appearance,
          or the appearance of others in the Content is not a performance and is
          not employment and is not subject to any union or guild collective
          bargaining agreements, and does not entitle him/her to wages, salary,
          corporate benefits, unemployment or worker’s compensation benefits, or
          other compensation under any such collective bargaining agreement or
          otherwise; and (f) he/she will follow and obey all local, city, state
          and federal laws in connection with his/her participation, filming,
          creation and/or distribution of the Content. Furthermore, Assignor
          will defend, indemnify and hold harmless Company and its parent,
          subsidiary and affiliated companies, and each of their respective
          officers, directors, agents, representatives and employees, from and
          against any and all claims, actions, damages, liabilities, losses,
          costs and expenses (including, without limitation, attorneys’ fees)
          that in any way arise out of or result from Assignor’s breach of any
          representation, warranty or term of this Agreement.
        </li>
        <li>
          Assignor understands and acknowledges that he/she will not be entitled
          to or paid any additional compensation beyond the relevant structure
          listed above in Paragraph 3(b), and that such compensation is full and
          final monetary consideration paid for providing Content whether or not
          used in the Material, for appearing in the Content and/or Material,
          for giving the Company the rights listed in this Agreement, or for
          Company’s exercise of any and all of the rights listed in this
          Agreement.  Assignor acknowledges and agrees that significant elements
          of the consideration Assignor is receiving under this Agreement is the
          opportunity to share ideas and the opportunity to potentially
          contribute Content to be featured in the Material and/or on the Site.
          Assignor acknowledges that Company will incur significant costs and
          expenses in reliance upon this Agreement, so Assignor will not attempt
          to challenge, cancel, or to revoke any of the rights granted to
          Company herein.
        </li>
        <li>
          This Agreement does not constitute and shall not be construed as
          constituting a partnership, agency, or joint venture between Parties.
          The Assignor shall have no right to obligate or bind Company in any
          manner whatsoever and nothing herein contained shall give or is
          intended to give any right of any kind to any third party. Assignor
          acknowledges that he/she is a volunteer and that he/or she is not
          deemed to be an employee of Company, nor shall he/she be entitled to
          the benefits provided by Company to its employees.  To the extent
          Assignor receives anything of value in connection with the Content,
          including but not limited to goods and services, Assignor shall
          disclose such fact to Company and shall be responsible for all taxes
          and other obligations that are or may become due from him/her.
        </li>
        <li>
          <strong>
            <u>Release and Agreement Not to Sue</u>
            .  To the maximum
            extent permitted by law, Assignor, for himself/herself and on behalf
            of his/her heirs, executors, agents, successors or assigns, hereby
            agrees not to sue Company and further release, hold harmless, and
            forever discharge Company from any and all claims, actions, damages,
            losses, liabilities, costs, expenses, injuries or causes of action
            whatsoever that in any way are caused by, arise out of or result from
            this Agreement, Assignor’s appearance in the Content and/or
            Material.
          </strong>
        </li>

        <li>
          Assignor acknowledges that there is a possibility that subsequent to
          the execution of this Agreement, Assignor may discover facts or incur or
          suffer claims which were unknown or unsuspected at the time this
          Agreement was executed, and which if known by Assignor at that time may
          have materially affected Assignor’s decision to execute this Agreement.
          Assignor acknowledges and agrees that by reason of this Agreement, and
          the releases contained in the preceding paragraphs, Assignor is assuming
          any risk of such unknown facts and such unknown and unsuspected claims.
        </li>
        <li>
          This Agreement shall be interpreted under the internal,
          substantive law of the State of California without regard to the
          conflicts of law provisions thereof and Assignor submits to in personam
          jurisdiction in the state and federal courts of Los Angeles County.
        </li>

        <li>
          Assignor agrees that Company may license, assign, and otherwise
          transfer this Agreement and all rights granted by Assignor to Company
          under this Agreement to any person or entity. Assignor agrees further
          that Assignor shall not license, assign or otherwise transfer this
          Agreement and Assignor’s obligations under this Agreement to any person
          or entity.
        </li>
        <li>
          This is the complete and binding agreement between Company and
          Assignor, shall be binding and inure to the benefit of the Assignor’s
          successors and assigns, and it supersedes all prior understandings and
          communications, both oral and written, with respect to its subject
          matter.  The illegality, invalidity or unenforceability of any provision
          of this Agreement shall in no way affect the validity or enforceability
          of any of the remainder of this Agreement, which shall be enforced to
          the maximum extent permitted by law. This Agreement cannot be
          terminated, rescinded or amended, except by a written agreement signed
          by both Company and Assignor.
        </li>
        <li>
          This Agreement may be executed in any number of counterparts,
          including facsimile counterparts, each of which shall be deemed to be an
          original but all of which together shall constitute one and the same
          Agreement. Signatures conveyed by facsimile transmission shall serve to
          bind the Parties to this Agreement.
        </li>
      </ol>

      <p>
        <strong>
          <u>
            ASSIGNOR UNDERSTANDS THAT HE/SHE IS GIVING UP CERTAIN LEGAL
            RIGHTS UNDER THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, HIS/HER
            RIGHT TO FILE A LAWSUIT IN COURT WITH RESPECT TO ANY CLAIM ARISING IN
            CONNECTION WITH THIS AGREEMENT.
          </u>
        </strong>
      </p>

      <p>
        IN WITNESS WHEREOF, this Agreement is entered into as of the date and
        year above written:
      </p>
      <table>
        <tr>
          <td>ASSIGNOR:</td>
          <td>COMPANY:</td>
        </tr>
        <tr>
          <td>By: _________________________________ </td>
          <td>By: _________________________________ </td>
        </tr>
        <tr>
          <td>Date: _______________________________ </td>
          <td>Date: _______________________________ </td>
        </tr>
      </table>
    </Container>
  </div>
)

export default RevshareAgreement
