import AWS from 'aws-sdk/global';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

const config = require('../config.json');
const userPool = new CognitoUserPool({
  UserPoolId: config.cognito.COGNITO_USER_POOL_ID,
  ClientId: config.cognito.APP_CLIENT_ID,
});

export function authUser() {
  return new Promise((resolve) => {
    if (
      AWS.config.credentials &&
      Date.now() < AWS.config.credentials.expireTime - 60000
    ) {
      resolve(
        AWS.config.credentials.params.Logins[
          `cognito-idp.${config.cognito.AWS_REGION}.amazonaws.com/${config.cognito.COGNITO_USER_POOL_ID}`
        ]
      );
    } else {
      const currentUser = getCurrentUser();
      if (currentUser === null) {
        resolve(false);
      } else {
        getUserToken(currentUser).then((userToken) => resolve(userToken));
      }
    }
  });
}

export function signOutUser() {
  const currentUser = getCurrentUser();
  if (currentUser !== null) {
    currentUser.signOut();
  }
  if (AWS.config.credentials) {
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({});
  }
}

export function signUpUser(email, password) {
  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, null, null, (err, result) => {
      if (err) reject(err);
      else resolve(result);
    });
  });
}

export function resendConfirmationCode(email) {
  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) =>
    cognitoUser.resendConfirmationCode((err) => {
      if (err) {
        reject(error.message || JSON.stringify(err));
      } else {
        resolve();
      }
    })
  );
}

export function submitConfirmationCode(email, password, confirmationCode) {
  const userData = { Username: email, Pool: userPool };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) =>
    cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        reject(err);
      } else {
        loginUser(email, password)
          .then((result) => resolve(result))
          .catch((err2) => reject(err2));
      }
    })
  );
}

function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }
      const sessionToken = session.getIdToken().getJwtToken();
      resolve(sessionToken);
    });
  });
}

function getCurrentUser() {
  return userPool.getCurrentUser();
}

export function getCurrentUserToken() {
  const user = userPool.getCurrentUser();
  return getUserToken(user);
}

export function loginUser(email, password) {
  const user = new CognitoUser({ Username: email, Pool: userPool });
  const authenticationData = { Username: email, Password: password };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  return new Promise((resolve, reject) =>
    user.authenticateUser(authenticationDetails, {
      onSuccess: (result) => resolve(result),
      onFailure: (err) => reject(err),
    })
  );
}

export function forgotPassword(email, showVerificationModal) {
  const user = new CognitoUser({ Username: email, Pool: userPool });
  return new Promise((resolve, reject) =>
    user.forgotPassword({
      onSuccess: (result) => resolve(result),
      onFailure: (err) => reject(err),
      inputVerificationCode() {
        showVerificationModal();
      },
    })
  );
}

export function confirmResetPassword(email, newPassword, confirmationCode) {
  const user = new CognitoUser({ Username: email, Pool: userPool });
  return new Promise((resolve, reject) =>
    user.confirmPassword(confirmationCode, newPassword, {
      onSuccess: () => {
        loginUser(email, newPassword)
          .then((result) => resolve(result))
          .catch((err) => reject(err));
      },
      onFailure: (err) => reject(err),
    })
  );
}
