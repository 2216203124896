import { fas } from "fontawesome.macro";
import {
  AnimalsDoingThingsLogo,
  AwesomePeopleDoingThingsLogo,
  BugsDoingThingsLogo,
  CatsDoingThingsLogo,
  DoggosDoingThingsLogo,
  DoingThingsMediaLogo,
  DoYouEvenLiftLogo,
  DrunkPeopleDoingThingsLogo,
  DumbPeopleDoingThingsLogo,
  FakeNewsNetworkLogo,
  FestivalistLogo,
  FourTwentyLogo,
  GamersDoingThingsLogo,
  BreezyLogo,
  InsideHistoryLogo,
  KidsDoingThingsLogo,
  KookslamsLogo,
  LiquidNostalgiaLogo,
  MiddleClassFancyLogo,
  MoistBuddhaLogo,
  NeatMomLogo,
  NotFinancialAdviceLogo,
  OrdinaryPeopleLogo,
  RadDadLogo,
  RednecksDoingThingsLogo,
  ShitheadSteveLogo,
  SkatersDoingThingsLogo,
  TrashCanPaulLogo,
  TuesdayBrunchLogo,
  TunaMeltdownLogo,
} from "../assets/images";

const ROOT_URL = window.location.origin
  ? `${window.location.origin}/api/`
  : "https://doingthings.media/api/";

const colors = {
  brandTeal: "#20BFB2", // Teal
  brandSeaFoam: "#8CDBE6", // Seafoam
  brandLightBlue: "#2BC6FC", // Light Blue
  brandBlue: "#3930EF", //  Blue
  brandYellow: "#FCCC32", // Yellow
  brandLavender: "#D998FB", //  Lavender
  brandPink: "#F2308D", // Pink
  brandLightGray: "#F5F6F8", // Grey
  brandWhite: "#FFF", // White
  brandDark: "#171523", // Brand Dark
  nsfwRed: "#e04f5f",
  green: "#32bea6",
};

// Alphabetized based on slugname.
const AGGREGATORS = [
  {
    brandedSubmissionURL: "",
    description: "American gonzo journalism style interviews",
    icon: "",
    name: "All Gas No Brakes",
    selected: false,
    slugname: "allgasnobrakes",
    submittable: false,
    val: "AllGasNoBreaks",
    socials: {
      instagramHandle: "allgasnobrakesshow",
      instagram: "https://www.instagram.com/allgasnobrakesshow/",
      patreon: "https://www.patreon.com/allgasnobrakes",
      youtube: "https://www.youtube.com/channel/UCtqxG9IrHFU_ID1khGvx9sA",
    },
  },
  {
    brandedSubmissionURL: "submit.animalsdoingthings.com",
    description: "The funniest furry (and not so furry)  animals",
    icon: AnimalsDoingThingsLogo,
    name: "Animals Doing Things",
    selected: false,
    merchLink: "https://animalsdoingthings.com",
    slugname: "animalsdoingthings",
    submittable: true,
    tagId: 470,
    val: "animalsDT",
    socials: {
      facebook: "https://www.facebook.com/animalsdoingthings/",
      instagramHandle: "animalsdoingthings",
      instagram: "https://www.instagram.com/animalsdoingthings/",
      tiktok: "https://www.tiktok.com/@animalsdoingthings",
    },
  },
  {
    brandedSubmissionURL: "",
    icon: AwesomePeopleDoingThingsLogo,
    name: "Awesome People Doing Things",
    selected: false,
    slugname: "awesomepeopledoingthings",
    submittable: true,
    tagId: 655,
    val: "awesomeDT",
    socials: {
      instagramHandle: "awesomepeopledoingthings",
      instagram: "https://www.instagram.com/awesomepeopledoingthings/",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "For the golfing enthusiasts",
    icon: BreezyLogo,
    name: "Breezy Golf",
    selected: false,
    merchLink: "https://breezy.golf",
    slugname: "breezy",
    submittable: true,
    tagId: 653,
    val: "golfersDT",
    socials: {
      facebook: "https://www.facebook.com/golfersdoingthings/",
      instagramHandle: "breezygolf",
      instagram: "https://www.instagram.com/breezygolf/",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "",
    facebook: "",
    icon: BugsDoingThingsLogo,
    name: "Bugs Doing Things",
    selected: false,
    slugname: "bugsdoingthings",
    submittable: true,
    tagId: 470,
    val: "bugsDT",
    socials: {
      instagramHandle: "bugsdoingthings",
      instagram: "https://www.instagram.com/bugsdoingthings/",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "Rated the #1 instagram page by every single cat",
    icon: CatsDoingThingsLogo,
    name: "Cats Doing Things",
    selected: false,
    slugname: "catsdoingthings",
    submittable: true,
    tagId: 472,
    val: "catsDT",
    socials: {
      facebook: "https://www.facebook.com/catsdoingthings/",
      instagramHandle: "catsdoingthings",
      instagram: "https://www.instagram.com/catsdoingthings/",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "Rated the #1 instagram page by every single dog",
    icon: DoggosDoingThingsLogo,
    name: "Doggos Doing Things",
    selected: false,
    slugname: "doggosdoingthings",
    submittable: true,
    tagId: 471,
    val: "doggosDT",
    socials: {
      instagram: "https://www.instagram.com/doggosdoingthings/",
      instagramHandle: "doggosdoingthings",
      facebook: "https://www.facebook.com/Doggosdoingthings/",
    },
  },
  {
    brandedSubmissionURL: "submit.doingthingsmedia.com",
    description:
      "The parent brand. We have over 20 social brands under the Doing Things umbrella that we own and operate.",
    icon: DoingThingsMediaLogo,
    name: "Doing Things Media",
    selected: false,
    slugname: "doingthings",
    submittable: true,
    tagId: 646,
    val: "doingthingsDT",
    socials: {
      facebook: "https://www.facebook.com/doingthingsmedia/",
      instagramHandle: "doingthings",
      instagram: "https://www.instagram.com/doingthings/",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "A video guide of how not to do things",
    icon: DumbPeopleDoingThingsLogo,
    name: "Doing Things Wrong",
    selected: false,
    slugname: "doingthingswrong",
    submittable: false,
    tagId: 474,
    val: "dummiesDT",
    socials: {
      facebook: "https://www.facebook.com/dummiesdoingthings/",
      instagramHandle: "doingthingswrong",
      instagram: "https://www.instagram.com/doingthingswrong/",
      tiktok: "https://www.tiktok.com/@doingthingswrong_",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "Do you though?",
    icon: DoYouEvenLiftLogo,
    name: "Do You Even Lift?",
    selected: false,
    slugname: "doyouevenlift",
    submittable: true,
    tagId: 477,
    val: "GymRatsDT",
    socials: {
      instagramHandle: "doyouevenlift",
      instagram: "https://www.instagram.com/doyouevenlift/",
      tiktok: "http://tiktok.com/@doyouevenlift",
    },
  },
  {
    brandedSubmissionURL: "submit.drunkpeopledoingthings.com",
    description: "Source of your favorite party content and fails",
    icon: DrunkPeopleDoingThingsLogo,
    name: "Drunk People Doing Things",
    selected: false,
    merchLink: "https://drunkpeopledoingthings.com",
    slugname: "drunkpeopledoingthings",
    submittable: true,
    tagId: 475,
    val: "drunkPeopleDT",
    socials: {
      facebook: "https://www.facebook.com/drunkpeopledoingthings/",
      instagramHandle: "drunkpeopledoingthings",
      instagram: "https://www.instagram.com/drunkpeopledoingthings/",
      tiktok: "https://www.tiktok.com/@drunkpeopledoingthings",
    },
  },
  {
    icon: FakeNewsNetworkLogo,
    name: "Fake News Network",
    selected: false,
    slugname: "fakenewsnetwork",
    submittable: true,
    val: "fakenewsnetwork",
    socials: {
      instagramHandle: "fakenewsnetwork",
      instagram: "https://www.instagram.com/fakenewsnetwork/",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "Absurd festival content",
    icon: FestivalistLogo,
    name: "Festivalist",
    selected: false,
    slugname: "festivalist",
    submittable: true,
    tagId: 652,
    val: "wooksDT",
    socials: {
      instagramHandle: "festivalist",
      instagram: "https://www.instagram.com/festivalist/",
    },
  },
  {
    brandedSubmissionURL: "submit.fourtwentyshop.co",
    description: "All things weed",
    icon: FourTwentyLogo,
    name: "Four Twenty",
    selected: false,
    merchLink: "https://fourtwentyshop.co",
    slugname: "fourtwenty",
    submittable: true,
    tagId: 476,
    val: "highPeopleDT",
    socials: {
      instagramHandle: "fourtwenty",
      instagram: "https://www.instagram.com/fourtwenty/",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "A community of passionate gamers",
    icon: GamersDoingThingsLogo,
    name: "House of Gaming",
    selected: false,
    slugname: "houseofgaming",
    submittable: true,
    tagId: 651,
    val: "gamersDT",
    socials: {
      instagramHandle: "houseofgaming",
      instagram: "https://www.instagram.com/houseofgaming/",
      tiktok: "https://www.tiktok.com/@gamersdoingthings",
    },
  },
  {
    icon: InsideHistoryLogo,
    name: "Inside History",
    selected: false,
    slugname: "insidehistory",
    submittable: true,
    val: "insidehistory",
    socials: {
      instagramHandle: "insidehistory",
      instagram: "https://www.instagram.com/insidehistory/",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "Kids being kids",
    icon: KidsDoingThingsLogo,
    name: "Kids Doing Things",
    selected: false,
    slugname: "kidsdoingthings",
    submittable: true,
    tagId: 473,
    val: "kidsDT",
    socials: {
      facebook: "https://www.facebook.com/kidsdoingthings/",
      instagramHandle: "kidsdoingthings",
      instagram: "https://www.instagram.com/kidsdoingthings/",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "Curated kook slammage. Submit your videos for a chance to be immortalized on our page forever.",
    icon: KookslamsLogo,
    name: "Kookslams",
    selected: false,
    merchLink: "https://kookslams.com/",
    slugname: "kookslams",
    submittable: true,
    tagId: null,
    val: "kookslams",
    socials: {
      facebook: "https://www.facebook.com/kookslams",
      instagramHandle: "kookslams",
      instagram: "https://www.instagram.com/kookslams/",
      twitter: "https://twitter.com/kookslams",
    },
  },
  {
    icon: LiquidNostalgiaLogo,
    name: "Liquid Nostalgia",
    selected: false,
    merchLink: "https://liquidnostalgia.com",
    slugname: "liquidnostalgia",
    submittable: true,
    val: "liquidnostalgia",
    socials: {
      instagramHandle: "liquid.nostalgia",
      instagram: "https://www.instagram.com/liquid.nostalgia/",
    },
  },
  {
    brandedSubmissionURL: "submit.middleclassfancy.com",
    description: "Self deprecating humor around the middle class life",
    icon: MiddleClassFancyLogo,
    name: "Middle Class Fancy",
    selected: false,
    merchLink: "https://middleclassfancy.com",
    slugname: "middleclassfancy",
    submittable: true,
    tagId: 656,
    val: "middleclassfancyDT",
    socials: {
      facebook: "https://www.facebook.com/middleclassfancy/",
      instagramHandle: "middleclassfancy",
      instagram: "https://www.instagram.com/middleclassfancy/",
      twitter: "https://twitter.com/midclassfancy",
    },
  },
  {
    icon: MoistBuddhaLogo,
    name: "Moist Buddha",
    selected: false,
    slugname: "moistbuddha",
    submittable: true,
    val: "moistbuddha",
    socials: {
      instagramHandle: "moistbuddha",
      instagram: "https://www.instagram.com/moistbuddha",
    },
  },
  {
    brandedSubmissionURL: "",
    icon: NeatMomLogo,
    name: "Neat Mom",
    selected: false,
    slugname: "neatmom",
    submittable: true,
    tagId: 1576,
    val: "neatMomDT",
    socials: {
      instagramHandle: "neatmom",
      instagram: "https://www.instagram.com/neatmom",
    },
  },
  {
    icon: NotFinancialAdviceLogo,
    name: "Not Financial Advice",
    selected: false,
    merchLink: "https://notfinancialadvice.shop",
    slugname: "notfinancialadvice",
    submittable: true,
    val: "notfinancialadvice",
    socials: {
      instagramHandle: "notfinancialadvice__",
      instagram: "https://www.instagram.com/notfinancialadvice__/",
    },
  },
  {
    icon: OrdinaryPeopleLogo,
    name: "Ordinary People",
    selected: false,
    merchLink: "https://ordinarypeopleshop.com",
    slugname: "ordinarypeople",
    submittable: true,
    val: "ordinarypeople",
    socials: {
      instagramHandle: "ordinarypeoplememes",
      instagram: "https://www.instagram.com/ordinarypeoplememes/",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "If your dad was cool and ran a meme page",
    icon: RadDadLogo,
    name: "Rad Dad",
    selected: false,
    slugname: "raddad",
    submittable: true,
    tagId: 649,
    val: "oldPeopleDT",
    socials: {
      instagramHandle: "raddad",
      instagram: "https://www.instagram.com/raddad/",
      facebook: "https://www.facebook.com/raddad/",
    },
  },
  {
    brandedSubmissionURL: "",
    icon: RednecksDoingThingsLogo,
    name: "Merica Doing Things",
    selected: false,
    slugname: "rednecksdoingthings",
    submittable: false,
    tagId: 654,
    val: "rednecksDT",
    socials: {},
  },
  {
    brandedSubmissionURL: "submit.shitheadsteve.com",
    description: "The funniest takes on current events and pop culture",
    icon: ShitheadSteveLogo,
    name: "shitheadsteve",
    selected: false,
    merchLink: "https://shitheadsteve.com",
    slugname: "shitheadsteve",
    submittable: true,
    tagId: 648,
    val: "steve",
    socials: {
      facebook: "https://www.facebook.com/sh1theadsteve/",
      instagramHandle: "shitheadsteve",
      instagram: "https://www.instagram.com/shitheadsteve/",
      twitter: "https://twitter.com/shitheadsteve__",
    },
  },
  {
    brandedSubmissionURL: "",
    icon: SkatersDoingThingsLogo,
    name: "Skaters Doing Things",
    selected: false,
    slugname: "skatersdoingthings",
    submittable: true,
    tagId: 650,
    val: "skatersDT",
    socials: {
      instagramHandle: "skatersdoingthings",
      instagram: "https://www.instagram.com/skatersdoingthings",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "For the meme connoisseur. Advanced memes only",
    icon: TrashCanPaulLogo,
    name: "trashcanpaul",
    selected: false,
    slugname: "trashcanpaul",
    submittable: true,
    val: "TCP",
    socials: {
      facebook: "https://www.facebook.com/trashcanpaul/",
      instagramHandle: "trashcanpaul",
      instagram: "https://www.instagram.com/trashcanpaul/",
    },
  },
  {
    brandedSubmissionURL: "",
    description: "",
    icon: TuesdayBrunchLogo,
    name: "Tuesday Brunch",
    selected: false,
    slugname: "tuesdaybrunch",
    submittable: true,
    tagId: 470,
    val: "tuesdaybrunch",
    socials: {
      instagramHandle: "tuesdaybrunch",
      instagram: "https://www.instagram.com/tuesdaybrunch/",
    },
  },
  {
    icon: TunaMeltdownLogo,
    link: "https://www.instagram.com/tuna.meltdown/",
    name: "Tuna Meltdown",
    selected: false,
    slugname: "tunameltdown",
    submittable: true,
    val: "tunameltdown",
    socials: {
      instagramHandle: "tuna.meltdown",
      instagram: "https://www.instagram.com/tuna.meltdown/",
    },
  },
];

const yesNoOptions = [
  {
    title: "Yes",
    value: "1",
  },
  {
    title: "No",
    value: "0",
  },
];

const logTypeValues = [
  {
    title: "All",
    value: "",
  },
  {
    title: "Clip",
    value: "clip",
  },
  {
    title: "Tag",
    value: "tag",
  },
  {
    title: "Tag Clip",
    value: "tagClip",
  },
  {
    title: "Tag Category",
    value: "tagCategory",
  },
];

const actionTypeValues = [
  {
    title: "All",
    value: "",
  },
  {
    title: "Add",
    value: "add",
  },
  {
    title: "Edit",
    value: "edit",
  },
  {
    title: "Delete",
    value: "delete",
  },
];

const CATEGORIES = [
  {
    id: 17,
    name: "Actions",
    selected: false,
    title: "Actions",
    val: "actions",
  },
  {
    id: 1,
    name: "Animals",
    selected: false,
    title: "Animals",
    val: "animal",
  },
  {
    id: 2,
    name: "Color",
    selected: false,
    title: "Color",
    val: "color",
  },
  {
    id: 3,
    name: "Cute",
    selected: false,
    title: "Cute",
    val: "cute",
  },
  {
    id: 16,
    name: "Dogs",
    selected: false,
    title: "Dogs",
    val: "dog",
  },
  {
    id: 4,
    name: "Fails",
    selected: false,
    title: "Fails",
    val: "fail",
  },
  {
    id: 5,
    name: "Food and Drink",
    selected: false,
    title: "Food and Drink",
    val: "food",
  },
  {
    id: 6,
    name: "Gym Activities",
    selected: false,
    title: "Gym Activities",
    val: "gym",
  },
  {
    id: 7,
    name: "High",
    selected: false,
    title: "High",
    val: "high",
  },
  {
    id: 8,
    name: "Holidays",
    selected: false,
    title: "Holidays",
    val: "holiday",
  },
  {
    id: 9,
    name: "Individuals",
    selected: false,
    title: "Individuals",
    val: "individual",
  },
  {
    id: 10,
    name: "Location",
    selected: false,
    title: "Location",
    val: "location",
  },
  {
    id: 18,
    name: "Music",
    selected: false,
    title: "Music",
    val: "music",
  },
  {
    id: 11,
    name: "Party",
    selected: false,
    title: "Party",
    val: "party",
  },
  {
    id: 12,
    name: "Sports",
    selected: false,
    title: "Sports",
    val: "sport",
  },
  {
    id: 13,
    name: "Stunts",
    selected: false,
    title: "Stunts",
    val: "stunt",
  },
  {
    id: 14,
    name: "Transportation",
    selected: false,
    title: "Transportation",
    val: "transportation",
  },
  {
    id: 15,
    name: "Other",
    selected: false,
    title: "Other",
    val: "other",
  },
];

const RATINGS = [
  {
    name: "Approved",
    selected: false,
    val: "approved",
    icon: fas("thumbs-up"),
  },
  {
    name: "Disapproved",
    selected: false,
    val: "disapprove",
    icon: fas("thumbs-down"),
  },
  {
    name: "Bookmarked",
    selected: false,
    val: "bookmarked",
    icon: fas("bookmark"),
  },
  {
    name: "Unmarked",
    selected: false,
    val: "unmarked",
    icon: fas("minus-circle"),
  },
  {
    name: "Viral",
    selected: false,
    val: "isViral",
    icon: fas("fire"),
  },
  {
    name: "NSFW",
    selected: false,
    val: "vimeo",
    icon: fas("exclamation-triangle"),
  },
];

const LICECERATINGS = [
  {
    name: "Viral",
    selected: false,
    val: "isViral",
  },
  {
    name: "Approved",
    selected: false,
    val: "approved",
  },
];

const SORTBY = [
  {
    name: "Oldest to Newest",
    val: "ASC",
  },
  {
    name: "Newest to Oldest",
    val: "DESC",
  },
];

const adminGroup = [
  "admin",
  "externalPartners",
  "executiveAdmin",
  "confirmers",
  "jukin",
  "pitchHammer",
];
const dtmAdmins = ["admin"];

const clipValidationFields = {
  file: "Submission to upload",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  videoInvalid: "Video",
  didYouRecord: "I was holding the camera when creating this video.",
  noExclusive:
    "I have not signed an agreement with anyone else or any other websites regarding the submitted content.",
  recaptcha: "Recaptcha",
  description: "Description",
  privateDescription: "Description",
  aggregator: "Brand Not Selected",
  revShareError: "",
  revShareResult: "",
  overEighteen: "I am over the age of 18.",
  reviewedRights:
    "I have reviewed and agreed to the License Agreement and understand that I am transferring exclusive rights to the submitted content.",
  reviewedTermsPrivacy:
    "I have reviewed and agreed to the Terms of Service and Privacy Policy.",
  location: "Location",
};

const SEARCH_OPTIONS = [
  {
    title: "All Fields",
    value: "all",
  },
  {
    title: "Video ID",
    value: "noteId",
  },
  {
    title: "Video Title",
    value: "videoTitle",
  },
  {
    title: "Description",
    value: "description",
  },
  {
    title: "IG Handle",
    value: "userCredit",
  },
  {
    title: "Duration",
    value: "duration",
  },
  {
    title: "First Name",
    value: "firstName",
  },
  {
    title: "Last Name",
    value: "lastName",
  },
  {
    title: "Full Name",
    value: "fullName",
  },
  {
    title: "Email",
    value: "email",
  },
  {
    title: "Location",
    value: "location",
  },
  {
    title: "Tag",
    value: "tag",
  },
];

const SEARCH_STOP_WORDS = [
  "a",
  "an",
  "and",
  "are",
  "as",
  "at",
  "be",
  "but",
  "by",
  "for",
  "if",
  "in",
  "into",
  "is",
  "it",
  "no",
  "not",
  "of",
  "on",
  "or",
  "such",
  "that",
  "the",
  "their",
  "then",
  "there",
  "these",
  "they",
  "this",
  "to",
  "was",
  "will",
  "with",
];

const DEFAULT_SEARCH_OBJECT = {
  offset: 0,
  orderBy: {
    field: "createdAt",
    descending: "DESC",
  },
  searchTerms: [],
  selectedAggregators: AGGREGATORS.filter(
    (aggregator) => aggregator.submittable
  ),
  selectedCategories: CATEGORIES,
  selectedRatings: RATINGS,
  searchFields: SEARCH_OPTIONS.filter(
    (option) => !["all", "fullName"].includes(option.value)
  ).map((option) => option.value),
  selectedTags: [],
};

const DEFAULT_ADMIN_SEARCH_OBJECT = {
  additionalOptions: {
    contentType: 'video'
  },
  aspect_ratio: null,
  endDate: null,
  exact: false,
  isLocationAdded: undefined,
  offset: 0,
  orderBy: {
    field: "createdAt",
    direction: "DESC",
  },
  searchTerms: [],
  selectedAggregators: AGGREGATORS,
  selectedCategories: CATEGORIES,
  selectedRatings: RATINGS,
  selectedTags: [],
  startDate: null,
  searchFields: SEARCH_OPTIONS.filter(
    (option) => !["all", "fullName"].includes(option.value)
  ).map((option) => option.value)
};

const aspectRatios = {
  landscape: [
    "5:4",
    "4:3",
    "3:2",
    ,
    "16:10",
    "5:3",
    "16:9",
    "17:9",
    "21:9",
    "1:1",
  ],
  portrait: [
    "4:5",
    "3:4",
    "2:3",
    "10:16",
    "3:5",
    "9:16",
    "9:17",
    "9:21",
    "1:1",
  ],
};

export {
  actionTypeValues,
  adminGroup,
  AGGREGATORS,
  CATEGORIES,
  clipValidationFields,
  colors,
  DEFAULT_ADMIN_SEARCH_OBJECT,
  DEFAULT_SEARCH_OBJECT,
  dtmAdmins,
  LICECERATINGS,
  logTypeValues,
  RATINGS,
  ROOT_URL,
  SEARCH_OPTIONS,
  SORTBY,
  SEARCH_STOP_WORDS,
  yesNoOptions,
  aspectRatios,
};
