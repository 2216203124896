import { useContext, useEffect, useMemo, useState, memo } from "react";
import { toast } from "react-toastify";
import {
  downloadCount,
  getLibrary,
  removeFromLibrary,
} from "../../libs/request/partnerLibrary";
import DataTable from "../../screens/DataTable";
import { BulkDownloadContext } from "../../store/bulkDownloadsStore";
import Context from "../../store/store";
import Loader from "../Loader";
import moment from "moment";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "fontawesome.macro";
import { setBrandLogo } from "../../helpers/global";
import SlideViewer from "../SlideViewer";
import {
  ADD_DOWNLOAD,
  REMOVE_DOWNLOAD,
  SET_STATE,
} from "../../reducers/BulkDownloadReducer";

export default function PartnerLibrary() {
  const { stateUser } = useContext(Context);
  const { state, dispatch } = useContext(BulkDownloadContext);
  const [loading, setLoading] = useState(true);
  const [activeSub, setActiveSub] = useState();

  const loadData = async () => {
    await getLibrary(stateUser.awsId)
      .then(({ submissions }) => {
        let totalDuration = 0;
        submissions.forEach((submission) => {
          totalDuration += parseInt(submission.duration);
        });
        const tempData = {
          submissions: submissions,
          totalDuration: totalDuration,
          selectedDuration: 0,
        };
        dispatch({ type: SET_STATE, payload: { ...tempData } });
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong . . .");
        console.log(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleRemoveFromLibrary = ({ id }) => {
    removeFromLibrary(id, stateUser.awsId)
      .then(() => {
        toast.success("Removed submission from your library!");
        setLoading(true);
        loadData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong . . .");
      });
  };

  const handleDownloadSubmission = async ({ id }) => {
    await downloadCount(id, stateUser.awsId)
      .then(() => {
        toast.success("Successfully downloaded submission.");
      })
      .catch((err) => {
        toast.error("Something went wrong . . .");
        console.log(err);
      });
  };

  const handleCheck = (e, id, attachment, duration) => {
    if (e.target.checked) {
      const downloadInfo = {
        id,
        attachment,
        duration: parseInt(duration),
      };
      dispatch({
        type: ADD_DOWNLOAD,
        payload: {
          download: downloadInfo,
        },
      });
    } else {
      dispatch({
        type: REMOVE_DOWNLOAD,
        payload: {
          download: { id, duration },
        },
      });
    }
  };

  const handleBulkDownload = () => {
    const downloadBulkSubmissions = state.bulkDownloads.map((download) => {
      downloadCount(download.id, stateUser.awsId).catch((err) => {
        console.log("error", err);
        toast.error("Something went wrong . . .");
      });
    });

    Promise.all(downloadBulkSubmissions)
      .then(() => {
        state.bulkDownloads.forEach((download) => {
          const link = document.createElement("a");
          link.id = download.id;
          link.setAttribute("href", download.attachment);
          link.setAttribute("target", "_blank");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
        toast.success("Bulk Download: Complete!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong . . .");
      });
  };

  const handleClose = () => {
    setActiveSub(null);
  };

  const renderAggregator = (aggregator) => (
    <div className='centered'>
      <img
        src={setBrandLogo(aggregator)}
        className='agg-logo-table'
        alt='DTMedia'
      />
    </div>
  );

  const renderThumbnail = (submission) => (
    <img
      style={{ width: 85, height: 85, cursor: "pointer" }}
      src={submission.thumbnail}
      onClick={() => setActiveSub(submission)}
    />
  );

  const renderCreatedDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const renderActions = (row) => (
    <>
      <div style={{ display: "flex", gap: "8px" }}>
        <a href={row.attachment} download>
          <Button onClick={async () => await handleDownloadSubmission(row)}>
            <FontAwesomeIcon icon={fas("download")} />
          </Button>
        </a>
        <Button onClick={() => handleRemoveFromLibrary(row)}>
          <FontAwesomeIcon icon={fas("trash")} />
        </Button>
      </div>
    </>
  );

  const renderDownloadCheckbox = ({ id, attachment, duration }) => (
    <input
      type='checkbox'
      onChange={(e) => handleCheck(e, id, attachment, duration)}
    />
  );

  const data = useMemo(() => state.submissions, [state]);

  const columns = useMemo(
    () => [
      {
        Header: "Brand",
        accessor: "aggregator",
        className: "align-center",
        headerClassName: "align-center",
        Cell: (props) => renderAggregator(props.row.original.aggregator),
      },
      {
        Header: "Submission Title",
        accessor: "videoTitle",
        Cell: (props) => props.row.original.videoTitle,
      },
      {
        Header: "Description",
        accessor: "privateDescription",
        width: 300,
        Cell: (props) => props.row.original.privateDescription,
      },
      {
        Header: "User Credit",
        accessor: "userCredit",
        Cell: (props) => props.row.original.userCredit,
      },
      {
        Header: "Preview",
        accessor: "thumbnail",
        Cell: (props) => renderThumbnail(props.row.original),
      },
      {
        Header: "Id",
        accessor: "id",
        Cell: (props) => props.row.original.id,
      },
      {
        Header: "Actions",
        width: 300,
        Cell: (props) => renderActions(props.row.original),
      },
      {
        Header: "Download",
        className: "align-center",
        headerClassName: "align-center",
        Cell: (props) => renderDownloadCheckbox(props.row.original),
      },
    ],
    [state.submissions]
  );

  return (
    <>
      <div className='partner-library list'>
        <div className='header'>
          <div className='header-col'>
            <h1>Partner Library</h1>
            <span>
              {data?.length === 0
                ? "There are no items in your library."
                : `${data?.length} items in your library totaling ${moment
                    .utc(state.totalDuration * 1000)
                    .format("HH:mm:ss")}`}
            </span>
          </div>
        </div>
        {loading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            {activeSub && (
              <SlideViewer
                handleClose={handleClose}
                contentType={activeSub.contentType}
                mediaUrl={activeSub.attachment}
              />
            )}

            <DataTable columns={columns} data={data} />

            {state.bulkDownloads.length > 0 && (
              <div
                style={{
                  display: "flex",
                  padding: "1.5rem",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  fontSize: "1.2rem",
                }}
              >
                {state.selectedDuration > 0 &&
                  "Selected Download's Duration: " +
                    moment
                      .utc(state.selectedDuration * 1000)
                      .format("HH:mm:ss")}
                <Button className='btn' onClick={handleBulkDownload}>
                  Download
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
