import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import { CustomButton, TextInput } from './form'

const PaginationBar = ({
  changePage, clipCount, offset, offsetNumber, parent,
}) => {
  const [blankPage, setBlankPage] = useState(false)
  return (
    <div className="pagination-bar">
      <div className="prev">
        <CustomButton
          disableMe={offset === 0}
          handleClick={() => changePage(offset - 1)}
          styling="page-button p"
          title="Previous"
          icon="chevron-left"
        />
      </div>
      <div className="count-input">
        <span>Page:</span>
        <TextInput
          parent={parent}
          id="page"
          name="page"
          value={blankPage ? '' : offsetNumber}
          handleChange={(e) => {
            if (e.target.value * 1 > 0 && e.target.value * 1 <= Math.ceil(clipCount / 20)) {
              setBlankPage(false)
              changePage(parseInt(e.target.value, 10) - 1)
            } else if (!e.target.value && !blankPage) {
              setBlankPage(true)
            }
          }}
          type="text"
          textPlaceholder="Page"
        />
        <span>{`of ${clipCount ? Math.ceil(clipCount / 20).toLocaleString() : 0}`}</span>
      </div>
      <div className="next">
        <CustomButton
          disableMe={clipCount <= ((offset + 1) * 20)}
          handleClick={() => changePage(offset + 1)}
          styling="page-button n"
          title="Next Page"
          icon="chevron-right"
        />
      </div>
    </div>
  )
}

export default PaginationBar
