import { addSubToCampaign } from "../../libs/request/mRssFeed";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { stripVideoUUID } from "../../helpers/global";
import SlideViewer from "../SlideViewer";

export default function ListActions({
  submission,
  campaignData,
  loadData,
  setShowActions,
  setActiveSubmission,
  mediaViewerClip,
}) {
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [futureCampaign, setFutureCampaign] = useState();

  const campaignMap = new Map();
  campaignData.map((campaign) => campaignMap.set(campaign.campaignId, { id: campaign.campaignId, name: campaign.campaignName }));
  const activeCampaign = campaignMap.get(submission.campaignId);

  const addTofutureCampaign = (campaignId) => {
    const campaign = campaignMap.get(campaignId);
    setFutureCampaign(campaign);
  };

  const handleClose = () => {
    setActiveSubmission({});
    setFutureCampaign();
    setShowActions(false);
  };

  const handleSaveCampaignChanges = () => {
    addSubToCampaign(submission.noteId, futureCampaign.id);
    loadData();
    handleClose();
  };

  return (
    <>
      <div className="slide half">
        <Button
          className='slide-close'
          onClick={() => handleClose()}
        ></Button>
        <div className="slide-container">
          <div className="slide-wrapper">
            <SlideViewer handleClose={handleClose} contentType={mediaViewerClip.contentType} mediaUrl={mediaViewerClip.contentType !== 'image' ? stripVideoUUID(mediaViewerClip.attachment) : mediaViewerClip.attachment}>

              {activeCampaign ?
                <h4 className="banner">This video has been added to the <strong>{activeCampaign.name}</strong> campaign.</h4> :
                <h4 className="banner"><span>😐 </span>This video has not been added to a campaign yet.</h4>
              }

              {/* Actions */}
              <div className="mrss-actions">
                <Dropdown toggle={() => setDropdownToggle(!dropdownToggle)} isOpen={dropdownToggle}>
                  <span className="text">Add this media to the</span>
                  <span className="dropdown-text">
                    <DropdownToggle>--</DropdownToggle>
                    <DropdownMenu>
                      {campaignData.map((campaign, i) => (
                        campaign.campaignId != submission.campaignId && (
                          <DropdownItem key={i} onClick={() => addTofutureCampaign(campaign.campaignId)}>
                            {campaign.campaignName}
                          </DropdownItem>
                        )
                      ))}
                    </DropdownMenu>
                  </span>
                  <span className="text">mRSS campaign.</span>
                </Dropdown>
                <Button className="" onClick={() => handleSaveCampaignChanges()}>Save</Button>
              </div>
            </SlideViewer>
          </div>
        </div>
      </div>
    </>
  );
}
