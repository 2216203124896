import moment from "moment"
import { useState } from "react"
import { toast } from "react-toastify"
import { addUserToGroup, removeUserFromGroup } from '../actions'
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import EditUserDropdown from "./components/EditUserDropdown"
import MicroTable from "../components/MicroTable"

export default function EditUser({ user, stateUser, adminGroups, setShowEditUser, setUserToEdit, loadAdminUsers, setStandardUsers, setSearchString, setErrorAddUser, setErrorSearchUser }) {
  const [dropdownToggleAdd, setDropdownToggleAdd] = useState(false)
  const [dropdownToggleRemove, setDropdownToggleRemove] = useState(false)
  const [groupToAdd, setGroupToAdd] = useState(null)
  const [groupToRemove, setGroupToRemove] = useState(null)
  const [targetGroupToAdd, setTargetGroupToAdd] = useState(null)
  const [targetGroupToRemove, setTargetGroupToRemove] = useState(null)
  const [confirmationMessageAdd, setConfirmationMessageAdd] = useState(null)
  const [confirmationMessageRemove, setConfirmationMessageRemove] = useState(null)

  const availableGroups = adminGroups.filter(group => !user.groups.includes(group) && group !== 'users')

  const handleSelectAddGroup = (group) => {
    setGroupToAdd(group)
    setTargetGroupToAdd(group)
    setConfirmationMessageAdd(`Adding ${user.firstName} ${user.lastName} to ${group}`)
  }

  const handleSelectRemoveGroup = (group) => {
    if (group === 'users') {
      toast.error('Cannot remove user from that group!')
      return
    } else {
      setGroupToRemove(group)
      setTargetGroupToRemove(group)
      setConfirmationMessageRemove(`Removing ${user.firstName} ${user.lastName} from ${group}`)
    }
  }

  const handleClearGroupChanges = (action) => {
    action === 'add' ? handleAddClearGroupChanges() : handleRemoveClearGroupChanges()

  }

  const handleAddClearGroupChanges = () => {
    setGroupToAdd(null)
    setTargetGroupToAdd(null)
    setConfirmationMessageAdd(null)
  }

  const handleRemoveClearGroupChanges = () => {
    setGroupToRemove(null)
    setTargetGroupToRemove(null)
    setConfirmationMessageRemove(null)
  }

  const removeFromAdminGroup = () => {
    if (!window.confirm('Are you sure you want to remove this user from the group?')) {
      return
    }
    removeUserFromGroup(user.awsId, targetGroupToRemove).then((response) => {
      if (response.message) {
        toast.error('Something went wrong . . .')
        setErrorSearchUser(response.message)
        return
      }
      toast.success(`Removed from ${targetGroupToRemove}`)
    }).then(loadAdminUsers())
  }

  const addToAdminGroup = () => {
    addUserToGroup(user.awsId, targetGroupToAdd).then((response) => {
      if (response.message) {
        toast.error('Something went wrong . . .')
        setErrorAddUser(response.message)
        return
      }
      toast.success('Success!')
      setSearchString('')
      setStandardUsers([])
      loadAdminUsers()
    })
  }

  const microTableData = [
    {
      key: 'Id:',
      value: user.id
    },
    {
      key: 'AWS Id:',
      value: user.awsId
    },
    {
      key: 'Groups:',
      value: user.groups.map((group, i) => (
        <span key={i}>{group} </span>
      ))
    },
    {
      key: 'Email:',
      value: user.email != '' ? user.email : user.paypalEmail !== '' ? user.paypalEmail : 'No email for user'
    },
    {
      key: 'Created At:',
      value: moment(user.createdAt).format('MM/DD/YYYY hh:mm')
    },
    {
      key: 'Name:',
      value: `${user.firstName} ${user.lastName}`
    },
    {
      key: 'Instagram:',
      value: user.instagramUser
    },
    {
      key: 'Phone Number:',
      value: user.phone
    },
  ]

  return (
    <>
      <div id="mediaViewer" className="slide">
        <Button className="slide-close" onClick={() => {
          setShowEditUser(false)
          setUserToEdit({})
        }}></Button>
        <div className="slide-container">
          <div className="slide-wrapper" style={{ marginTop: '2rem', justifyContent: 'flex-start', overflow: 'scroll', gap: '2rem' }}>
            <div>
              <MicroTable
                label={`${user.firstName} ${user.lastName}`}
                data={microTableData}
              />
            </div>
            {stateUser.groups.includes('superAdmin') && (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                  <EditUserDropdown
                    setDropdownToggle={setDropdownToggleAdd}
                    dropdownToggle={dropdownToggleAdd}
                    label={'Add User To Group'}
                    availableGroups={availableGroups}
                    handleSelect={handleSelectAddGroup}
                    handleClick={addToAdminGroup}
                    handleClearGroupChanges={handleClearGroupChanges}
                    action={'add'}
                    confirmationMessage={confirmationMessageAdd}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    <EditUserDropdown
                      setDropdownToggle={setDropdownToggleRemove}
                      dropdownToggle={dropdownToggleRemove}
                      label={'Remove User From Group'}
                      availableGroups={user.groups.filter(group => group !== 'users')}
                      handleSelect={handleSelectRemoveGroup}
                      handleClick={removeFromAdminGroup}
                      handleClearGroupChanges={handleClearGroupChanges}
                      action={'remove'}
                      confirmationMessage={confirmationMessageRemove}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}