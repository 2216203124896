import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Col, Row } from "reactstrap";
import { loginUser, submitConfirmationCode, resendConfirmationCode } from "../libs/awsLib";
import { addUserToGroup } from "../libs/request";
import { SignIn } from "../components";

const Login = (props) => {
  const [awsError, setAwsError] = useState("");
  const [modalError, setModalError] = useState("");
  const [showModal, setShowModal] = useState(false);


  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const handleLogIn = (event, email, password) => {
    event.preventDefault();
    loginUser(email, password)
      .then((result) => {
        props.authenticateUser(result.accessToken.jwtToken);
        props.history.push("/");
      })
      .catch((error) => {
        console.log('error', error)
        if (error.name === "UserNotConfirmedException") {
          toggleModal();
        } else {
          setAwsError(error.message);
        }
      });
  };

  const handleConfirmationCode = async (
    event, { email, password }, confirmationCode,
  ) => {
    event.preventDefault();
    submitConfirmationCode(email, password, confirmationCode).then((result) => {
      const newAuthUser = props.authenticateUser(result.accessToken.jwtToken);
      toggleModal();
      addUserToGroup(newAuthUser.sub, "users").then(() => {
        props.authenticateUser(result.accessToken.jwtToken);
        props.history.push("/");
      }).catch((error) => {
        setAwsError(error.message);
      });
    }).catch((error) => {
      console.log('error', error)
      if (error.name === "ExpiredCodeException") {
        resendConfirmationCode(email);
        setModalError("Your code has expired. A new code has been sent, please use the new code within 24 hours.");
      } else {
        setModalError(error.message);
      }
    });
  };

  return (
    <Container tag={"main"}>
      <Helmet>
        <title>Log In | Doing Things Media</title>
        <meta name="description" content="Sign into Doing Things Media and upload your content faster!" />
      </Helmet>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <SignIn
            awsError={awsError}
            handleLogIn={handleLogIn}
            showModal={showModal}
            toggleModal={toggleModal}
            handleConfirmationCode={handleConfirmationCode}
            modalError={modalError}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
