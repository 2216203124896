import React from 'react'
import { Button } from 'reactstrap'
import { AGGREGATORS } from '../../helpers/constants'

const aggregators = AGGREGATORS.filter(({ submittable }) => submittable)

const CategoryList = ({
  handleFieldUpdate,
  activeBrand,
  setActiveBrand
}) => (
  <div className="categorylist">
    <h2>Select a Brand</h2>
    <div className={`categorylist-wrapper ${activeBrand ? 'activeBrand' : ""}`}>

      {/* Iterate through the options. */}
      {aggregators.map((item, index) => {

        // Set the logo.
        const ourStyle = {}
        if (item.icon) {
          ourStyle.backgroundImage = `url(${item.icon})`;
        }

        // Determine if this brand is currently selected.
        const activeClass = (activeBrand && item.slugname == activeBrand.slugname) ? 'active' : 'inactive'

        return (
          <div className="category-container" key={index}>
            <Button
              style={ourStyle}
              className={`category ${activeClass}`}
              onClick={() => {
                setActiveBrand(item)
                handleFieldUpdate({
                  target: {
                    type: 'input',
                    name: 'aggregator',
                    value: item.slugname,
                  },
                })
                handleFieldUpdate({
                  target: {
                    type: 'input',
                    name: 'tagId',
                    value: item.tagId,
                  },
                })
              }}
            />
          </div>
        )

      })}
    </div>
  </div>
)

export default CategoryList