import { get } from './common'
import { ROOT_URL } from '../../helpers/constants'

export function addUserToGroup(username, group) {
  return fetch('/api/user/group', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'post',
    body: JSON.stringify({
      username,
      group,
    }),
  }).then((response) => response.json())
}

export function uploadProfilePhoto(file, awsId) {
  const formData = new FormData()
  formData.append('file', file)
  return fetch(`/api/userPhoto/${ awsId }`, {
    headers: {
      Accept: 'application/json',
    },
    method: 'post',
    body: formData,
  }).then((response) => response.json())
}

export const getMyClips = (userID) => {
  const url = `${ ROOT_URL }user/${ userID }/clips`
  return get(url)
}
