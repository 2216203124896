import { Navbar } from "reactstrap";
import DoingThingsMediaLogo from "../../assets/images/badges/DoingThingsMedia.png";
import Menu from "./Menu";
import React from "react";

const TopNavigation = () => {
  return (
    <>
      <Navbar id="top" sticky="top">
        {/* Branding */}
        <a href="/" title="Doing Things Media" className="branding">
          <img src={DoingThingsMediaLogo} alt="Doing Things Media" />
        </a>

        {/* Menu */}
        <Menu></Menu>
      </Navbar>
    </>
  );
};

export default TopNavigation;
