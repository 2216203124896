import React, { useState, useEffect, useContext } from "react";
import Context from "../store/store";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupButtonDropdown,
  Row,
} from "reactstrap";
import {
  TextInput,
  SortByDate,
  RatingCheckbox,
  AggregatorCheckbox,
} from "../components/form";
import {
  DashboardTable,
  MediaViewer,
  SubmissionDetails,
} from "../components/dashboard";
import {
  aspectRatios,
  DEFAULT_ADMIN_SEARCH_OBJECT,
  SEARCH_OPTIONS,
} from "../helpers/constants";
import {
  changePage,
  clearCheckboxes,
  exportAllClips,
  handleCheckChangeSearch,
  handleSearchChange,
  handleSort,
  newClipSearch,
  selectAllCheckboxes,
  useDebounce,
} from "../libs/utils";
import ReactSwitch from "react-switch";

const Dashboard = (props) => {
  const { stateUser } = useContext(Context);
  const [searchString, setSearchString] = useState("");
  const [clipCount, setClipCount] = useState(1);
  const [clips, setClips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [searchSelection, setSearchSelection] = useState("all");
  const [searchSelectionTitle, setSearchSelectionTitle] = useState(undefined);
  const [showExport, setShowExport] = useState(false);
  const defaultSearchObject = DEFAULT_ADMIN_SEARCH_OBJECT;
  const [searchObject, setSearchObject] = useState(defaultSearchObject);
  const debouncedSearchObject = useDebounce(searchObject, 500);
  const [exactSearch, setExactSearch] = useState(false);

  // Dropdowns
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [arToggle, setARToggle] = useState(false);

  useEffect(() => {
    if (typeof debouncedSearchObject.offset === "number") {
      newClipSearch(
        debouncedSearchObject,
        setClipCount,
        setClips,
        setIsLoading,
        "dashboard",
        stateUser.groups.includes("externalPartners")
      );
    }
  }, [debouncedSearchObject]);

  const [showMediaViewer, setShowMediaViewer] = useState(false);

  const [mediaViewerClip, setMediaViewerClip] = useState({});
  const handleShowMediaViewer = (row) => {
    setShowMediaViewer(!showMediaViewer);
    setMediaViewerClip({ ...row });
  };

  const updateMediaViewer = (row) => {
    setMediaViewerClip({ ...row });
  };

  const [showSubmissionDetails, setShowSubmissionDetails] = useState(false);
  const handleShowSubmissionDetails = (row) => {
    setShowSubmissionDetails(!showSubmissionDetails);
    setMediaViewerClip((state) => {
      return { ...state, ...row };
    });
  };

  const handleTagsUsedChange = (noteId, tagsUsed) => {
    const rowIndex = clips.findIndex((obj) => obj.noteId === noteId);
    const newClips = [...clips];
    newClips[rowIndex] = { ...newClips[rowIndex], tags_used: tagsUsed };
    setClips(newClips);
  };

  const selectAllCheckboxesDashboard = (array) => {
    selectAllCheckboxes(array, searchObject, setSearchObject);
  };

  const handleCheckChangeDashboard = (index, array) => {
    handleCheckChangeSearch(index, array, searchObject, setSearchObject);
  };

  const changePageDashboard = (page) => {
    changePage(page, searchObject, setSearchObject);
  };

  const clearCheckboxesDashboard = (array) => {
    clearCheckboxes(array, searchObject, setSearchObject);
  };

  // If changing search string, update that string
  // If changing search selection, update that selection
  const handleSearchChangeDashboard = (
    currentSearchString = searchString,
    currentSearchSelection = searchSelection
  ) => {
    handleSearchChange(
      currentSearchString,
      searchObject,
      setSearchObject,
      setSearchString,
      currentSearchSelection,
      true
    );
  };

  const handleSortDashboard = (field, direction) => {
    handleSort(field, direction, searchObject, setSearchObject);
  };

  const toggleSearchDropdown = () => setSearchDropdownOpen(!searchDropdownOpen);
  const toggleTypeDropdown = () => setTypeDropdownOpen(!typeDropdownOpen);
  const toggleLocationDropdown = () =>
    setLocationDropdownOpen(!locationDropdownOpen);
  const toggleStatusDropdown = () => setStatusDropdownOpen(!statusDropdownOpen);

  const handleToggleSearchType = () => {
    setSearchObject((current) => ({ ...current, exact: !exactSearch }));
    setExactSearch(!exactSearch);
  };

  return (
    <>
      {/* Media Viewer */}
      {showMediaViewer && (
        <MediaViewer
          mediaViewerClip={mediaViewerClip}
          updateMediaViewer={updateMediaViewer}
          handleTagsUsedChange={handleTagsUsedChange}
          clips={clips}
          setClips={setClips}
          setShowMediaViewer={setShowMediaViewer}
          setMediaViewerClip={setMediaViewerClip}
        />
      )}

      {/* Submission Details */}
      {showSubmissionDetails && (
        <SubmissionDetails
          mediaViewerClip={mediaViewerClip}
          updateMediaViewer={updateMediaViewer}
          handleTagsUsedChange={handleTagsUsedChange}
          clips={clips}
          setClips={setClips}
          setShowMediaViewer={setShowMediaViewer}
          setShowSubmissionDetails={setShowSubmissionDetails}
          setMediaViewerClip={setMediaViewerClip}
        />
      )}

      <Row id='dashboard'>
        {/* Export */}
        {showExport && (
          <InputGroup id='export'>
            <TextInput
              handleChange={(e) =>
                setSearchObject({
                  ...searchObject,
                  startDate: e.target.value,
                })
              }
              id='downloadStart'
              name='downloadStart'
              required
              type='date'
              styling='db-search'
              labelTitle='Start Date'
              value={searchObject.startDate || ""}
            />
            <TextInput
              handleChange={(e) =>
                setSearchObject({
                  ...searchObject,
                  endDate: e.target.value,
                })
              }
              id='downloadEnd'
              name='downloadEnd'
              required
              type='date'
              styling='db-search'
              labelTitle='End Date'
              value={searchObject.endDate || ""}
            />
            <Button
              className='btn simple'
              disabled={isLoadingDownload}
              style={{ pointerEvents: isLoadingDownload ? 'none' : 'auto' }}
              onClick={() => {
                if (isLoadingDownload) {
                  return;
                }
                exportAllClips(debouncedSearchObject, setIsLoadingDownload)
                  .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = "export.csv";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                  })
                  .catch((error) => {
                    console.error("Error during export:", error);
                  });
              }}
            >
              {isLoadingDownload ? <div className="export-loader"></div> : 'Export Bulk Data'}
            </Button>
          </InputGroup>
        )}

        {/* Dashboard Options */}
        <div id='options'>
          {/* Search */}
          <InputGroup id='search'>
            <Dropdown
              addonType='prepend'
              isOpen={searchDropdownOpen}
              toggle={toggleSearchDropdown}
            >
              <DropdownToggle caret className='btn simple'>
                {searchSelectionTitle ?? "Limit Search"}
              </DropdownToggle>
              <DropdownMenu id='searchSelection' name='searchSelection'>
                {SEARCH_OPTIONS.map((options) => (
                  <DropdownItem
                    value={options.value}
                    onClick={(e) => {
                      setSearchSelection(e.target.value);
                      setSearchSelectionTitle(
                        options.value === "all" ? undefined : options.title
                      );
                      handleSearchChangeDashboard(undefined, e.target.value);
                    }}
                    key={options.value}
                  >
                    {options.title}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <TextInput
              id='searchInput'
              name='searchText'
              type={searchSelection === "duration" ? "number" : "text"}
              textPlaceholder='Search for a tag, title or keyword'
              value={searchString || ""}
              required
              handleChange={(e) => handleSearchChangeDashboard(e.target.value)}
            />
            <div
              style={{
                display: "flex",
                gap: 8,
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              <ReactSwitch
                id='exact-search'
                checked={exactSearch}
                onChange={handleToggleSearchType}
              />
              <label htmlFor='exact-search'>
                {exactSearch ? `Exact` : `Broad`} Search
              </label>
            </div>
          </InputGroup>

          {/* Filtering */}
          <InputGroup id='filter'>
            {/* Brand */}
            <AggregatorCheckbox
              clearCheckboxes={clearCheckboxesDashboard}
              handleCheckChange={handleCheckChangeDashboard}
              selectAllCheckboxes={selectAllCheckboxesDashboard}
              selectedAggregators={searchObject.selectedAggregators}
            />

            {/* Rating */}
            <RatingCheckbox
              clearCheckboxes={clearCheckboxesDashboard}
              handleCheckChange={handleCheckChangeDashboard}
              selectAllCheckboxes={selectAllCheckboxesDashboard}
              selectedRatings={searchObject.selectedRatings}
            />

            {/* Date */}
            <SortByDate
              handleSort={handleSortDashboard}
              orderBy={searchObject.orderBy}
              iconColor='white'
            />

            {/* Aspect Ratio */}
            <InputGroupButtonDropdown
              addonType='append'
              isOpen={arToggle}
              toggle={() => setARToggle(!arToggle)}
            >
              <DropdownToggle nav caret>
                {searchObject.additionalOptions.aspect_ratio
                  ? `Aspect Ratio: ${searchObject.additionalOptions.aspect_ratio}`
                  : `Aspect Ratio`}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem header>Landscape</DropdownItem>
                {aspectRatios.landscape.map((ar, i) => (
                  <DropdownItem
                    key={i}
                    onClick={() =>
                      setSearchObject({
                        ...searchObject,
                        additionalOptions: {
                          ...searchObject.additionalOptions,
                          aspect_ratio: ar,
                        },
                      })
                    }
                  >
                    {ar}
                  </DropdownItem>
                ))}
                <DropdownItem header>Portrait</DropdownItem>
                {aspectRatios.portrait.map((ar, i) => (
                  <DropdownItem
                    key={i}
                    onClick={() =>
                      setSearchObject({
                        ...searchObject,
                        additionalOptions: {
                          ...searchObject.additionalOptions,
                          aspect_ratio: ar,
                        },
                      })
                    }
                  >
                    {ar}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </InputGroupButtonDropdown>

            {/* File Type */}
            <InputGroupButtonDropdown
              addonType='append'
              isOpen={typeDropdownOpen}
              toggle={toggleTypeDropdown}
            >
              <DropdownToggle nav caret>
                {searchObject.fileType
                  ? `File Type: ${searchObject.additionalOptions.contentType}`
                  : "File Type"}
              </DropdownToggle>
              <DropdownMenu
                value={searchObject.additionalOptions.contentType}
                id='fileType'
                name='fileType'
              >
                <DropdownItem
                  onClick={() =>
                    setSearchObject({
                      ...searchObject,
                      additionalOptions: {
                        ...searchObject.additionalOptions,
                        contentType: undefined,
                      },
                    })
                  }
                >
                  All
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    setSearchObject({
                      ...searchObject,
                      additionalOptions: {
                        ...searchObject.additionalOptions,
                        contentType: "video",
                      },
                    })
                  }
                >
                  Video
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    setSearchObject({
                      ...searchObject,
                      additionalOptions: {
                        ...searchObject.additionalOptions,
                        contentType: "image",
                      },
                    })
                  }
                >
                  Image
                </DropdownItem>
              </DropdownMenu>
            </InputGroupButtonDropdown>

            {/* Location */}
            <InputGroupButtonDropdown
              addonType='append'
              isOpen={locationDropdownOpen}
              toggle={toggleLocationDropdown}
            >
              <DropdownToggle nav caret>
                Location
              </DropdownToggle>
              <DropdownMenu
                value={searchObject.isLocationAdded}
                id='isLocationAdded'
                name='isLocationAdded'
              >
                <DropdownItem
                  onClick={() =>
                    setSearchObject({ ...searchObject, isLocationAdded: null })
                  }
                >
                  All
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    setSearchObject({ ...searchObject, isLocationAdded: true })
                  }
                >
                  Location Provided
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    setSearchObject({ ...searchObject, isLocationAdded: false })
                  }
                >
                  No Location
                </DropdownItem>
              </DropdownMenu>
            </InputGroupButtonDropdown>

            {/* Status */}
            <InputGroupButtonDropdown
              addonType='append'
              isOpen={statusDropdownOpen}
              toggle={toggleStatusDropdown}
            >
              <DropdownToggle nav caret>
                Status
              </DropdownToggle>
              <DropdownMenu
                value={searchObject.isConfirmed}
                id='isConfirmed'
                name='isConfirmed'
              >
                <DropdownItem
                  onClick={() =>
                    setSearchObject({ ...searchObject, isConfirmed: null })
                  }
                >
                  All
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    setSearchObject({ ...searchObject, isConfirmed: true })
                  }
                >
                  Confirmed
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    setSearchObject({ ...searchObject, isConfirmed: false })
                  }
                >
                  Not Confirmed
                </DropdownItem>
              </DropdownMenu>
            </InputGroupButtonDropdown>

            {/* Toggle Export */}
            {!stateUser.groups.includes("externalPartners") && (
              <Button
                id='toggle-export'
                onClick={() => {
                  setShowExport(true);
                }}
              >
                Export
              </Button>
            )}
          </InputGroup>
        </div>

        {/* Table */}
        <DashboardTable
          clips={clips}
          clipCount={clipCount}
          mediaViewerClip={mediaViewerClip}
          changePage={changePageDashboard}
          handleShowMediaViewer={handleShowMediaViewer}
          handleShowSubmissionDetails={handleShowSubmissionDetails}
          handleTagsUsedChange={handleTagsUsedChange}
          isLoading={isLoading}
          setClips={setClips}
          searchObject={searchObject}
          showSubmissionDetails={showSubmissionDetails}
        />
      </Row>
    </>
  );
};

export default Dashboard;
