import React, { useEffect } from 'react'
import { ReCaptcha, loadRecaptcha } from 'react-recaptcha-v3'

const ReCaptchaComponent = ({ sitekey, verifyReCaptcha }) => {
  const verifyCallback = ( recaptchaToken ) => {
    const body = JSON.stringify({ response: recaptchaToken })
    const params = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    }
    fetch('/api/token-validation', params)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          verifyReCaptcha(resp.success)
        } else {
          // recaptchaInstance.reset()
        }
      })
  }

  useEffect(() => {
    loadRecaptcha(sitekey)
  })

  return (
    <div>
      <ReCaptcha
        sitekey={ sitekey }
        verifyCallback={ verifyCallback }
      />
    </div>
  )
}
export default ReCaptchaComponent
