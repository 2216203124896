import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "fontawesome.macro";
import { copyToClipboard } from "../libs/utils";

/**
 * data param is to be an array of objects representing each table
 * @param {string} label
 * @param {Array} data
 */
export default function MicroTable({
  label,
  data,
  expandable,
  toggleState,
  handleToggleState,
  dropdown,
}) {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2>{label}</h2>
        {expandable && (
          <span className="icon-btn" onClick={handleToggleState}>
            {toggleState ? (
              <FontAwesomeIcon icon={fas("caret-up")} />
            ) : (
              <FontAwesomeIcon icon={fas("caret-down")} />
            )}
          </span>
        )}
        {dropdown && dropdown()}
      </div>
      <table>
        <tbody>
          {data.map((item, i) => (
            <tr key={i}>
              <td>{item.key}</td>
              <td
                className={
                  item.copyable && item.copyable === true
                    ? "copyable"
                    : item.editable
                    ? "editable"
                    : ""
                }
                onClick={
                  item.copyable && item.copyable === true
                    ? (event) => {
                        copyToClipboard(event, item.copyValue);
                      }
                    : null
                }
                onFocus={
                  item.editable && item.editable
                    ? (e) => {
                        if (
                          e.target.innerText === "No title has been created."
                        ) {
                          e.target.innerText = "";
                        }
                      }
                    : null
                }
                onBlur={
                  item.editable && item.editable
                    ? (e) => item.updateAction(e)
                    : null
                }
                contentEditable={item.editable}
                suppressContentEditableWarning={item.editable}
              >
                {item.value}
                {item.copyable && item.copyable ? (
                  <FontAwesomeIcon icon={fas("copy")} />
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
