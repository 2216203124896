import { Button } from "reactstrap";
import { fas } from "fontawesome.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetBrandFeed } from "../../hooks/BrandFeed/useGetBrandFeed";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";
import DataTable from "../../screens/DataTable";

export default function BrandFeed() {
  const { data, isLoading } = useGetBrandFeed();
  const history = useHistory();

  const handleViewXML = (id) => {
    if (window.location.hostname === "localhost") {
      window.alert(
        "XML won't render on localhost. Should work fine on dev and production."
      );
    } else {
      window.open(
        `${window.location.origin}/api/lightning/xml/${id}`,
        "_blank"
      );
    }
  };

  const handleViewBrandFeed = (id) => {
    history.push(`/admin/brand_feed/${id}`);
  };

  const renderActions = (id) => (
    <div className='actions'>
      <Button onClick={() => handleViewXML(id)}>
        <FontAwesomeIcon icon={fas("rss")} />
      </Button>
      <Button onClick={() => handleViewBrandFeed(id)}>
        <FontAwesomeIcon icon={fas("edit")} />
      </Button>
    </div>
  );

  const brandFeedData = useMemo(() => data, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "Campaign",
        accessor: "campaign",
        Cell: (props) => <div className="flex left">{props.row.original.name}</div>
      },
      {
        Header: "Sub Count",
        accessor: "count",
        Cell: (props) => <div className="flex left">{props.row.original.count.toLocaleString()}</div>
      },
      {
        Header: "Actions",
        accessor: "actions",
        className: "align-right",
        Cell: (props) => renderActions(props.row.original.id),
      },
    ],
    []
  );

  return (
    <>
      {!!data && (
        <div className='brand-feeds list'>
          <div className='header'>
            <div className='header-col'>
              <h1>Brand Feeds</h1>
              <span>{data?.length} Partners</span>
            </div>
          </div>
          <DataTable columns={columns} data={brandFeedData} />
        </div>
      )}
    </>
  );
}
