import React from 'react'
import { Label, Col, FormGroup, Input, FormText } from 'reactstrap'

const TextInput = ({
  smLabelSize, lgLabelSize, labelTitle, xsCol, smCol, lgCol, xlCol, type, name, textPlaceholder,
  id, required, styling, handleChange, value, formText, labelClass, valid, invalid,
}) => (
  <FormGroup>
    { labelTitle && (
      <Label
        for={ labelTitle }
        sm={ smLabelSize }
        lg={ lgLabelSize }
        className={ labelClass || 'strong' }
      >
        { labelTitle }
      </Label>
    ) }
    <Col
      xs={ xsCol }
      sm={ smCol }
      lg={ lgCol }
      xl={ xlCol }
      className={ styling }
    >
      <Input
        type={ type }
        name={ name }
        id={ id }
        placeholder={ textPlaceholder }
        required={ required }
        onChange={ handleChange }
        value={ value }
        valid={ valid }
        invalid={ invalid }
        autoComplete={ type == 'password' ? 'new-password' : 'on' }
      />
      <FormText>{ formText }</FormText>
    </Col>
  </FormGroup>
)

export default TextInput
