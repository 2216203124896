import { get, post } from "./common";
import { ROOT_URL } from "../../helpers/constants";

// By default, put Other (15) as category
export const createTag = (value) => {
  const url = `${ROOT_URL}tag`;
  return post(url, { value });
};

export const tagList = () => {
  // Get tags from back end
  const url = `${ROOT_URL}tag`;
  return get(url);
};

export const getTopTags = () => {
  // Get tags from back end
  const url = `${ROOT_URL}topTags`;
  return get(url);
};

export const getTagCount = (offset) => {
  const url = `${ROOT_URL}tagCount?offset=${offset}`;
  return get(url);
};

export const searchTags = (offset, searchValue) => {
  const url = `${ROOT_URL}searchTags?offset=${offset}&searchValue=${searchValue}`;
  return get(url);
};
