import { USER_UPDATE, USER_FETCH, USER_CLEAR } from "../actions/types";

export const INITIAL_STATE = {
  groups: [],
  awsId: "",
  isAdmin: false,
  isAuthenticated: false,
  id: "",
  viewedSubmissionIds: [],
};

export const UserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_FETCH:
      return action.payload && action.payload.awsId
        ? { ...state, ...action.payload }
        : state;
    case USER_UPDATE:
      // replaces anything in new payload, and leaves the rest as the old state
      return { ...state, ...action.payload };
    case USER_CLEAR:
      return action.payload;
    default:
      return state;
  }
};
