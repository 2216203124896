import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  Row, Col, Button, Container,
} from 'reactstrap';
import {getMyClips} from '../libs/request/user';
import Context from '../store/store';

const MyClips = () => {
  const context = useContext(Context);

  const {stateUser} = context;
  const [loading, setLoading] = useState(true);
  const [myClips, setMyClips] = useState([]);

  const {
    awsId,
  } = stateUser;

  useEffect(() => {
    if (awsId !== null && awsId !== '') {
      getMyClips(awsId).then((response) => {
        if (response.items) {
          setMyClips(response.items);
        }
        setLoading(false);
      });
    }
  }, [awsId]);

  return (
    <Row className="profile-screen my-clips">
      <Col xs={10} sm={10} md={10} lg={10} className="content">
        <Row className="pageTitle">
          <Col xs={12} sm={12} md={6} lg={6}>
            My Submitted Clips
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Link to="/profile">
              <Button
                alt="profile"
                className="transparent-white"
                title="Back to Profile"
              >
                Back to Profile
              </Button>
            </Link>
          </Col>
        </Row>
        {
          (awsId == null || awsId === '') && (
            <h2>You must be logged in to view your clips</h2>
          )
        }
        {
          (loading && (awsId !== null && awsId !== '')) && (
            <h2>Loading...</h2>
          )
        }
        <Container>
          <Row className="clip-list">
            {
              myClips.map((clip) => (
                <Col xs={6} sm={6} md={3} lg={3} xl={3} key={clip.id} className="clip-col">
                  <h2>
                    <span>Title: </span>
                    {clip.videoTitle || 'DTM Submission'}
                  </h2>
                  <img src={clip.thumbnail} alt="" />
                  <span>Description: </span>
                  {clip.description || clip.privateDescription || 'No description on record'}
                  <br />
                  {clip.contractFilename && (
                    <span>
                      <a href={clip.contractFilename} target="_blank" rel="noopener noreferrer">
                        Associated Contract
                      </a>
                    </span>
                  )}
                </Col>
              ))
            }
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default MyClips;
