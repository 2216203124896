import { AGGREGATORS } from "./constants";

// Determine if a user (argv1) is in a given group or groups (argv2).
const isUserInGroup = (stateUser, groups) => {
  // User is defined and has groups.
  if (stateUser && stateUser.groups) {
    // If argv[1] is a string then check to see if this one group belongs in the user's groups.
    if (typeof groups == "string") {
      return stateUser.groups.includes(groups);

      // If argv[1] is an array then iterate through the array.
    } else if (typeof groups == "object") {
      return groups.some((group) => stateUser.groups.includes(group));
    }

    // No user or user groups.
  } else {
    return false;
  }
};

// Returns true if a branded submission URL.
const isBrandedSubmissionURL = () => {
  // Determine if this page is a legacy submission page with /s/ in the URL.
  if (window.location.pathname.includes("/s/")) {
    // Extract the brand slug from the URL.
    const submissionSlug = window.location.pathname.replace(/\/s\//g, "");

    // Check to see if the extracted brand name matches a known brand name from constants.
    const brand = AGGREGATORS.filter(
      (brand) => brand.slugname === submissionSlug
    );

    // If a match is found then set the root class as the brand name.
    if (brand.length) {
      return true;
    }

    // Not a legacy submission URL.
  } else {
    // Check to see if the extracted brand name matches a known brand name from constants.
    const brand = AGGREGATORS.filter(
      (brand) => brand.brandedSubmissionURL === window.location.hostname
    );

    // If a match is found then set the root class as the brand name.
    if (brand.length) {
      return true;
    }
  }

  // No match found.
  return false;
};

// Returns true if a branded submission URL.
const fetchBrandFromURL = () => {
  // Determine if this page is a legacy submission page with /s/ in the URL.
  if (window.location.pathname.includes("/s/")) {
    // Extract the brand slug from the URL.
    const submissionSlug = window.location.pathname.replace(/\/s\//g, "");

    // Check to see if the extracted brand name matches a known brand name from constants.
    const brand = AGGREGATORS.filter(
      (brand) => brand.slugname === submissionSlug
    );

    // If a match is found then set the root class as the brand name.
    if (brand.length) {
      return brand[0];
    } else {
      return AGGREGATORS.filter((brand) => brand.slugname === "doingthings")[0];
    }

    // Not a legacy submission URL.
  } else {
    // Check to see if the extracted brand name matches a known brand name from constants.
    const brand = AGGREGATORS.filter(
      (brand) => brand.brandedSubmissionURL === window.location.hostname
    );

    // If a match is found then set the root class as the brand name.
    if (brand.length) {
      return brand[0];
    } else {
      return AGGREGATORS.filter((brand) => brand.slugname === "doingthings")[0];
    }
  }
};

// Return a brand object from a slugname.
const fetchBrandFromSlugname = (slugname) => {
  // Check to see if the extracted brand name matches a known brand name from constants.
  const brand = AGGREGATORS.filter(
    (brand) => brand.slugname == slugname || brand.val == slugname
  );

  // If a match is found then set the root class as the brand name.
  if (brand.length) {
    return brand[0];
  }

  // Return the Doing Things Media object as the fallback.
  return AGGREGATORS.filter((brand) => brand.slugname === "doingthings")[0];
};

// Return a logo based on the brand that is passed in.
const setBrandLogo = (brandName = "") => {
  // Find the aggregator with this val or slugname and return the logo.
  // Prior to 5/19/22, aggregators were assigned to a clip via the "val" key in AGGREGATORS.
  // After 5/19/22, aggregators are assigned to a clip via the "slugname" key in AGGREGATORS.
  const brandObject = AGGREGATORS.filter(
    (brand) => brand.slugname == brandName || brand.val == brandName
  );
  if (brandObject.length) {
    return brandObject[0].icon;
  } else {
    return AGGREGATORS.filter((brand) => brand.slugname === "doingthings")[0]
      .icon;
  }
};

// Gets aggregator from query params - removes classes for when url changes -
// adds class to root div for styling purposes - will be changed to theme hook
const loadStyles = (brandSlugname = "") => {
  // Access the root element and remove any classes it might have.
  const root = document.getElementById("root");
  root.removeAttribute("class");

  // If a slugname was passed into this function then use it. Otherwise fetch from the URL.
  if (brandSlugname == "" || brandSlugname == null) {
    // Load the active brand.
    const activeBrand = fetchBrandFromURL();

    // Load the styles for this brand by adding a class to the root.
    root.classList.add(activeBrand.slugname);
  } else {
    // Load the styles for this brand by adding a class to the root.
    root.classList.add(brandSlugname);
  }
};

// Determine which navigation we need to load depending on the URL.
const determineNavigation = () => {
  const topNavigationSlugs = ["/admin/", "/dashboard/", "/partners/"];
  const sideNavigationSlugs = [];
  if (
    topNavigationSlugs.some((slug) => window.location.pathname.includes(slug))
  ) {
    return "top";
  } else if (
    sideNavigationSlugs.some((slug) => window.location.pathname.includes(slug))
  ) {
    return "sidebar";
  } else {
    return "default";
  }
};

// Parse the UUID from the URL.
const stripVideoUUID = (clipAttachment) => {
  const uuid = clipAttachment.split("/");
  return `https://cdn.doingthings.media/${uuid[uuid.length - 1]}`;
};

export {
  determineNavigation,
  fetchBrandFromSlugname,
  fetchBrandFromURL,
  isBrandedSubmissionURL,
  isUserInGroup,
  loadStyles,
  setBrandLogo,
  stripVideoUUID,
};
