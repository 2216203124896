import React, { useState } from 'react'
import {
  FormGroup, Input, Label, Dropdown, DropdownMenu,
  DropdownItem, DropdownToggle,
} from 'reactstrap'

const AggregatorCheckbox = ({
  selectedAggregators, handleCheckChange, clearCheckboxes, selectAllCheckboxes,
}) => {

  const [ dropdownOpen, setDropdownOpen ] = useState(false)
  const toggle = () => { setDropdownOpen(!dropdownOpen) }

  return (
    <Dropdown nav isOpen={ dropdownOpen } toggle={ toggle }>
      <DropdownToggle nav caret>Brand</DropdownToggle>
      <DropdownMenu>
        { selectedAggregators.map((agg, index) => (
          <DropdownItem className="aggregatorList" key={ agg.name } onClick={ () => handleCheckChange(index, 'selectedAggregators') }>
            <FormGroup check>
              <Label check>
                <span className={ `checkbox ${ agg.selected ? 'checked' : '' }` } />
                <Input
                  type="checkbox"
                  key={ agg.name }
                  checked={ agg.selected }
                  readOnly
                  style={ {
                    backgroundImage: `url('${ agg.icon }')`,
                  } }
                />
                {agg.icon && (
                  <img src={ agg.icon } width="30" alt={ agg.name } title={ agg.name } />
                )}
                {' '}
                { agg.name }
              </Label>
            </FormGroup>
          </DropdownItem>
        ))}
        <DropdownItem divider />
        <DropdownItem onClick={ () => clearCheckboxes('selectedAggregators') }>Clear</DropdownItem>
        <DropdownItem onClick={ () => selectAllCheckboxes('selectedAggregators') }>Select All</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default AggregatorCheckbox
