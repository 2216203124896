import { Route, Switch, Redirect } from "react-router-dom";
import AdminStatsScreen from "./screens/AdminStats";
import AdminUserLogsScreen from "./screens/AdminUserLogsScreen";
import AdminUsers from "./screens/AdminUsers";
import AgreementViewer from "./screens/agreement/AgreementViewer";
import AppliedRoute from "./AppliedRoute";
import BrandFeed from "./components/BrandFeed/BrandFeed";
import BrandFeedView from "./components/BrandFeed/BrandFeedView";
import CampaignList from "./components/mrss/List";
import CampaignView from "./components/mrss/campaign";
import ConfirmOwnership from "./screens/ConfirmOwnership";
import Dashboard from "./screens/AdminDashboard";
import ForgotPassword from "./screens/ForgotPassword";
import LicenseIndex from "./screens/LicenseIndex";
import LicenseIndividual from "./screens/LicenseIndividual";
import LinkGen from "./screens/LinkGenerator";
import Login from "./screens/Login";
import MediaIndividual from "./screens/MediaIndividual";
import MyClips from "./screens/MyClips";
import NotFound from "./screens/PageNotFound";
import PartnerLibrary from "./components/ExternalPartnerLibrary/PartnerLibrary";
import PartnerLibraryIndex from "./components/ExternalPartnerLibrary/PartnerLibraryIndex";
import PartnerLibrarySingle from "./components/ExternalPartnerLibrary/PartnerLibrarySingle";
import Profile from "./screens/Profile";
import React from "react";
import RevshareAgreement from "./screens/RevshareAgreement";
import Submit from "./screens/Submit";
import TagMetrics from "./screens/TagMetrics";

export default ({ globalProps, stateUser }) => {
  return (
    <Switch>
      {/* Home */}
      <AppliedRoute path="/" exact component={Submit} props={globalProps} />

      {/* Submissionn */}
      <Redirect strict from="/submit/" to="/" />
      <AppliedRoute path="/s/:page/" component={Submit} props={globalProps} />

      {/* Auth */}
      <AppliedRoute path="/login/" component={Login} props={globalProps} />
      <AppliedRoute
        path="/forgot-password/"
        component={ForgotPassword}
        props={globalProps}
      />

      {/* Profile */}
      <AppliedRoute path="/profile/" component={Profile} props={globalProps} />
      <AppliedRoute
        path="/profile/clips/"
        component={MyClips}
        props={globalProps}
      />

      {/* Admin */}
      <AppliedRoute
        path="/dashboard/"
        component={Dashboard}
        props={globalProps}
      />
      <AppliedRoute
        path="/admin/stats/"
        exact
        component={AdminStatsScreen}
        props={globalProps}
      />
      <AppliedRoute
        path="/admin/logs/"
        exact
        component={AdminUserLogsScreen}
        props={globalProps}
      />
      <AppliedRoute
        path="/admin/users/"
        exact
        component={AdminUsers}
        props={globalProps}
      />
      <AppliedRoute
        path="/admin/tags/"
        exact
        component={TagMetrics}
        props={globalProps}
      />

      {/* mRSS Feed & Campaigns */}
      <AppliedRoute
        path="/admin/mrss/"
        exact
        component={CampaignList}
        props={globalProps}
      />
      <AppliedRoute
        path="/admin/mrss/campaign/view/:campaignId"
        component={CampaignView}
        props={globalProps}
      />

      {/* Legal */}
      <AppliedRoute path="/revshare-agreement" component={RevshareAgreement} />
      <AppliedRoute path="/link-gen" component={LinkGen} props={globalProps} />

      {/* Licensing */}
      <AppliedRoute
        exact
        path="/license/"
        component={LicenseIndex}
        props={globalProps}
      />
      <AppliedRoute
        exact
        path="/licence/"
        component={LicenseIndex}
        props={globalProps}
      />
      <AppliedRoute
        path="/license/:id/"
        component={LicenseIndividual}
        props={globalProps}
      />
      <AppliedRoute
        path="/licence/:id/"
        component={LicenseIndividual}
        props={globalProps}
      />

      {/* Media Viewer */}
      <AppliedRoute
        path="/m/:id/"
        exact
        component={MediaIndividual}
        props={globalProps}
      />
      <AppliedRoute
        path="/media/:id/"
        component={MediaIndividual}
        props={globalProps}
      />

      {/* Confirm Ownership */}
      <AppliedRoute
        path="/confirm/"
        component={ConfirmOwnership}
        props={globalProps}
      />

      {/* Partner Library */}
      <AppliedRoute
        path="/admin/partners/"
        exact
        component={PartnerLibraryIndex}
        props={globalProps}
      />
      <AppliedRoute
        path="/admin/partners/library/:userId"
        exact
        component={PartnerLibrarySingle}
        props={globalProps}
      />

      <AppliedRoute
        path="/partners/library/"
        exact
        component={PartnerLibrary}
        props={globalProps}
      />

      {/* Brand Feeds */}
      <AppliedRoute
        path="/admin/brand_feed"
        exact
        component={BrandFeed}
        props={globalProps}
      />
      <AppliedRoute
        path="/admin/brand_feed/:id"
        exact
        component={BrandFeedView}
        props={globalProps}
      />

      {/* Agreement */}
      <AppliedRoute
        path="/agreement/:id/"
        exact
        component={AgreementViewer}
        props={globalProps}
      />

      {/* 404 */}
      <Route component={NotFound} />
    </Switch>
  );
};
