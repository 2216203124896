import { Button } from "reactstrap";
import { ExternalPartnerContext } from "../../store/ExternalPartnerStore";
import { fas } from "fontawesome.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLibraryAdmin } from "../../libs/request/partnerLibrary";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import DataTable from "../../screens/DataTable";
import Loader from "../Loader";

export default function PartnerLibraryIndex() {
  const [loading, setLoading] = useState(true);
  const [partnerData, setPartnerData] = useState();
  const { externalPartnerDispatch } = useContext(ExternalPartnerContext);
  const history = useHistory();
  const loadData = () => {
    getLibraryAdmin().then((response) => {
      setPartnerData(response);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleViewLibrary = ({ id, email }) => {
    externalPartnerDispatch({
      type: "ADD",
      payload: { id: id, email: email },
    });
    history.push(`/admin/partners/library/${id}`);
  };

  const renderActions = (user, submissionIdsLength) => (
    <div className='actions'>
      {submissionIdsLength > 0 && (
        <Button className='simple' onClick={() => handleViewLibrary(user)}>
          <FontAwesomeIcon icon={fas("edit")} />
        </Button>
      )}
    </div>
  );

  const data = useMemo(() => partnerData, [partnerData]);

  const columns = useMemo(
    () => [
      {
        Header: "Partner",
        accessor: "user",
        Cell: (props) => (
          <div className='flex left'>{props.row.original.user.email}</div>
        ),
        width: 300,
      },
      {
        Header: () => <div className='flex'>Items in Library</div>,
        accessor: "submissions",
        width: 75,
        Cell: (props) => (
          <div className='flex'>{props.row.original.submissionIds.length}</div>
        ),
      },
      {
        Header: () => <div className='flex right'>Actions</div>,
        accessor: "actions",
        width: 75,
        Cell: (props) =>
          renderActions(
            props.row.original.user,
            props.row.original.submissionIds.length
          ),
      },
    ],
    [partnerData]
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className='partner-dashboard list'>
          <div className='header'>
            <div className='header-col'>
              <h1>External Partners</h1>
              <span>{data.length} Partners</span>
            </div>
          </div>
          <DataTable data={data} columns={columns} />
        </div>
      )}
    </>
  );

};