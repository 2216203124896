import { createContext, useReducer } from 'react';
import agreementViewerReducer from '../reducers/AgreementViewerReducer';

const initalState = {
  loading: true,
  submission: {},
};
export const AgreementViewerContext = createContext();

export function AgreementViewerProvider({ children }) {
  const [state, dispatch] = useReducer(agreementViewerReducer, initalState);

  return (
    <AgreementViewerContext.Provider value={{ state, dispatch }}>
      {children}
    </AgreementViewerContext.Provider>
  );
}
