import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import { userUpdate } from "../actions";
import { TextInput, CustomButton } from "../components/form";
import { updateOneThing, uploadProfilePhoto } from "../libs/request";
import Context from "../store/store";

const Profile = () => {
  const context = useContext(Context);
  const { stateUser, dispatchUser } = context;
  const {
    phone, email, firstName, lastName, instagramUser,
    paypalEmail, altEmail, profilePhoto, awsId,
  } = stateUser;
  const [newPhoto, setNewPhoto] = useState(null);
  const [objectUrl, setObjectUrl] = useState(null);

  const saveOneField = (event, field) => {
    event.preventDefault();
    const { value } = event.target;
    const reduxObject = {};
    reduxObject[field] = value;
    dispatchUser(userUpdate(reduxObject));
    updateOneThing("user", awsId, { field, value })
      .then(() => {
      });
  };

  const handleFileUploadChange = (event) => {
    const tempFile = event.currentTarget.files[0];
    if (tempFile && tempFile.type.includes("image/")) {
      setNewPhoto(tempFile);
      setObjectUrl(URL.createObjectURL(tempFile));
    } else {
      setNewPhoto(null);
      setObjectUrl(null);
    }
  };

  const handleUpload = (event) => {
    event.stopPropagation();
    uploadProfilePhoto(newPhoto, awsId).then((response) => {
      if (response.profilePhoto) {
        setNewPhoto(null);
        setObjectUrl(null);
        dispatchUser(userUpdate({ profilePhoto: response.profilePhoto }));
      }
    });
  };

  return (
    <Row className="profile-screen">
      <Col xs={12} sm={12} md={12} lg={12} className="content">
        <Row className="pageTitle centered-row desktop">
          Welcome back&nbsp;
          {firstName}
          !
        </Row>
        <Row className="profile-title">
          Edit Profile
        </Row>
        <Row className="input-container">
          <Col xs={12} sm={6} md={6} lg={6}>
            <Row className="inputrow">
              <TextInput
                labelTitle="Primary Email"
                disabled
                smCol={12}
                lgCol={8}
                id="email"
                name="email"
                value={email || ""}
              />
              <div className="helper">
                Primary email cannot be changed at this time. This is your username to login.
              </div>
            </Row>
            <Row className="inputrow">
              <TextInput
                labelTitle="Alternate Email (optional)"
                smCol={12}
                lgCol={6}
                id="altEmail"
                name="altEmail"
                value={altEmail}
                handleChange={(event) => saveOneField(event, "altEmail")}
                textPlaceholder="myotheremail@gmail.com"
              />
            </Row>
            <Row className="inputrow">
              <TextInput
                xsCol={12}
                lgCol={8}
                labelTitle="Paypal Email"
                id="paypalEmail"
                name="paypalEmail"
                value={paypalEmail}
                handleChange={(event) => saveOneField(event, "paypalEmail")}
                textPlaceholder="myemail@gmail.com"
              />
            </Row>
            <Row className="photo-input-row">
              <label className="profilePhotoInput" style={{ backgroundImage: `url(${(objectUrl || `"${profilePhoto}"`)})` }}>
                <input type="file" name="profilePhoto" accept="image/*" onChange={handleFileUploadChange} />
                {objectUrl && (
                  <Button onClick={handleUpload} color="primary">Save</Button>
                )}
                {!(objectUrl || profilePhoto) && "Upload photo"}
              </label>
            </Row>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Row className="inputrow">
              <TextInput
                labelTitle="First Name *"
                htmlFor="first-name"
                id="first-name"
                name="firstName"
                value={firstName}
                type="text"
                feedbackText="first name"
                handleChange={(event) => saveOneField(event, "firstName")}
                textPlaceholder="First Name"
                xsCol={10}
                lgCol={8}
              />
            </Row>
            <Row className="inputrow">
              <TextInput
                labelTitle="Last Name *"
                htmlFor="last-name"
                id="last-name"
                name="lastName"
                value={lastName}
                textPlaceholder="Last Name"
                handleChange={(event) => saveOneField(event, "lastName")}
                type="text"
                feedbackText="last name"
                smCol={10}
                lgCol={8}
              />
            </Row>
            <Row className="inputrow">
              <TextInput
                smCol={10}
                lgCol={6}
                labelTitle="Phone Number"
                value={phone}
                textPlaceholder="+4444444444"
                handleChange={(event) => saveOneField(event, "phone")}
                id="phone"
                name="phone"
              />
            </Row>
            <Row className="inputrow">
              <TextInput
                smCol={10}
                lgCol={6}
                labelTitle="Instagram Username"
                htmlFor="instagramUser"
                id="instagramUser"
                name="instagramUser"
                value={instagramUser}
                textPlaceholder="@myinstagramname"
                handleChange={(event) => saveOneField(event, "instagramUser")}
              />
            </Row>
            <Row className="submit-row">
              <a href="/forgot-password">Click here to reset your password.</a>
            </Row>
          </Col>
        </Row>
        <Row className="submit-row">
          <Col xs={12} sm={12} md={6} lg={6}>
            <Link to="/profile/clips">
              <Button
                alt="view clips"
                className="transparent-white"
                title="View My Clips"
              >
                View My Clips
              </Button>
              <br />
              <br />
            </Link>
            <a href="https://doingthingsmedia.com/agreement/" alt="agreement">
              <Button
                alt="agreement"
                className="transparent-white"
                title="View Agreement"
              >
                View Agreement
              </Button>
            </a>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <CustomButton
              navTo="/"
              alt="home"
              styling="dtm-button"
              title="Back to Doing Things"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Profile;
