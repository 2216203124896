import axios from "axios";
import { useState } from "react";
import { ROOT_URL } from "../../helpers/constants";

export const useMutateBrandFeed = (feedId) => {
  const [error, setError] = useState();
  const removeFromFeed = async (subId) => {
    return await axios
      .get(`${ROOT_URL}brand_feed/remove?feedId=${feedId}&subId=${subId}`)
      .catch((err) => setError(err));
  };

  return { removeFromFeed, error };
};
