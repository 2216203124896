import { get } from "./common";
import { ROOT_URL } from "../../helpers/constants";

export const aggregatorSubmissions = () => {
  const url = `${ROOT_URL}statistics/aggregatorSubmissions?${Date.now()}`;
  return get(url);
};

export const datedSubmissions = () => {
  const url = `${ROOT_URL}statistics/datedSubmissions?${Date.now()}`;
  return get(url);
};

export const featuredSubmissions = () => {
  const url = `${ROOT_URL}statistics/featuredSubmissions?${Date.now()}`;
  return get(url);
};

export const markedSubmissions = () => {
  const url = `${ROOT_URL}statistics/markedSubmissions?${Date.now()}`;
  return get(url);
};

export const userSubmissions = () => {
  const url = `${ROOT_URL}statistics/userSubmissions?${Date.now()}`;
  return get(url);
};

export const submissionMetrics = () => {
  const url = `${ROOT_URL}statistics/reviewedSubmissions`;
  return get(url);
};

export const platformMetrics = () => {
  const url = `${ROOT_URL}statistics/platform`;
  return get(url);
};
