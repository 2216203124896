import { React, useContext, useState } from "react";
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form } from "reactstrap";
import { parseQS } from "../../libs/utils";
import { Checkbox, TextInput, CustomButton } from "../form";
import Context from "../../store/store";
import { isBrandedSubmissionURL } from "../../helpers/global";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import FileUpload from "../form/FileUpload";
import { AGGREGATORS } from "../../helpers/constants";
import { fas } from "fontawesome.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VideoDetails = ({
  authenticateUser,
  clipFile,
  didYouRecord,
  fileUrl,
  groupPermissionGiven,
  handleChangeCategory,
  handleFieldUpdate,
  handleFileUploadChange,
  handleFinalSubmit,
  handleRevShareCheck,
  handleSelectSuggest,
  isBrandManager,
  isLoading,
  location,
  noExclusive,
  notValidFormat,
  overEighteen,
  privateDescription,
  recaptcha,
  revShareAccept,
  reviewedRights,
  reviewedTermsPrivacy,
  search,
  stayUpdated,
  userCredit,
  videoTooLong,
}) => {
  const context = useContext(Context);
  const { activeBrand, setActiveBrand, isMobile } = context;
  const [dropdownToggle, setDropdownToggle] = useState();
  const aggregators = AGGREGATORS;
  const queryParams = parseQS(window.location.search);

  const handleAuthDocUpload = (e) => {
    handleFileUploadChange(e, "authDoc");
  };

  return (
    <>

      {/* Banner for Brand Managers */}
      {!isBrandedSubmissionURL() && isBrandManager && (
        <div className="banner">
          <h4>
            <FontAwesomeIcon icon={fas("exclamation-triangle")} />
            <span>You are uploading content as a brand manager.</span>
          </h4>
        </div>
      )}

      <Form onSubmit={handleFinalSubmit} className={`row video-details ${isBrandManager ? "brandManager" : ""}`}>
        <Col className="left-col">

          {!isBrandedSubmissionURL() && isBrandManager ? (
            <div className="inputs-container">
              <div className="upload-wrapper auth">
                <h2>Authorization Confirmation</h2>
                <h5>Upload proof of authorization.</h5>
                <FileUpload
                  id="auth-upload"
                  onChange={handleAuthDocUpload}
                  label="Browse Files"
                />
              </div>
              <div className="upload-wrapper media">
                <h2>Upload Media</h2>
                <h5>Upload the file you'd like to submit.</h5>
                <FileUpload
                  id="media-upload"
                  onChange={handleFileUploadChange}
                  label="Browse Files"
                />
              </div>
            </div>
          ) : (
            <>
              <div className="brand-selection">
                <h4>Brand</h4>
                <Dropdown
                  toggle={() => setDropdownToggle(!dropdownToggle)} isOpen={dropdownToggle}
                >
                  <DropdownToggle caret>
                    <span className="brand-toggle">
                      {activeBrand ? (
                        <img src={activeBrand.icon != "" ? activeBrand.icon : null} />
                      ) : ""}
                      <span className="brand-info">
                        <span className="brand-info__name">{activeBrand ? activeBrand.name : "Select a brand"}</span>
                        <span className="brand-info__insta">{activeBrand ? `@${activeBrand.socials.instagramHandle}` : ""}</span>
                      </span>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu style={{ overflow: "scroll", maxHeight: "50vh" }}>
                    {aggregators.filter((agg) => agg.submittable).map((agg, i) => (
                      <DropdownItem key={i} onClick={() => setActiveBrand(agg)}>
                        <img src={agg.icon != "" ? agg.icon : null} />
                        {agg.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>

                {activeBrand.slugname === "ordinarypeople" && (
                  <div className="brand-disclaimer">
                    <h4><strong>To maximize your chances of having your photo featured, please follow these guidelines:</strong></h4>
                    <ul>
                      <li>Submit a front-facing headshot.</li>
                      <li>Use a neutral background (a plain portrait studio-style background is ideal).</li>
                      <li>Make eye contact with the camera.</li>
                      <li>Formal or professional attire is preferred.</li>
                    </ul>
                  </div>
                )}

              </div>
            </>
          )}

          {/* Brand Manager: Authorization Confirmation */}
          {/* {isBrandManager && (
            <div className="form-group">
              <label htmlFor="auth-upload">Authorization Confirmation</label>
              <FileUpload
                id="auth-upload"
                onChange={handleAuthDocUpload}
                label={isMobile ? 'Tap here to upload proof of authorization.' : 'Click here or drag and drop to upload proof of authorization.'}
              />
            </div>
          )} */}

          {/* File Upload */}
          {!isBrandManager && (
            <div className="form-group">
              <label htmlFor="media-upload">Upload Media</label>
              <FileUpload
                id="media-upload"
                onChange={handleFileUploadChange}
                label={isMobile ? "Tap here to upload content." : "Click here or drag and drop to upload content."}
              />
            </div>
          )}

          {videoTooLong && (
            <p className="error">
              Please upload videos that are five minutes or less.
            </p>
          )}
          {notValidFormat && (
            <p className="error">Please upload a video or image.</p>
          )}

          <TextInput
            labelTitle="Media Description"
            type="textarea"
            value={privateDescription}
            handleChange={handleFieldUpdate}
            id="privateDescription"
            name="privateDescription"
            styling="essay-input"
          />

          <ReactGoogleMapLoader
            params={{
              key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
              libraries: "places,geocode",
            }}
            render={(googleMaps) =>
              googleMaps && (
                <ReactGooglePlacesSuggest
                  autocompletionRequest={{ input: search }}
                  googleMaps={googleMaps}
                  onSelectSuggest={handleSelectSuggest}
                >
                  <TextInput
                    labelTitle="Location"
                    htmlFor="location"
                    id="location"
                    name="location"
                    value={location}
                    handleChange={handleFieldUpdate}
                  />
                </ReactGooglePlacesSuggest>
              )
            }
          />

          <TextInput
            labelTitle="Instagram Username to Credit"
            htmlFor="userCredit"
            id="userCredit"
            name="userCredit"
            value={userCredit}
            handleChange={handleFieldUpdate}
            labelClass="smallerLabel"
          />

          <TextInput
            labelTitle="Link To Media on Social Media"
            htmlFor="file-url"
            id="fileUrl"
            name="fileUrl"
            value={fileUrl}
            handleChange={handleFieldUpdate}
            type="url"
            feedbackText="Enter a valid URL"
          />

          {/* If a BrandManager then load the submit button in this column. */}
          {!isBrandedSubmissionURL() && isBrandManager && (
            <CustomButton
              styling="primary submit-btn"
              title="Submit"
              handleClick={(e) => handleFinalSubmit(e, "authDoc")}
              disableMe={isLoading || !recaptcha}
            />
          )}
        </Col>

        {/* Only show the checkboxex if not a brand manager. */}
        {(isBrandedSubmissionURL() || !isBrandManager) && (
          <Col className="right-col">
            <div className="consent-wrapper">
              <Col className="consent-section">
                <h3>I confirm that:</h3>
                <Checkbox
                  required
                  id="reviewedRights"
                  name="reviewedRights"
                  checked={!!reviewedRights}
                  handleChange={handleFieldUpdate}
                  text={
                    <div>
                      I have reviewed and agreed to the{" "}
                      <a
                        href="https://doingthingsmedia.com/legal/agreement/"
                        target="_blank" rel="noreferrer"
                      >
                        Licensing Agreement
                      </a>{" "}
                      and understand that I am transferring exclusive rights to the
                      submitted content.
                    </div>
                  }
                  faIcon="square"
                  checkedIcon="check"
                />
                <Checkbox
                  required
                  id="reviewedTermsPrivacy"
                  name="reviewedTermsPrivacy"
                  checked={!!reviewedTermsPrivacy}
                  handleChange={handleFieldUpdate}
                  text={
                    <div>
                      I have reviewed and agreed to the{" "}
                      <a
                        href="https://doingthingsmedia.com/legal/terms/"
                        target="_blank" rel="noreferrer"
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://doingthingsmedia.com/legal/privacy/"
                        target="_blank" rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </div>
                  }
                  faIcon="square"
                  checkedIcon="check"
                  styles="checkbox-input"
                />
                <Checkbox
                  required
                  id="didYouRecord"
                  name="didYouRecord"
                  checked={!!didYouRecord}
                  handleChange={handleFieldUpdate}
                  text="I was holding the camera when creating this video."
                  faIcon="square"
                  checkedIcon="check"
                  styles="checkbox-input"
                />
                <Checkbox
                  required
                  id="over-eighteen"
                  name="overEighteen"
                  checked={!!overEighteen}
                  handleChange={handleFieldUpdate}
                  text="I am over the age of 18."
                  faIcon="square"
                  checkedIcon="check"
                  styles="checkbox-input"
                />
                <Checkbox
                  required
                  id="noExclusive"
                  name="noExclusive"
                  checked={!!noExclusive}
                  handleChange={handleFieldUpdate}
                  faIcon="square"
                  checkedIcon="check"
                  styles="checkbox-input"
                  text="I have not signed an agreement with anyone else or any other websites regarding the submitted content."
                />
                <Checkbox
                  required
                  id="groupPermissionGiven"
                  name="groupPermissionGiven"
                  checked={!!groupPermissionGiven}
                  handleChange={handleFieldUpdate}
                  faIcon="square"
                  checkedIcon="check"
                  styles="checkbox-input"
                  text={
                    <div>
                      I confirm that I have permission from every person in the video to post and they are aware of {' '}
                      <a
                        href="https://doingthingsmedia.com/legal/terms/"
                        target="_blank" rel="noreferrer"
                      >
                        Terms and Conditions
                      </a>{" "}
                      that come along with my submission.
                    </div>}
                />
                {queryParams.rsID && (
                  <Checkbox
                    required
                    id="revShareAccept"
                    name="revShareAccept"
                    checked={revShareAccept}
                    handleChange={handleRevShareCheck}
                    text="I understand this submission is a 50/50 rev share split per the License Agreement *"
                    faIcon="square"
                    checkedIcon="check"
                    styles="checkbox-input"
                  />
                )}
              </Col>
              <Col className="consent-section">
                <h3>Keep me in the loop!</h3>
                <Checkbox
                  id="stay-updated"
                  name="stayUpdated"
                  checked={!!stayUpdated}
                  handleChange={handleFieldUpdate}
                  text="I want to keep up with what's happening at Doing Things Media."
                  styles="checkbox-input"
                  faIcon="square"
                  checkedIcon="check"
                />
              </Col>
            </div>
            <CustomButton
              styling="primary submit-btn"
              title="Submit"
              handleClick={handleFinalSubmit}
              disableMe={isLoading || !recaptcha}
            />
          </Col>
        )}
      </Form>

    </>
  );
};

export default VideoDetails;
