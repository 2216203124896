import moment from "moment";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../components";
import { getSingleClip } from "../../libs/request";
import { SET_DATA } from "../../reducers/AgreementViewerReducer";
import { AgreementViewerContext } from "../../store/agreementViewerStore";

export default function AgreementViewer() {
  const { state, dispatch } = useContext(AgreementViewerContext);
  const { id } = useParams();

  const loadData = async () => {
    await getSingleClip(id).then((submission) => {
      dispatch({
        type: SET_DATA,
        payload: submission,
      });
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  // Check if redact=1 exists in the URL and perform redaction if needed.
  useEffect(() => {

    // Function to execute redaction logic.
    const redactElements = () => {
        const searchParams = new URLSearchParams(window.location.search);
        console.log(searchParams.get('redact'));
        if (searchParams.get('redact') == 1) {
            const elementsToRedact = document.querySelectorAll('[data-redact]');
            elementsToRedact.forEach((element) => {
                element.textContent = element.textContent.replace(/[^ ]/g, '█');
            });
        }
    };

    // Check if the specific elements are available in the DOM.
    const checkForElements = () => {
        if (document.querySelectorAll('[data-redact]').length > 0) {
            redactElements();
        } else {
            setTimeout(checkForElements, 100);
        }
    };

    checkForElements();

  }, [window.location.search]);

  // Signature ID
  const generateSignatureId = (str1, str2) =>
    (str1.length && `${str1[0]}${generateSignatureId(str2, str1.slice(1))}`) ||
    str2;

  return (
    <>
      {state.loading ? (
        <Loader />
      ) : (
        <>
          <div id="agreement">
            <div className="container">
              <div className="wrapper">
                <h1>ASSIGNMENT AND TRANSFER AGREEMENT</h1>

                <p>
                  This Assignment and Transfer Agreement ("Agreement") is made
                  as of{" "}
                  {moment(state.submission.createdAt).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}{" "}
                  GMT ("Effective Date") between Doing Things Media, LLC.
                  ("Assignee") and{" "}
                  <span className="proper">
                    {state.submission.firstName} {state.submission.lastName}
                  </span>{" "}
                  ("Assignor"). In consideration of the mutual promises and
                  covenants contained in this Agreement, the parties agree as
                  follows:
                </p>

                <p>
                  <strong>1. Definitions</strong>
                </p>
                <p data-redact>
                  1.1 "Assigned Property" means the property submitted by You to
                  Assignee, via digital channels or otherwise, and all
                  Intellectual Property and Intellectual Property Rights forming
                  a part of, embodied, in or necessary for use of the property.
                </p>
                <p data-redact>
                  1.2 "Intellectual Property" means all technology and
                  intellectual property, regardless of form, including without
                  limitation: published and unpublished works of authorship,
                  including without limitation audiovisual works, collective
                  works, computer programs, compilations, databases, derivative
                  works, literary works, maskworks, and sound recordings ("Works
                  of Authorship"); inventions and discoveries, including without
                  limitation articles of manufacture, business methods,
                  compositions of matter, improvements, machines, methods, and
                  processes and new uses for any of the preceding items
                  ("Inventions"); words, names, symbols, devices, designs, and
                  other designations, and combinations of the preceding items,
                  used to identify or distinguish a business, good, group,
                  product, or service or to indicate a form of certification,
                  including without limitation logos, product designs, and
                  product features ("Trademarks"); and information that is not
                  generally known or readily ascertainable through proper means,
                  whether tangible or intangible, including without limitation
                  algorithms, customer lists, ideas, designs, formulas,
                  know-how, methods, processes, programs, prototypes, systems,
                  and techniques ("Confidential Information").
                </p>
                <p data-redact>
                  1.3 "Intellectual Property Rights" means all rights in,
                  arising out of, or associated with Intellectual Property in
                  any jurisdiction, including without limitation: rights in,
                  arising out of, or associated with Works of Authorship,
                  including without limitation rights in maskworks and databases
                  and rights granted under the Copyright Act ("Copyrights");
                  rights in, arising out of, or associated with Inventions,
                  including without limitation rights granted under the Patent
                  Act ("Patent Rights"); rights in, arising out of, or
                  associated with Trademarks, including without limitation
                  rights granted under the Lanham Act ("Trademark Rights");
                  rights in, arising out of, or associated with Confidential
                  Information, including without limitation rights granted under
                  the Uniform Trade Secrets Act ("Trade Secret Rights"); rights
                  in, arising out of, or associated with a person's name, voice,
                  signature, photograph, or likeness, including without
                  limitation rights of personality, privacy, and publicity
                  ("Personality Rights"); rights of attribution and integrity
                  and other moral rights of an author ("Moral Rights"); and
                  rights in, arising out of, or associated with domain names
                  ("Domain Name Rights").
                </p>

                <p>
                  <span data-redact><strong>2. Assignment.</strong> Assignor hereby perpetually,
                  irrevocably, and unconditionally assigns, transfers, and
                  conveys to Assignee and its successors and assigns, all of
                  Assignor's right, title, and interest in and to the Assigned
                  Property. Assignor further perpetually, irrevocably, and
                  unconditionally assigns, transfers, and conveys to Assignee
                  and its successors and assigns all claims for past, present
                  and future infringement or misappropriation of the
                  Intellectual Property Rights included in the Assigned
                  Property, including all rights to sue for and to receive and
                  recover all profits and damages accruing from an infringement
                  misappropriation prior to the Effective Date as well as the
                  right to grant releases for past infringements. Assignor
                  hereby waives and agrees not to enforce all Moral Rights and
                  all Personality Rights that Assignor may have in the Assigned
                  Property.</span>
                </p>

                <p>
                  <strong>3. Consideration.</strong> Your exclusive assignment,
                  transfer, grant of ownership, of all rights to Doing Things
                  Media of the Content, (or exclusive license of copyright in
                  and to the Content if a court or arbitrator were to find that,
                  for some reason, ownership of the Content was not properly
                  transferred to Doing Things Media), and this Agreement are
                  supported by reasonable and valuable consideration, the
                  receipt and adequacy of which is or are hereby acknowledged by
                  You. <span data-redact>You acknowledge that such consideration includes the mere
                  possibility of Compiler's or Doing Things Media (as defined,
                  and naturally its other licensees) use or display and/or
                  distribution of the Content, and the mere possibility of the
                  publicity and promotion by Compiler or from Doing Things
                  Media's use or display and/or distribution of the Content, and
                  the mere possible use of Your Personality Rights.</span>
                </p>

                <p data-redact>
                  <strong>4. Confidentiality.</strong> Assignor must not use any
                  Confidential Information assigned as part of the Assigned
                  Property except for the benefit of Assignee. Assignor must not
                  disclose such Confidential Information to third parties.
                  Assignor must take reasonable steps to maintain the
                  confidentiality and secrecy of such Confidential Information
                  and to prevent the unauthorized use or disclosure of such
                  Confidential Information. Any breach of these restrictions
                  will cause irreparable harm to Assignee and will entitle
                  Assignee to injunctive relief in addition to all applicable
                  legal remedies.
                </p>

                <p data-redact>
                  <strong>5. Representations and Warranties.</strong> Assignor
                  represents and warrants to Assignee that: Assignor exclusively
                  owns all right, title, and interest in and to the Assigned
                  Property; Assignor has not granted and will not grant any
                  licenses or other rights to the Assigned Property to any third
                  party; the Assigned Property is free of any liens,
                  encumbrances, security interests, and restrictions on
                  transfer; to Assignor's knowledge, the Intellectual Property
                  that is assigned as part of the Assigned Property does not
                  infringe Intellectual Property Rights of any third party; and
                  there are no legal actions, investigations, claims, or
                  proceedings pending or threatened relating to the Assigned
                  Property.
                </p>

                <p data-redact>
                  <strong>6. Indemnification.</strong> Assignor will defend,
                  indemnify, and hold harmless Assignee, and Assignee's
                  officers, directors, shareholders, successors, and assigns,
                  from and against all losses, liabilities, and costs including,
                  without limitation, reasonable attorneys' fees, expenses,
                  penalties, judgments, claims and demands of every kind and
                  character that Assignee, its officers, directors,
                  shareholders, successors, and assigns may incur, suffer, or be
                  required to pay arising out of, based upon, or by reason of:
                  the breach by Assignor of any of the representations or
                  warranties made by Assignor under this Agreement; Assignor's
                  use of the Assigned Property prior to the date of this
                  Agreement; or Assignor's failure to perform its obligations
                  under this Agreement.
                </p>

                <p data-redact>
                  <strong>7. Further Assurances</strong>
                </p>
                <p data-redact>
                  7.1 Assistance. Assignor will take all action and execute all
                  documents as Assignee may reasonably request to effectuate the
                  transfer of the Assigned Property and the vesting of complete
                  and exclusive ownership of the Assigned Property in Assignee.
                  In addition, Assignor will, at the request and sole cost and
                  expense of Assignee, but without additional compensation,
                  promptly sign, execute, make, and do all such deeds,
                  documents, acts, and things as Assignee may reasonably
                  require:
                </p>
                <ul data-redact>
                  <li>
                    to apply for, obtain, register, maintain and vest in the
                    name of Assignee alone (unless Assignee otherwise directs)
                    Intellectual Property Rights protection relating to any or
                    all of the Assigned Property in any country throughout the
                    world, and when so obtained or vested, to renew and restore
                    the same;
                  </li>
                  <li>
                    to defend any judicial, opposition, or other proceedings in
                    respect of such applications and any judicial, opposition,
                    or other proceedings or petitions or applications for
                    revocation of such Intellectual Property Rights; and
                  </li>
                  <li>
                    to assist Assignee with the defense and enforcement of its
                    rights in any registrations issuing from such applications
                    and in all Intellectual Property Rights protection in the
                    Intellectual Property.
                  </li>
                </ul>
                <p data-redact>
                  7.2 Power of Attorney. If at any time Assignee is unable, for
                  any reason, to secure Assignor's signature on any letters
                  patent, copyright, or trademark assignments or applications
                  for registrations, or other documents or filings pertaining to
                  any or all of the Assigned Property, whether because of
                  Assignor's unwillingness, or for any other reason whatsoever,
                  Assignor hereby irrevocably designates and appoints Assignee
                  and its duly authorized officers and agents as its agents and
                  attorneys-in-fact, to act for and on its behalf and stead to
                  execute and file any and all such applications, registrations,
                  and other documents and to do all other lawfully permitted
                  acts to further the prosecution thereon with the same legal
                  force and effect as if executed by Assignor.
                </p>

                <p data-redact>
                  <strong>8. Miscellaneous</strong>
                </p>
                <p data-redact>
                  8.1 Injunctive Relief. A breach of this Agreement may result
                  in irreparable harm to Assignee and a remedy at law for any
                  such breach will be inadequate, and in recognition thereof,
                  Assignee will be entitled to injunctive and other equitable
                  relief to prevent any breach or the threat of any breach of
                  this Agreement by Assignor without showing or proving actual
                  damages.
                </p>
                <p data-redact>
                  8.2 Binding on Successors. This Agreement will inure to the
                  benefit of, and be binding upon, the parties, together with
                  their respective representatives, successors, and assigns,
                  except that Assignor may not assign this Agreement without the
                  consent of Assignee.Assignee may assign this Agreement in its
                  discretion.
                </p>
                <p data-redact>
                  8.3 Governing Law and Jurisdiction. This Agreement will be
                  governed by, and construed in accordance with, the laws of the
                  State of New York without reference to its conflict of laws
                  provisions.With respect to any dispute arising out of or
                  related to this Agreement, the parties consent to the
                  exclusive jurisdiction of, and venue in, the federal and state
                  courts located in New York County, New York.
                </p>
                <p data-redact>
                  8.4 Amendment and Waiver. This Agreement may not be amended or
                  modified unless mutually agreed upon in writing by the parties
                  and no waiver will be effective unless signed by the party
                  from whom such waiver is sought. The waiver by any party of a
                  breach of any provision of this Agreement will not operate or
                  be construed as a waiver of any subsequent breach.
                </p>
                <p data-redact>
                  8.5 Severability. If any provision of this Agreement is held
                  invalid by any court of competent jurisdiction, such
                  invalidity will not affect the validity or operation of any
                  other provision, and the invalid provision will be deemed
                  severed from this Agreement.
                </p>
                <p data-redact>
                  8.6 Entire Agreement. This Agreement is the entire agreement
                  concerning the subject matter hereof. It supersedes all prior
                  and contemporaneous agreements, assurances, representations,
                  and communications between the parties.
                </p>

                <div className="footer">
                  <div className="item">
                    <span className="proper">
                      {state.submission.firstName} {state.submission.lastName}
                    </span>
                    <label>Full Legal Name</label>
                  </div>
                  <div className="item">
                    {moment(state.submission.createdAt).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                    <label>Date</label>
                  </div>
                  <div className="item">
                    {state.submission.id}
                    <label>Video ID</label>
                  </div>
                  <div className="item">
                    {state.submission.videoTitle == ""
                      ? "Not Provided"
                      : state.submission.videoTitle}
                    <label>Video Description</label>
                  </div>
                  <div className="item">
                    <a
                      href={`${window.location.origin}/m/${
                        state.submission.noteId
                          ? state.submission.noteId
                          : state.submission.id
                      }`}
                      target="_blank"
                      className="small"
                    >
                      {window.location.origin}/m/
                      {state.submission.noteId
                        ? state.submission.noteId
                        : state.submission.id}
                    </a>
                    <label>Video URL</label>
                  </div>
                  <div className="item">
                    <span className="esign-badge">
                      <svg
                        xmlnsXlink="http://www.w3.org/2000/svg"
                        viewBox="0 0 485 485"
                      >
                        <path
                          d="M445.9 88.2v135.5c0 123.4-83.8 231-203.5 261.3C122.9 454.8 39.1 347.1 39.1 223.7V88.2C133.2 77.6 186.5 41.8 242.5 0c56 41.8 109.3 77.6 203.4 88.2z"
                          fill="#ffd15c"
                        />
                        <path
                          d="M242.5 431.5c-90.1-28.6-152-112.2-152-207.8v-91.8c63.3-13.8 109.8-39.3 152-68.4 43.1 29.9 88.7 54.9 152 68.5v91.8c0 95.5-61.9 179.1-152 207.7z"
                          fill="#f8b64c"
                        />
                        <path
                          fill="#fff"
                          d="m208.8 325.4-50.1-50.1 25.4-25.5 24.7 24.6 92.1-92 25.4 25.4z"
                        />
                      </svg>
                      <span className="text">
                        The above terms agreed to by{" "}
                        <span className="proper">
                          {state.submission.firstName}{" "}
                          {state.submission.lastName}
                        </span>{" "}
                        on{" "}
                        {moment(state.submission.createdAt).format(
                          "MMMM Do YYYY"
                        )}{" "}
                        at{" "}
                        {moment(state.submission.createdAt).format("h:mm:ss a")}
                        .
                        <small>
                          <strong>Signature ID: </strong>
                          {generateSignatureId(
                            `${
                              state.submission.noteId
                                ? state.submission.noteId.slice(-12)
                                : state.submission.id
                            }`,
                            `${moment(state.submission.createdAt).format("x")}`
                          )}
                        </small>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}