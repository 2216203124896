import axios from "axios";
import { ROOT_URL } from "../../helpers/constants";

function returnData(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export const getLibraryAdmin = () => {
  const url = `${ROOT_URL}library/admin/all`;
  const data = returnData(url);
  return data;
};

export const getLibrary = (id) => {
  const url = `${ROOT_URL}library/all?id=${id}`;
  const data = returnData(url);
  return data;
};

export const addToLibrary = (subId, userId, email) => {
  if (!!email && email.includes("+")) {
    email = email.replace("+", "%2B");
  }
  const url = `${ROOT_URL}library/add?subId=${subId}&userId=${userId}&email=${email}`;
  const data = returnData(url);
  return data;
};

export const removeFromLibrary = (clipId, userId) => {
  const url = `${ROOT_URL}library/remove?clipId=${clipId}&userId=${userId}`;
  const data = returnData(url);
  return data;
};

export const downloadCount = async (clipId, userId) => {
  const url = `${ROOT_URL}library/download?clipId=${clipId}&userId=${userId}`;
  const data = await returnData(url);
  return data;
};
