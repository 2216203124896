import {
  addSubToCampaign,
  addSubToFeed,
  getCampaigns,
} from "../../libs/request/mRssFeed";
import {
  approveClip,
  deleteClip,
  markAsViewed,
  updateOneThing,
} from "../../libs/request";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { fas } from "fontawesome.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isUserInGroup, stripVideoUUID } from "../../helpers/global";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import AdminTags from "./AdminTags";
import Context from "../../store/store";
import moment from "moment";
import React from "react";
import ReactPlayer from "react-player";
import MicroTable from "../MicroTable";
import { MarkAsViewed } from "./MarkAsViewed";

export default function SubmissionDetails({
  clips,
  handleTagsUsedChange,
  mediaViewerClip,
  setClips,
  setMediaViewerClip,
  setShowSubmissionDetails,
  updateMediaViewer,
}) {
  // Load Context
  const context = useContext(Context);
  const { stateUser } = context;
  const { id } = stateUser;

  // State
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);
  const [showAdditionalMeta, setShowAdditionalMeta] = useState(false);
  const [tags, setTags] = useState();
  const [dropdownToggle, setDropdownToggle] = useState();
  const [campaignData, setCampaignData] = useState();
  const [futureCampaign, setFutureCampaign] = useState();
  const [campaignDataLoading, setCampaignDataLoading] = useState(true);
  const [strippedUUID, setStrippedUUID] = useState();
  const [userHasViewed, setUserHasViewed] = useState();

  // Define Groups
  const executiveAdmins = ["executiveAdmin", "superAdmin"];
  const admins = [...executiveAdmins, "admin"];

  const loadData = () => {
    getCampaigns(mediaViewerClip.id).then((res) => {
      const tempMap = new Map();
      res.campaigns.forEach((campaign) => {
        if (campaign.campaignId)
          tempMap.set(campaign.id, {
            id: campaign.id,
            name: campaign.campaignName,
          });
      });
      const tempCampaignData = {
        campaigns: res.campaigns.filter(
          ({ id }) => !res.clipCampaigns.find((campaign) => campaign.id === id)
        ),
        campaignMap: tempMap,
        activeCampaigns: res.clipCampaigns,
      };
      setCampaignData(tempCampaignData);
      setCampaignDataLoading(false);
    });
  };

  useEffect(() => {
    loadData();
    setCampaignDataLoading(true);
    setTags(mediaViewerClip.tagValues);
    if (mediaViewerClip.usersMarkedAsViwedIds.includes(id))
      setUserHasViewed(true);
  }, []);

  useEffect(() => {
    if (mediaViewerClip.id) {
      setMicroTableData(generateMicroTableData(mediaViewerClip));
      setTags(mediaViewerClip.tagValues);
      setStrippedUUID(stripVideoUUID(mediaViewerClip.attachment));
      // getClipTags(mediaViewerClip.id).then((result) => {
      //   const { tags } = result;
      //   const approvedTags = tags.filter((tag) => tag.approved === 1);
      //   setTags(approvedTags);
      // });
      loadData();
      setUserHasViewed(!!mediaViewerClip.usersMarkedAsViwedIds.includes(id));
    }
  }, [mediaViewerClip]);

  const addTofutureCampaign = (campaignId) => {
    const campaign = campaignData.campaigns.find(
      (campaign) => campaign.id === campaignId
    );
    setFutureCampaign(campaign);
    campaignData.activeCampaigns.push(campaign);
  };

  const handleSaveCampaignChanges = () => {
    addSubToCampaign(mediaViewerClip.id, futureCampaign.id)
      .then(() => {
        toast.success(`Added to ${futureCampaign.campaignName}`);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong . . .");
      });
  };

  const metadata =
    !!mediaViewerClip && !!mediaViewerClip.metadata
      ? JSON.parse(mediaViewerClip.metadata)
      : {};

  window.activeClip = mediaViewerClip;
  window.activeClip["parsedMetadata"] = metadata;

  const handleDelete = (clip) => {
    const { id } = clip;
    deleteClip(id)
      .then(() => {
        setShowSubmissionDetails(false);
        setClips(clips.filter((f) => f.id !== id));
        toast.success("File deleted", { icon: "🚀" });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const renderContract = (row) => {
    if (row.approved) {
      return (
        <a
          href={`/agreement/${row.noteId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </a>
      );
    }
    return <em>Approve the video to generate a contract.</em>;
  };

  const renderFileSize = (metadata, decimals = 2) => {
    if (!metadata) {
      return <span className="alert">Unknown</span>;
    }
    const bytes = metadata.size;
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    if (!isNaN(value)) {
      return value + " " + sizes[i];
    } else {
      return <span className="alert">Unknown</span>;
    }
  };

  const renderDevice = (metadata) => {
    if (metadata && metadata.tags) {
      if (metadata.tags["com.apple.quicktime.make"]) {
        if (metadata.tags["com.apple.quicktime.model"]) {
          return `${metadata.tags["com.apple.quicktime.make"]} (${metadata.tags["com.apple.quicktime.model"]})`;
        } else {
          return `${metadata.tags["com.apple.quicktime.make"]}`;
        }
      } else {
        return <span className="alert">Unknown</span>;
      }
    } else {
      return <span className="alert">Unknown</span>;
    }
  };

  const renderDimensions = (metadata) => {
    if (metadata && metadata.width && metadata.height) {
      return `${metadata.width}px x ${metadata.height}px`;
    } else {
      return <span className="alert">Unknown</span>;
    }
  };

  const renderLocation = (metadata) => {
    if (metadata && metadata.location) {
      return metadata.location;
    } else {
      return <span className="alert">Unknown</span>;
    }
  };

  const renderCreationDate = (metadata) => {
    if (
      metadata &&
      metadata["tags"] &&
      metadata["com.apple.quicktime.creationdate"] != ""
    ) {
      return `${moment(
        metadata["tags"]["com.apple.quicktime.creationdate"],
        "YYYY-MM-DDTHH:mm:ss-ZZ"
      ).format("LLL")} (${moment(
        metadata["tags"]["com.apple.quicktime.creationdate"],
        "YYYY-MM-DDTHH:mm:ss-ZZ"
      )
        .subtract(4, "hours")
        .fromNow()})`;
    } else if (!!metadata && metadata.creation_time) {
      return `${moment(metadata.creation_time).format("LLL")} ${moment(
        mediaViewerClip.createdAt,
        "YYYY-MM-DD HH:mm:ss"
      )
        .subtract(4, "hours")
        .fromNow()}`;
    } else {
      return <span className="alert">Unknown</span>;
    }
  };

  const updateFields = async (event, row, values, data) => {
    // Prevent reload.
    event.preventDefault();

    // Updates the clip, passes the clip ID, passes a key/value dict of the value to update.
    return new Promise((resolve, reject) => {
      updateOneThing("clip", row.id, values)
        .then((response) => {
          if (response.status === 200) {
            // Find the index of the row that has just been edited.
            const rowIndex = data.findIndex((obj) => obj.noteId === row.noteId);

            // Copy the clips array into a new array.
            const newClips = [...data];

            // Update the target row index with the uppdated value.
            Object.keys(values).forEach(function (key) {
              newClips[rowIndex][key] = values[key];
            });

            // Update the state of clips.
            setClips(newClips);

            // Success
            toast.success("Updated");
            resolve(response.status);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error(error.message);
          reject(error);
        });
    });
  };

  const handleApproval = (row, type) => {
    if (type === "approved" || type === "isViral" || type === "vimeo") {
      return new Promise((resolve, reject) => {
        approveClip(row)
          .then((response) => {
            if (response.status === 200) {
              updateMediaViewer(row);
              resolve();
            } else {
              reject(response.error);
              console.log(response.error);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong.");
            console.log(error.message);
            reject(error);
          });
      });
    }
  };

  const updateField = async (event, row, field, value) => {
    // Prevent reload.
    event.preventDefault();

    // Updates the clip, passes the clip ID, passes a key/value dict of the value to update.
    return new Promise((resolve, reject) => {
      updateOneThing("clip", row.id, { [field]: value })
        .then((response) => {
          if (response.status === 200) {
            // Find the index of the row that has just been edited.
            const rowIndex = clips.findIndex((obj) => obj.id === row.id);

            // Copy the clips array into a new array.
            const newClips = [...clips];

            // Update the target row index with the uppdated value.
            newClips[rowIndex][field] = value;

            // Update the state of clips.
            setClips(newClips);

            // Success
            toast.success("Success");
            resolve();
          } else {
            toast.error("Something went wrong.");
            console.log(response.error);
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error(error.message);
          reject(error);
        });
    });
  };

  /**
   * Function to update video row in db with new rating
   * @param event
   * @param row
   * @param type
   * @return void
   */
  const updateValue = async (event, row, type) => {
    event.persist();

    if (row[type] === false) {
      row[type] = true;
    } else {
      row[type] = false;
    }

    const updatedValue = row[type];

    await updateField(event, row, type, updatedValue);
    updateMediaViewer(row);
  };

  const handleRating = async (event, row, type, data) => {
    // Allows for the event's information to be accessed beyond this line (it's needed lower down).
    event.persist();

    row[type] = row[type] === false ? true : false;
    type === "approved" ? (row["disapprove"] = false) : null;
    type === "disapprove" ? (row["approved"] = false) : null;

    // Deconstruct the row.
    const { approved, disapprove, isViral } = row;

    // Initate a array of values to update.
    const valuesToUpdate = {
      approved,
      disapprove,
      isViral,
    };

    // Update the values in the database.
    return new Promise((resolve, reject) => {
      updateFields(event, row, valuesToUpdate, data)
        .then((response) => {
          const { approved, isViral } = valuesToUpdate;
          // Once saved, determine if we need to mark this clip as approved.
          if (response) {
            if (approved === true || isViral === true) {
              handleApproval(row, type);
              resolve();
            }
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong.");
          reject(error);
        });
    });
  };

  const renderActiveCampaigns = (activeCampaigns, futureCampaign) => {
    const validatedActiveCampaigns = activeCampaigns.filter(
      (campaign) => campaign != undefined
    );
    activeCampaigns = validatedActiveCampaigns.map((campaign) => campaign.name);
    if (futureCampaign && futureCampaign.name) {
      activeCampaigns.push(futureCampaign.name);
    } else if (activeCampaigns.length == 0) {
      return <em>No active campaigns.</em>;
    }
    return activeCampaigns.join(", ");
  };

  const addToFeed = async (noteId) => {
    await addSubToFeed(noteId)
      .then((res) => {
        toast.success("Added to mRSS Feed");
      })
      .catch((error) => {
        toast.error("There was an error!");
      });
  };

  const handleTitleUpdate = (e) => {
    const { innerText } = e.target;
    if (innerText === "No title has been created.") return;
    updateField(e, mediaViewerClip, "videoTitle", innerText);
  };

  const renderAgreementCell = () => {
    return mediaViewerClip.approved ? (
      <>
        <a
          href={`/agreement/${mediaViewerClip.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </a>
        &nbsp; &nbsp;
        <a
          href={`/agreement/${mediaViewerClip.id}?redact=1`}
          target="_blank"
          rel="noopener noreferrer"
        >
          (Redacted Version)
        </a>
      </>
    ) : (
      <em>Approve the video to generate a contract.</em>
    );
  };

  const renderBrandManagerCell = () => {
    return mediaViewerClip.authDoc && mediaViewerClip.authDoc != "null" ? (
      <a
        href={mediaViewerClip.authDoc}
        download
        target="_blank"
        rel="noreferrer"
      >
        View Authorization Documentation
      </a>
    ) : (
      <span className="alert">Not Uploaded</span>
    );
  };

  const renderSubmissionDate = () => {
    return `${moment(mediaViewerClip.createdAt).format("LLL")} (${moment(
      mediaViewerClip.createdAt,
      "YYYY-MM-DD HH:mm:ss"
    )
      .subtract(4, "hours")
      .fromNow()})`;
  };

  const generateMicroTableData = (clipData) => [
    {
      label: "Submission Information",
      data: [
        {
          key: "ID",
          value: clipData.id,
          copyable: true,
          copyValue: clipData.id,
          editable: false,
        },
        {
          key: "Date",
          value: renderSubmissionDate(),
          copyable: false,
          editable: false,
        },
        {
          key: "User Title",
          value: clipData.description,
          copyable: false,
          editable: false,
        },
        {
          key: "Location",
          value: clipData.location,
          copyable: false,
          editable: false,
        },
        {
          key: "Shareable Link",
          value: "Click to copy link.",
          copyable: true,
          copyValue: `${window.location.origin}/media/${clipData.id}`,
          editable: false,
        },
      ],
      expandable: false,
    },
    {
      label: "Internal Information",
      data: [
        {
          key: "Internal Description",
          value:
            clipData.videoTitle === ""
              ? "No title has been created."
              : clipData.videoTitle,
          copyable: false,
          editable: true,
          updateAction: (e) => handleTitleUpdate(e),
        },
        {
          key: "In License Library",
          value: clipData.isViral ? "Yes" : "No",
          copyable: clipData.isViral,
          copyValue: `${window.location.origin}/license/${clipData.id}`,
          editable: false,
        },
        {
          key: "Uploaded by Brand Manager",
          value: renderBrandManagerCell(),
          copyable: false,
          editable: false,
        },
      ],
      expandable: false,
    },
    {
      label: "User Information",
      data: [
        {
          key: "Email",
          value: clipData.email,
          copyable: false,
        },
        {
          key: "Phone",
          value: clipData.phone ? clipData.phone : "No Phone Number.",
          copyable: false,
        },
        {
          key: "Credited To",
          value: clipData.userCredit
            ? clipData.userCredit
            : "No one to credit!",
          copyable: false,
          editable: true,
          updateAction: (e) =>
            updateField(e, clipData, "userCredit", e.target.innerText),
        },
        {
          key: "Has Account",
          value: clipData.isLoggedIn ? "Yes" : "No",
          copyable: false,
        },
        {
          key: "Agreement",
          value: renderAgreementCell(),
          copyable: false,
        },
      ],
      expandable: false,
    },
    // {
    //   label: "Metadata",
    //   data: [
    //     {
    //       key: "Creation Time",
    //       value: renderCreationDate(metadata),
    //       additional: false,
    //     },
    //     {
    //       key: "Location",
    //       value: renderLocation(metadata),
    //       additional: false,
    //     },
    //     {
    //       key: "Dimensions",
    //       value: renderDimensions(metadata),
    //       additional: false,
    //       isToggleData: true,
    //     },
    //   ],
    //   expandable: true,
    // },
  ];

  const [microTableData, setMicroTableData] = useState(
    generateMicroTableData(mediaViewerClip)
  );

  const additionalMetaDataInformation = [
    {
      key: "File Size",
      value: renderFileSize(metadata),
      additional: true,
    },
    {
      key: "Duration",
      value: moment
        .duration(mediaViewerClip.duration, "seconds")
        .format("m [minutes], s [seconds]"),
      additional: true,
    },
    {
      key: "Device",
      value: renderDevice(metadata),
      additional: true,
    },
  ];

  const mRSSInformation = [
    {
      key: "Active Campaigns",
      value:
        !campaignDataLoading && campaignData.activeCampaigns.length
          ? campaignData.activeCampaigns
              .map((campaign) => campaign.campaignName)
              .join(", ")
          : "No Active Campaigns!",
      renderDropdown: () => renderMRSSDropdown(),
    },
  ];

  function handleShowAdditionalMetaData() {
    const metaData = microTableData.filter(
      (item) => item.label === "Metadata"
    )[0];
    const temp = microTableData;
    if (!showAdditionalMeta) {
      temp[temp.length - 1].data = metaData.data.concat(
        additionalMetaDataInformation
      );
      setMicroTableData(temp);
    } else {
      const tempMetadata = metaData.data.slice(0, 2);
      temp[temp.length - 1].data = tempMetadata;
      setMicroTableData(temp);
    }
    setShowAdditionalMeta(!showAdditionalMeta);
  }

  const handleMarkAsViewed = async () => {
    await markAsViewed({
      submissionId: mediaViewerClip.id,
      userId: id,
    }).then((result) => {
      setMediaViewerClip({ mediaViewerClip, ...result.data });
      setClips((current) => {
        current.find(
          (sub) => sub.id === mediaViewerClip.id
        ).usersMarkedAsViwedIds = result.data.usersMarkedAsViwedIds;
        return current;
      });
    });
  };

  function renderMRSSDropdown() {
    return (
      <>
        <h2 className="action-header">
          <Dropdown
            toggle={() => setDropdownToggle(!dropdownToggle)}
            isOpen={dropdownToggle}
          >
            <DropdownToggle>
              {<FontAwesomeIcon icon={fas("plus-circle")} />}
            </DropdownToggle>
            <DropdownMenu>
              {campaignData.campaigns.map((campaign, i) => (
                <DropdownItem
                  key={i}
                  onClick={() => addTofutureCampaign(campaign.id)}
                >
                  {campaign.campaignName}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {futureCampaign && (
            <Button onClick={() => handleSaveCampaignChanges()}>Save</Button>
          )}
        </h2>
      </>
    );
  }

  return (
    <>
      <div className="slide full">
        <Button
          className="slide-close"
          onClick={() => {
            setShowSubmissionDetails(false);
            setMediaViewerClip({});
          }}
        ></Button>
        <div className="slide-container">
          <div className="slide-wrapper">
            {/* Left Column */}
            <div className="left-col">
              {microTableData.map((item, i) => (
                <MicroTable
                  key={i}
                  label={item.label}
                  data={item.data}
                  toggleState={showAdditionalMeta}
                  expandable={item.expandable}
                  handleToggleState={handleShowAdditionalMetaData}
                />
              ))}

              {/* MRSS */}
              {!campaignDataLoading && (
                <MicroTable
                  label={"mRSS"}
                  data={mRSSInformation}
                  dropdown={mRSSInformation[0].renderDropdown}
                />
              )}
            </div>

            {/* Right Column */}
            <div className="right-col">
              {/* Media */}
              {mediaViewerClip.contentType !== "image" ? (
                <div className="player-wrapper">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    url={strippedUUID}
                    controls={true}
                  />
                </div>
              ) : (
                <img src={mediaViewerClip.attachment} />
              )}

              <MarkAsViewed
                handleClick={handleMarkAsViewed}
                userHasViewed={userHasViewed}
              />

              {/* Actions */}
              <div className="actions">
                {/* Viral */}
                <Button
                  className={`viral ${mediaViewerClip.isViral ? "active" : ""}`}
                  onClick={(event) =>
                    handleRating(event, mediaViewerClip, "isViral", clips)
                  }
                >
                  <FontAwesomeIcon icon={fas("fire")} />
                  <span>Viral</span>
                </Button>

                {/* Approve */}
                <Button
                  className={`approve ${
                    mediaViewerClip.approved ? "active" : ""
                  }`}
                  onClick={(event) =>
                    handleRating(event, mediaViewerClip, "approved", clips)
                  }
                >
                  <FontAwesomeIcon icon={fas("thumbs-up")} />
                  <span>Approve</span>
                </Button>

                {/* Disapprove */}
                <Button
                  className={`disapprove ${
                    mediaViewerClip.disapprove ? "active" : ""
                  }`}
                  onClick={(event) =>
                    handleRating(event, mediaViewerClip, "disapprove", clips)
                  }
                >
                  <FontAwesomeIcon icon={fas("thumbs-down")} />
                  <span>Disapprove</span>
                </Button>

                {/* NSFW */}
                <Button
                  className={`nsfw ${
                    mediaViewerClip.disapprove ? "vimeo" : ""
                  }`}
                  onClick={(event) =>
                    updateValue(event, mediaViewerClip, "vimeo")
                  }
                >
                  <FontAwesomeIcon icon={fas("exclamation-triangle")} />
                  <span>NSFW</span>
                </Button>

                {/* Admins Only */}
                {isUserInGroup(stateUser, admins) && (
                  <>
                    {/* Additional Options */}
                    <div className="additional-container">
                      <Button
                        className="additional"
                        onClick={() =>
                          setShowAdditionalOptions(!showAdditionalOptions)
                        }
                      >
                        {!showAdditionalOptions ? (
                          <>
                            <FontAwesomeIcon icon={fas("plus")} />
                            <span>Show additional actions</span>
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={fas("minus")} />
                            <span>Hide additional actions</span>
                          </>
                        )}
                      </Button>

                      {showAdditionalOptions && (
                        <div className="actions-wrapper">
                          {/* Delete Submission */}
                          {stateUser.groups &&
                            stateUser.groups.includes("superAdmin") && (
                              <>
                                <Button
                                  className="delete"
                                  onClick={() => handleDelete(mediaViewerClip)}
                                >
                                  <FontAwesomeIcon icon={fas("trash")} />
                                  <span>Delete</span>
                                </Button>
                              </>
                            )}

                          {/* Report Content */}
                          <Button
                            className="report"
                            onClick={(event) =>
                              updateValue(
                                event,
                                mediaViewerClip,
                                "reportedContent"
                              )
                            }
                          >
                            <FontAwesomeIcon icon={fas("flag")} />
                            <span>Report</span>
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>

              {/* Tags */}
              <AdminTags
                currentClip={mediaViewerClip}
                handleTagsUsedChange={handleTagsUsedChange}
                updatedClip={mediaViewerClip}
                clipTags={tags}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
