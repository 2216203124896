import axios from "axios";
import { useEffect, useState } from "react";
import { ROOT_URL } from "../../helpers/constants";

export const useGetBrandFeed = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(undefined);

  async function fetchData() {
    const response = await axios
      .get(`${ROOT_URL}brand_feed`)
      .catch((error) => setError(error));
    setData(response.data);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return { data, isLoading, fetchData };
};
