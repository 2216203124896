import React from 'react'

const ToastErrorList = ({ error }) => (
  <>
    <div>{error.message}</div>
    {error.list.map((item) => (
      <div key={item.key}>
        {item.label}
      </div>
    ))}
  </>
)

export default ToastErrorList
