import React, {useState} from "react";
import {Row, Col, Form, Input, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {Helmet} from "react-helmet";
import {TextInput, CustomButton} from "./form";

const SignIn = ({
  awsError,
  handleConfirmationCode,
  handleLogIn,
  modalError,
  showModal,
  toggleModal,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");

  return (
    <>
      <Form className="login" onSubmit={(event) => handleLogIn(event, email, password)}>
        <Helmet>
          <title>Sign In | Doing Things Media</title>
          <meta name="description" content="Sign in to Doing Things Media" />
        </Helmet>
        <Row className="form-row">
          <Col>
            <TextInput
              handleChange={(e) => setEmail(e.target.value)}
              htmlFor="email"
              id="email"
              name="email"
              labelTitle="Email Address"
              required
              styling="standard-input"
              type="text"
              value={email}
            />
          </Col>
        </Row>
        <Row className="form-row">
          <Col>
            <TextInput
              handleChange={(e) => setPassword(e.target.value)}
              required
              styling="standard-input"
              id="password"
              name="password"
              labelTitle="Password"
              htmlFor="password"
              type="password"
              value={password}
            />
          </Col>
        </Row>
        <Row className="disclaimer-container">
          <Col>
            <a className="forgot" href="/forgot-password">Forgot Password?</a>
          </Col>
        </Row>
        <Row className="error-container">
          <Col>
            <p className="error">{awsError}</p>
          </Col>
        </Row>
        <Row className="btn-container centered">
          <Col>
            <CustomButton
              type="submit"
              styling="primary"
              title="Log in"
            />
          </Col>
        </Row>
      </Form>

      <Modal isOpen={ showModal } toggle={ toggleModal }>
        <ModalHeader
          className="forgot-password"
          toggle={ toggleModal }
        >
          Confirmation Code
        </ModalHeader>
        <Form
          onSubmit={
            (event) => handleConfirmationCode(event, {email, password}, confirmationCode)
          }
        >
          <ModalBody>
            <p>Input the confirmation code that was emailed to you below.</p>
            <TextInput
              text="Confirmation Code"
              id="confirmationCode"
              name="confirmationCode"
              labelTitle="Confirmation Code"
              value={ confirmationCode }
              placeholder="123456"
              handleChange={(e) => setConfirmationCode(e.target.value)}
              invalid={ confirmationCode.length !== 6 }
              feedbackText="a 6 digit confirmation code"
            />
          </ModalBody>
          <ModalFooter className="forgot-password-footer">
            <Input type="submit" value="Submit" />
            {" "}
            <span className="login-error">{modalError}</span>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default SignIn;
