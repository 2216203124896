import ReactPlayer from "react-player";
import { Button } from "reactstrap";

export default function SlideViewer({ children, handleClose, contentType, mediaUrl }) {
  return (
    <div className="slide half">
      <Button
        className='slide-close'
        onClick={() => handleClose()}
      ></Button>
      <div className="slide-container">
        <div className="slide-wrapper">
          <div className="right-col">

            {/* Media */}
            {contentType !== "image" ? (
              <div className='player-wrapper'>
                <ReactPlayer
                  width='100%'
                  height='100%'
                  url={mediaUrl}
                  controls={true}
                />
              </div>
            ) : (
              <img src={mediaUrl} />
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}